export class User {
    avatar_url: string
    email: string
    firstName: string
    lastName: string
    constructor(avatar_url: string, email: string, firstName: string = "",
        lastName: string = "") {
        this.avatar_url = avatar_url
        this.email = email
        this.firstName = firstName
        this.lastName = lastName
    }
}

export function buildUserFromOrgDetail(u: any): User  {
    return new User(u.avatar_url,
        u.email)
}