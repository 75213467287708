import React from "react"
import { InvoiceREST } from "./PempemInvoiceList"
import { useSearchParams } from "react-router-dom"

type Props = {
    invoice: InvoiceREST
    onErrorLoading: (string) => void
}

export function VATInvoiceDetail({ invoice, onErrorLoading }: Props) {

    const [param] = useSearchParams()
    const pdfUrlParam = param.get("pdfUrl")
    // const orgId = param.get("pdfUrl")

    // const [pdfUrl, setPdfUrl] = useState()

    // const { id } = useParams()

    // useEffect(() => {
    //     if (!pdfUrl) {
    //         downloadInvoiceHelper(parseInt(id), '')
    //             .then(url => {
    //                 setPdfUrl(url as any)
    //             })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    return (<div style={{ height: '100%', border: '1px solid black' }}>
        <iframe
            title="PDF renderer"
            src={decodeURIComponent(pdfUrlParam)}
            style={{ width: '100%', height: '100%' }}
            frameBorder="0"
        ></iframe>
    </div>)
}