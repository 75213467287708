import { Location, buildLocationFromDetail } from "./Location"

export class TaxInformationModel {
    legal_name: string
    taxActivated: boolean
    vat_transaction_type: string
    isWithholdingTax: boolean
    taxNumber: string
    address: string
    rt: string
    rw: string
    postalCode: string
    location: Location
    phoneNumber: string
    email: string

    constructor(name: string,
        taxActivated: boolean,
        is_withholding_tax: boolean,
        taxNumber: string,
        address: string,
        rt: string,
        rw: string,
        postalCode: string,
        location: Location,
        phoneNumber: string,
        email: string,
        transactionType: string) {
        this.legal_name = name
        this.taxActivated = taxActivated
        this.isWithholdingTax = is_withholding_tax
        this.taxNumber = taxNumber
        this.address = address
        this.rt = rt
        this.rw = rw
        this.postalCode = postalCode
        this.location = location
        this.phoneNumber = phoneNumber
        this.email = email
        this.vat_transaction_type = transactionType
    }
}

export function buildTaxInformation(o: any): TaxInformationModel {

    function convertTransactionTypeToNumberType(type: string) {
        if (type) {
            return type === "TAXPAYER" ? "05" : "03"
        }
        return undefined
    }

    return new TaxInformationModel(o.legal_name,
        o.apply_vat,
        o.is_withholding_tax ?? false,
        o.tax_identification_number,
        o.address,
        o.rt,
        o.rw,
        o.postal_code,
        o.legal_location ? buildLocationFromDetail(o.legal_location): null,
        o.phonenumber,
        o.email,
        convertTransactionTypeToNumberType(o.vat_transaction_type))
}