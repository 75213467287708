import { Location, buildLocationFromDetail } from "../../../models/organisation/Location"

export class OnboardingFarmer {
    createdAt: string
    owner_person_id: number
    location: Location

    constructor(createdAt: string,
        owner_person_id: number,
        location: any) {
            this.createdAt = createdAt
            this.owner_person_id = owner_person_id
            this.location = buildLocationFromDetail(location)
    }
}