import React from "react"
import { colorLightGrey } from "../rsc/colors/colors"
import { Box } from "@mui/material"

export function EmptyView({ value, isVisible }) {
    return (
        <div style={{display: isVisible ? "block" : "none"}}>
            <div style={{ textAlign: "center", marginTop: "60px" }}>
                <Box sx={{ boxShadow: 2, display: "inline-block", backgroundColor: colorLightGrey }}>
                    <p style={{ padding: "20px", display: "inline-block", margin: "0px" }}>{value}</p>
                </Box>
            </div>
        </div>
    )
}