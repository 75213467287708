import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tab, Tabs, styled, tabClasses, tabsClasses } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { FetchOnboardedFarmers } from "../../api";
import { transactorId } from "../../helper/TokenHelper";
import { DATE_PATTERN_DD, DATE_PATTERN_DD_MM_YYYY, DATE_PATTERN_MMM, DATE_PATTERN_MM_YYYY, convertDayjsToString, convertToDateWithUtc, showErrorMessage } from "../../helper/helper";
import { OnboardingFarmer } from "./models/OnboardingFarmer";
import { colorPrimary, colorPrimaryDark, colorYellowLight, colorYellowMedium } from "../../rsc/colors/colors";

type DashboardFarmerOnboardGraphProps = {
    client: ApolloClient<NormalizedCacheObject>
}

const RANGE_DAYS = 15
const RANGE_MONTH = 12

type UIFarmerChart = {
    data: number[]
    label: string
    stack: string
    color: string
}

const TabItem = styled(Tab)(({ theme }) => ({
    position: "relative",
    borderRadius: "30px",
    textAlign: "center",
    transition: "all .5s",
    padding: "10px 15px",
    color: "#555555",
    height: "auto",
    margin: "10px 0",
    flex: "2",
    float: "none",
    fontSize: "12px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
        minWidth: 120,
    },
    [`&.${tabClasses.selected}, &:hover`]: {
        color: "#FFFFFF",
        backgroundColor: colorPrimary,
        boxShadow: "0 7px 10px -5px rgba(76, 175, 80, 0.4)",
    },
}));

export function DashboardFarmerOnboardGraph({ client }: DashboardFarmerOnboardGraphProps) {

    const lastDays = useRef<Map<string, OnboardingFarmer[]>>(new Map())
    const lastMonths = useRef<Map<string, OnboardingFarmer[]>>(new Map())

    const [chartSeries, setChartSeries] = useState<UIFarmerChart[]>(null)

    const seriesFarmers = useRef<UIFarmerChart[]>([])

    const [displayType, setDisplayType] = useState("landplot")

    const chartsData = useRef<{ farmers: OnboardingFarmer[], landplots: OnboardingFarmer[] }>(undefined)

    const [tabIndex, setTabIndex] = useState<number>(0)

    const CHART_COLORS = [
        colorYellowLight,
        colorYellowMedium,
        colorPrimary,
        colorPrimaryDark,
        "#9C27B0",
        "#2196F3",
        "#795548",
        "#4CAF50",
        "#000000",
        "#BDDB39",
        "#FF194F",
        "#FFCB0F"
    ]

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (chartsData.current) {
            if (displayType === "farmers") {
                dispatchUsers(chartsData.current.farmers)
            } else {
                dispatchUsers(chartsData.current.landplots)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayType])

    function fetchData() {
        var variables = {}
        var query = FetchOnboardedFarmers

        variables = {
            transactorId: transactorId(),
            distinct: 'owner_person_id'
        }
        client.query({
            query: query,
            variables: variables,
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then(result => {
            const landplotsData = result.data.landplots.map(e => new OnboardingFarmer(e.created_at, e.owner_person_id, e.owner.location))
            const farmersData = result.data.farmers.map(e => new OnboardingFarmer(e.created_at, e.owner_person_id, e.owner.location))
            chartsData.current = { landplots: landplotsData, farmers: farmersData }

            dispatchUsers(landplotsData)
        }).catch(e => showErrorMessage(e.message))
    }

    function dispatchUsers(users: OnboardingFarmer[]) {

        const provinces = new Set([...users.map(u => u.location.province)].filter(p => p !== "Quebec"))
        seriesFarmers.current = [...provinces].sort((c1, c2) => { if (c1 > c2) { return 1 } else return -1 }).map((p, i) => {
            return {
                data: [],
                label: p,
                stack: "Province",
                color: CHART_COLORS[i]
            }
        })

        var day = dayjs()
        for (let i = 0; i < RANGE_DAYS; i++) {
            lastDays.current.set(convertDayjsToString(day.startOf('day'), DATE_PATTERN_DD_MM_YYYY), [])
            day = day.subtract(1, 'day')
        }

        day = dayjs()
        for (let i = 0; i < RANGE_MONTH; i++) {
            lastMonths.current.set(convertDayjsToString(day.startOf('month'), DATE_PATTERN_MM_YYYY), [])
            day = day.subtract(1, 'month')
        }

        users.forEach((u) => {
            const dateDay = convertDayjsToString(dayjs(u.createdAt), DATE_PATTERN_DD_MM_YYYY)
            const dateMonth = convertDayjsToString(dayjs(u.createdAt), DATE_PATTERN_MM_YYYY)
            const mapDay = lastDays.current.get(dateDay)
            const mapMonth = lastMonths.current.get(dateMonth)

            if (mapDay) {
                lastDays.current.get(dateDay).push(u)
            }
            if (mapMonth) {
                lastMonths.current.get(dateMonth).push(u)
            }
        })
        if (tabIndex === 0) {
            displayDays()
        } else {
            displayMonth()
        }
    }

    function displayMonth() {
        seriesFarmers.current.forEach(s => s.data = new Array(RANGE_MONTH).fill(0))
        var index = RANGE_MONTH - 1
        lastMonths?.current?.forEach((value, key) => {
            value.forEach(u => {
                var serie: UIFarmerChart = seriesFarmers.current.find(s => s.label === u.location.province)
                if (serie) {
                    serie.data[index] += 1
                }
            })
            index -= 1
        })
        if (seriesFarmers && seriesFarmers.current.length > 0) {
            setChartSeries([...seriesFarmers.current])
        }
    }

    function displayDays() {
        seriesFarmers.current.forEach(s => s.data = new Array(RANGE_DAYS).fill(0))
        var index = RANGE_DAYS - 1
        lastDays?.current?.forEach((value, key) => {
            value.forEach(u => {
                var serie: UIFarmerChart = seriesFarmers.current.find(s => s.label === u.location.province)
                if (serie) {
                    serie.data[index] += 1
                }
            })
            index -= 1
        })
        if (seriesFarmers && seriesFarmers.current.length > 0) {
            setChartSeries([...seriesFarmers.current])
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayType((event.target as HTMLInputElement).value);
    };

    function isLandPlotSelected() {
        return displayType === "landplot"
    }

    const getGraphTitle = (): string => {
        if (isLandPlotSelected()) {
            if (tabIndex === 0) {
                return t('onboarding_graph_title_day', { unit: t('landplots'), value: RANGE_DAYS })
            } else {
                return t('onboarding_graph_title_month', { unit: t('landplots'), value: RANGE_MONTH })
            }
        } else {
            if (tabIndex === 0) {
                return t('onboarding_graph_title_day', { unit: t('farmers'), value: RANGE_DAYS })
            } else {
                return t('onboarding_graph_title_month', { unit: t('farmers'), value: RANGE_MONTH })
            }
        }
    }

    function getXAxis(): string[] {
        var array: string[]
        if (tabIndex === 0) {
            array = [...lastDays.current.keys()].map(d => convertToDateWithUtc(d, DATE_PATTERN_DD, DATE_PATTERN_DD_MM_YYYY))
        } else {
            array = [...lastMonths.current.keys()].map(d => convertToDateWithUtc(d, DATE_PATTERN_MMM, DATE_PATTERN_MM_YYYY))
        }
        return array
    }

    function updateTab(index: number) {
        if (index === 0) {
            displayDays()
        } else {
            displayMonth()
        }
    }

    function getLegend() {
        return (<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "20px", padding: "0 30px" }}>
            {chartSeries?.map(s => <div style={{ margin: "5px", display: "flex", alignItems: "center" }}>
                <div style={{ backgroundColor: s.color, width: "20px", height: "20px", marginRight: "6px", flexShrink: "0" }}></div>
                <p>{s.label}</p>
            </div>)}

        </div>)
    }


    return (<div>
        <div style={{ marginTop: "30px" }}>
            <p className="table-label-indicator" style={{ textAlign: "center" }}>{t('onboarding_sidebar_title')}</p>
        </div>
        <Tabs
            value={tabIndex}
            onChange={(e, index) => { setTabIndex(index); updateTab(index) }}
            sx={{
                marginTop: "30px",
                width: "100%",
                [`& .${tabsClasses.indicator}`]: {
                    display: "none"
                },
                '& .MuiTabs-flexContainer': {
                    gap: "30px"
                }
            }}
        >
            <TabItem disableRipple label={t('day')} />
            <TabItem disableRipple label={t('month')} />
        </Tabs>

        <FormControl sx={{
            width: "100%",
            '& .MuiFormGroup-root': {
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100%"
            }
        }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="landplot"
                name="radio-buttons-group"
                onChange={handleChange}
                sx={{
                    '& .MuiFormControl-root': {
                        width: "100%"
                    }
                }}
            >
                <FormControlLabel value="landplot" control={<Radio />} label={t('landplot')} />
                <FormControlLabel value="farmers" control={<Radio />} label={t('farmer')} />
            </RadioGroup>
        </FormControl>
        <p className="table-label-indicator" style={{ marginTop: "40px", textAlign: "center" }}>{getGraphTitle()}</p>
        {getLegend()}
        {chartSeries ?
            <div style={{ position: "relative" }}>
                <BarChart
                    slotProps={{
                        legend: {
                            hidden: true
                        }
                    }}
                    xAxis={[{ scaleType: 'band', label: tabIndex === 0 ? t("day") : t('month'), data: getXAxis().reverse() }]}
                    series={chartSeries.map(s => { return { data: s.data, label: s.label, stack: s.stack, color: s.color } })}
                    height={300} width={500} />
                <p style={{ position: "absolute", top: "50%", bottom: "50%", transform: "rotate(270deg)", left: "-15px", width: "30px", height: "10px" }}>
                    {isLandPlotSelected() ? t('landplots') : t('farmers')}
                </p>
            </div>
            : <></>}

        <Grid container style={{ marginTop: "3px" }}>
            <Grid className="sidebar-section-container" style={{ background: "#ffffff88" }} direction={"column"} container>
                <p className="sidebar-section-title">{t("onboarding_graph_total_landplots")}</p>
                <Grid style={{ margin: "20px 4px 4px 4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                    <p className="sidebar-number">{chartsData.current?.landplots.length ?? 0}</p>
                </Grid>
            </Grid>
        </Grid>
        <Grid container style={{ marginTop: "3px" }}>
            <Grid className="sidebar-section-container" style={{ background: "#ffffff88" }} container direction={"column"}>
                <p className="sidebar-section-title">{t('onboarding_graph_total_farmers')}</p>
                <Grid style={{ margin: "20px 4px 4px 4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                    <p className="sidebar-number">{chartsData.current?.farmers.length ?? 0}</p>
                </Grid>
            </Grid>
        </Grid>
    </div>)
}