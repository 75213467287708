import { LoadingButton } from "@mui/lab"
import { TextField } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { EnterLayout } from "./EnterLayout"
import { firebaseApp, firebaseAuth } from "../pages/login/firebaseInit"
import { reauthenticateWithCredential, updatePassword } from "firebase/auth"
import { checkPassword, showErrorMessage, showSuccessMessage } from "../helper/helper"

type PwdWizardProps = {
    currentEmail: string,
    onChangePasswordSucceed: () => void
    onCancel: () => void
}

export function PasswordWizard(props: PwdWizardProps) {

    const { t } = useTranslation()

    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")

    const [saving, setSaving] = useState<boolean>(false)

    function changePassword() {
        setSaving(true)
        if (checkPassword(newPassword)) {
            reauthenticateWithCredential(firebaseAuth().currentUser, firebaseApp.auth.EmailAuthProvider.credential(props.currentEmail, oldPassword))
                .then(() => {
                    updatePassword(firebaseAuth().currentUser, newPassword).then(user => {
                        showSuccessMessage(t('change_pwd_success'))
                        props.onChangePasswordSucceed()
                    }).catch(e => {
                        onError(e?.message)
                    }).finally(() => setSaving(false))
                }).catch(e => {
                    onError(e?.message)
                })
        } else {
            onError(t('password_pattern'))
        }
    }

    function onError(message?: string) {
        setSaving(false)
        showErrorMessage(message)
    }

    return (<div className="p-[30px]">
        <EnterLayout onClick={() => changePassword()}>
            <div className="flex flex-col">

                <div className="flex flex-col gap-[10px] items-center mb-[20px]">
                    <p className="text-xl">{t('change_password')}</p>
                    <p>{t('change_pwd_instruction')}</p>
                </div>
                <p className="font-medium text-center my-[10px]">{props.currentEmail}</p>
                <p className="my-[10px]">{t('old_password')}</p>
                <TextField type="password" disabled={saving} value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                <p className="my-[10px]">{t('new_password')}</p>
                <TextField disabled={saving} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                <div className="flex justify-end gap-[20px]" >
                    <LoadingButton onClick={() => props.onCancel()} style={{ marginTop: "30px" }} variant="outlined" loading={saving}>{t('cancel')}</LoadingButton>
                    <LoadingButton onClick={() => props.onChangePasswordSucceed()} style={{ marginTop: "30px" }} variant="contained" loading={saving}>{t('save')}</LoadingButton>
                </div>
            </div>
        </EnterLayout>
    </div>)
}