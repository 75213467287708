import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { myAdminCompanies } from "../../api";
import { Loader } from "../../components/Loader";
import { transactorId } from "../../helper/TokenHelper";
import { Organisation, buildOrganisationFromDetail } from "../../models/organisation/Organisation";
import "./Admin.css";

function Admin({ client, onViewMoreClick }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>()

    const [companies, setCompanies] = useState<Organisation[]>([])

    const CompaniestHeader = () => {
        return <tr>
            <td>{t('name')}</td>
            <td>{t('location')}</td>
            <td>{t('employees')}</td>
        </tr>
    }

    const CompaniestRow = (company: Organisation, onCompanyClick) => {
        return <tr className="hoverGreenPale" style={{cursor:"pointer"}} key={company.id} onClick={() => {
            onCompanyClick(company)
        }}>
            <td>{company.name}</td>
            <td>{company?.location?.village}</td>
            <td>{company?.employees?.length}</td>
        </tr>
    }

    useEffect(() => {
        setLoading(true)
        const fetchCompanies = () => {
            client.query({
                query: myAdminCompanies,
                variables: {
                    transactorId: transactorId()
                }
            }).then(result => {
                let orgs: Organisation[] = result.data.organisations.map(o => buildOrganisationFromDetail(o.organisation))
                orgs.sort((orgA, orgB) => { return orgA.name.localeCompare(orgB.name) })
                setCompanies(orgs)
            }).finally(() => {
                setLoading(false)
            })
        }
        fetchCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <h1>{t('admin')}</h1>
            <div style={{ display: "flex", flexDirection:"row" }}>
                <table className="company-table" style={{ borderCollapse: "collapse", flex: "1", margin: "0px 20px 0px 28px" }}>
                    <tbody>
                        {CompaniestHeader()}
                        {companies.map((company) => CompaniestRow(company, (company) => { onViewMoreClick(company) }))}
                    </tbody>
                </table>
            </div>
            <Loader isLoading={loading}/>
        </div>
    )
}

export default Admin