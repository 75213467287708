export class ContractPhoto {
    uri: string
    created_at: string
    type?: string
    constructor(uri: string, created_at?: string, type?: string) {
        this.uri = uri
        this.created_at = created_at
        this.type = type
    }

    isWeightReceipt() {
        return this.type === 'weight_receipt' || this.type === 'sortation_ticket'
    }

    isFruitWhole() {
        return this.type === 'fruit_whole' || this.type === 'fruit_pile'
    }

    isFruitSingle() {
        return this.type === 'fruit_single' || this.type === 'fruit_bunch'
    }

    isFruit() {
        return this.isFruitSingle() || this.isFruitWhole()
    }
}

export function buildContractPhoto(p: any): ContractPhoto {
    return new ContractPhoto(p.uri, p.created_at, p.type)
}