export class LineItem {
    sku: string
    total: number
    quantity: number
    price_per_unit: number
    title: string

    constructor(sku: string,
        total: number,
        quantity: number,
        price_per_unit: number,
        title?: string) {
        this.sku = sku
        this.total = total
        this.quantity = quantity
        this.price_per_unit = price_per_unit
        this.title = title
    }

    isETaxLineItem(): boolean {
        return this.sku === "ETAX_0001"
    }
}

export function buildLineItem(li: any): LineItem {
    return (new LineItem(li.sku, li.total, li.quantity, li.price_per_unit, li.line_item_sale_item_static_description?.title))
}