import { Harvest, buildHarvestForContractDetail, buildHarvestForDashboard, buildHarvestForExport } from "./Harvest";

export class ContractHarvest {
    harvest: Harvest

    constructor(harvests: Harvest) {
        this.harvest = harvests
    }
}

export function buildContractHarvestForDetail(ch: any): ContractHarvest {
    return new ContractHarvest(buildHarvestForContractDetail(ch.harvest))
}

export function buildContractHarvestForExport(ch: any): ContractHarvest {
    return new ContractHarvest(buildHarvestForExport(ch.harvest))
}


export function buildContractHarvestForDashBoard(ch: any): ContractHarvest {
    return new ContractHarvest(buildHarvestForDashboard({
        ...ch.harvest,
        ppunchPerQuantity: ch.ppunchPerQuantity,
        ppunchTotal: ch.ppunchTotal,
        fcropTotal: ch.fcropTotal,
        priceBuyer: ch.priceBuyer,
        location: ch.location,
        contractId: ch.contractId,
        isPlotInProgram: ch.isPlotInProgram,
        grading: ch.grading
    }))
}