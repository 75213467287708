import { MarketTurn, buildMarketTurn } from "./MarketTurn"

const DEMAND = "demand"
const SUPPLY = "supply"
const GENERATOR = "generator"
const WORKER = "worker"
const FULFILLMENT = "fulfillment"


export class NiagaMarket {
    closingId?: string
    demand: MarketTurn
    supply: MarketTurn
    generator: MarketTurn
    worker: MarketTurn
    fulfillment: MarketTurn
    marketDay?: string

    constructor(demand?: MarketTurn,
        supply?: MarketTurn,
        generator?: MarketTurn,
        worker?: MarketTurn,
        fulfillment?: MarketTurn,
        closingId?: string,
        market_day?: string) {
        this.closingId = closingId
        this.demand = demand
        this.supply = supply
        this.generator = generator
        this.worker = worker
        this.fulfillment = fulfillment
        this.marketDay = market_day
    }

    getMarketTurn(): string {
        if (this.demand.open) {
            return DEMAND
        } else if (this.supply.open) {
            return SUPPLY
        } else if (this.generator.open) {
            return GENERATOR
        } else if (this.worker.open) {
            return WORKER
        } else if (this.fulfillment.open) {
            return FULFILLMENT
        }
    }

    getCurrentTurn(): MarketTurn {
        if (this.isDemandTurn()) {
            return this.demand
        } else if (this.isSupplyTurn()) {
            return this.supply
        } else if (this.isWorkerTurn()) {
            return this.worker
        } else if (this.isFulfillmentTurn()) {
            return this.fulfillment
        }
    }

    getNextTurn(): MarketTurn {
        if (this.isDemandTurn()) {
            return this.supply
        } else if (this.isSupplyTurn()) {
            return this.worker
        } else if (this.isSupplyTurn()) {
            return this.fulfillment
        } else if (this.isFulfillmentTurn()) {
            return this.demand
        }
    }

    isDemandTurn(): boolean {
        return this.demand.open
    }

    isSupplyTurn(): boolean {
        return this.supply.open
    }

    isGeneratorTurn(): boolean {
        return this.generator.open
    }

    isWorkerTurn(): boolean {
        return this.worker.open
    }

    isFulfillmentTurn(): boolean {
        return this.fulfillment.open
    }
}

export function buildNiagaMarket(m: any, market_day: any = undefined): NiagaMarket {
    return new NiagaMarket(buildMarketTurn(m.demand),
        buildMarketTurn(m.supply),
        buildMarketTurn(m.generator),
        buildMarketTurn(m.worker),
        buildMarketTurn(m.fulfillment),
        m.closingId,
        market_day)
}