import React, { useEffect, useState } from "react"
import { PRICE_CODE_CAN_PUBLISH_PRICE_NOT_DEMAND, PRICE_CODE_MARKET_CLOSE, PRICE_CODE_NO_ORG_LOCATION, PriceForOrganisation } from "../MyPrices"
import { Organisation } from "../../../models/organisation/Organisation"
import { ReactComponent as Forbidden } from "../../../rsc/ic_forbidden.svg";
import { colorLightGrey, colorPrimary, colorPurpleDark } from "../../../rsc/colors/colors";
import { ReactComponent as Check } from "../../../rsc/check.svg";
import { ReactComponent as Demand } from "../../../rsc/ic_do.svg";
import { t } from "i18next";
import { PriceRow } from "./PriceRow";
import { Collapse } from "@mui/material";
import { BidModel } from "../../../models/bid/BidModel";

type ExpandablePriceProps = {
    info: PriceForOrganisation
    isEditable: (org: Organisation, price: BidModel) => boolean
    isMarketClose: () => boolean
    getPriceStatus: (info: PriceForOrganisation) => number
    getErrorReason: (org: Organisation) => string | null
    onValidatePrice: (price: BidModel, org: Organisation, value: number) => Promise<BidModel>
    onDeletePrice: (price: BidModel, org: Organisation) => Promise<BidModel>
    onPriceClick?: (org: Organisation) => void
}

export function ExpandablePrice(props: ExpandablePriceProps) {
    const [open] = useState(true)
    const [prices, setPrices] = useState<BidModel[]>(props.info.price.bids)

    useEffect(() => {
        setPrices(props.info.price.bids)
    }, [props.info.price])

    const handleClick = () => {
        // setOpen(!open)
        props?.onPriceClick(props.info.org)
    };

    function priceUpdated(price: BidModel) {
        let index = prices.findIndex(p => p.market_day === price.market_day)
        prices[index] = price
        setPrices([...prices])
        // setOpen(false)
    }

    function getPriceStatusMessage() {
        let icon = <Forbidden style={{ height: "15px", width: "15px" }} />
        let message = ""
        let color = colorPrimary
        let status = props.getPriceStatus(props.info)
        if (status === PRICE_CODE_MARKET_CLOSE) {
            message = t('market_close')
            color = colorPurpleDark
        } else if (status === PRICE_CODE_NO_ORG_LOCATION) {
            message = t('need_mill_position')
            color = colorPurpleDark
        } else if (status === PRICE_CODE_CAN_PUBLISH_PRICE_NOT_DEMAND) {
            icon = <Check style={{ height: "15px", width: "15px" }} />
            message = t('edit_prices_for_next_days')
        } else {
            icon = <Check style={{ height: "15px", width: "15px" }} />
            message = t('edit_prices_for_all_days')
        }
        return (
            <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                {icon}
                <p style={{ margin: "0px 0px 0px 7px", color: color, display: "inline-block" }}>{message}</p>
            </div>
        )
    }

    return (
        <div key={props.info.org.id}>
            <div onClick={handleClick} style={{ background: colorLightGrey }}>
                <div style={{ flex: "1", display: "flex", alignItems: "end", padding: "20px 15px 20px 15px", flexDirection: "row" }}>
                    <Demand style={{ height: "20px" }} />
                    <p style={{ margin: "0 0 0 15px", fontSize: "20px", fontWeight: "700" }}>{props.info.org.name}</p>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Collapse style={{ display: "inline-block", flex: "1", paddingBottom: "15px" }} in={open} timeout="auto" unmountOnExit>
                    {getPriceStatusMessage()}
                    {
                        prices.map(p => {
                            return (
                                <PriceRow
                                    key={p.market_day}
                                    price={p}
                                    org={props.info.org}
                                    setPrice={priceUpdated}
                                    isEditable={props.isEditable}
                                    getErrorReason={props.getErrorReason}
                                    onValidatePrice={props.onValidatePrice}
                                    onDeletePrice={props.onDeletePrice} />

                            )
                        })
                    }
                    {/* {
                        <PriceVisionComponent priceVision={props.info.priceVision} />
                    } */}
                </Collapse>
            </div>
        </div>
    )
}
