import { AppBar, Box, Snackbar, Toolbar } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Location, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MyProfile, myCompaniesV2 } from "../api";
import { BurgerMenu } from "../components/BurgerMenu";
import { Loader } from "../components/Loader";
import { Menu } from "../components/Menu";
import ProfileActions from "../components/ProfileAction";
import { SwitchLang } from "../components/SwitchLang";
import { getUserRoles, transactorId, userId } from "../helper/TokenHelper";
import { clearErrorMessage, showErrorMessage } from "../helper/helper";
import { UserTokenModel, getUserFromApi } from "../models/UserTokenModel";
import { Organisation, buildOrganisationFromDetail } from "../models/organisation/Organisation";
import { colorDarkBlue } from "../rsc/colors/colors";
import { AdminInfo, LogState, MessageType, NFMStore, logout, setIsLoggedIn, setUser, updateAdmin, updateToken } from "../stores/store";
import Explorer from "./Explorer/Explorer";
import { ConfirmAccount } from "./create-account/ConfirmAccount";
import { CreateAccount } from "./create-account/CreateAccount";
import Auth from "./login/Auth";
import { firebaseAuth } from './login/firebaseInit';
import './styles/colors.scss';

export async function signOut() {
  try {
    await firebaseAuth().signOut()
    NFMStore.dispatch(logout())
  } catch (error) {
    console.log(error)
  }
}

function RoutesPempem({ client }) {

  const token = useSelector((state: any) => state.token.value)
  const isLoggedIn: LogState | null = useSelector((state: any) => state.isLoggedIn.value)
  const user: UserTokenModel | null = useSelector((state: any) => state.user.value)

  const [errorMessage, setErrorMessage] = useState<MessageType>(null)

  const isAdminRef = useRef<AdminInfo>({ dashboardEnable: false, hasMill: false })

  const currentPath = useRef<Location>()

  const { t } = useTranslation()

  const location = useLocation()

  let isEmailSendProcess = useRef(false)

  const navigate = useNavigate()

  NFMStore.subscribe(() => {
    setErrorMessage(NFMStore.getState().errorMessage.value)
  })

  useEffect(() => {
    addItTokenChangeListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      NFMStore.dispatch(setIsLoggedIn({ LoggedIn: true, forceRedirectLogin: false }))
    } else if (user === null) {
      NFMStore.dispatch(setIsLoggedIn({ LoggedIn: false, forceRedirectLogin: false }))
    }
  }, [user])

  async function fetchInitUserData() {
    const userProfile = await fetchProfile()
    // fetch company to know if it is a admin
    const orgs = await fetchCompany()

    const dashboardEnable = orgs.find(o => o.organisation_type === "conglomerate") !== undefined || (getUserRoles()?.find(r => r === 'operator') ? true : false)
    const hasMill = orgs.find(o => o.organisation_type === "mill") !== undefined

    if (hasMill || dashboardEnable) {
      const object = { dashboardEnable: dashboardEnable, hasMill: hasMill }
      isAdminRef.current = object

      NFMStore.dispatch(setUser(userProfile))
    } else {
      signOut()
      NFMStore.dispatch(setUser(null))
      if (currentPath.current.pathname.split('/')[1] === 'explorer') {
        navigate('/')
      } else {
        navigate(currentPath.current)
      }
      showErrorMessage(t('incorrect_role'))
    }
  }

  useEffect(() => {
    if (token) {
      fetchInitUserData()
    } else if (token === null) {
      const object = { dashboardEnable: false, hasMill: false }
      isAdminRef.current = object
      NFMStore.dispatch(updateAdmin({ ...object }))
      NFMStore.dispatch(setUser(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const fetchCompany = (): Promise<Organisation[]> => {
    return new Promise((s, r) => {
      client.query({
        query: myCompaniesV2,
        variables: {
          transactorId: transactorId()
        }
      }).then(result => {
        const orgs = result.data.organisations.map(o => buildOrganisationFromDetail(o.organisation))
        s(orgs)
      }).catch(e => {
        r(e)
      })
    })
  }

  const fetchProfile = (): Promise<UserTokenModel> => {
    return new Promise((s, r) => {
      client.query({
        query: MyProfile,
        variables: {
          userId: userId()
        }
      }).then(result => {
        s(getUserFromApi(result.data.person[0]))
      }).catch(e => {
        r(e)
      })
    })
  }

  const addItTokenChangeListener = () => {
    firebaseAuth().onIdTokenChanged(async user => {
      if (user && user.emailVerified && !isEmailSendProcess.current) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const token = await user.getIdToken()
        NFMStore.dispatch(updateToken(token))
      } else {
        NFMStore.dispatch(updateToken(null))
      }
    })
  }

  useEffect(() => {
    if (isLoggedIn?.LoggedIn === true) {
      if (currentPath.current.pathname === "/") {
        if (isAdminRef.current.hasMill) {
          navigate("explorer/my_prices")
        } else {
          navigate("explorer/dashboard")
        }
      }
    } else if (isLoggedIn?.LoggedIn === false) {
      if (currentPath.current.pathname.split('/')[1] === 'explorer' || isLoggedIn.forceRedirectLogin === true) {
        navigate('/')
      } else {
        navigate(currentPath.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    currentPath.current = location
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const ShowCorrectView = (isAdminRef: AdminInfo) => {
    if (isLoggedIn?.LoggedIn === true) {
      return (
        <Routes>
          <Route path='/' element={<Auth client={client} />} />
          <Route path='explorer/*' element={<Explorer logOut={signOut} isAdmin={isAdminRef} client={client} menuOpen={false} />} />
          <Route path='create-account' element={<CreateAccount />} />
          <Route path='confirm-account' element={
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <ConfirmAccount client={client} />
            </GoogleOAuthProvider>
          } />
        </Routes>
      )
    } else if (isLoggedIn?.LoggedIn === false) {
      return <Routes>
        <Route path='/' element={<Auth client={client} />} />
        <Route path='create-account' element={<CreateAccount />} />
        <Route path='confirm-account' element={
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <ConfirmAccount client={client} />
          </GoogleOAuthProvider>
        } />
      </Routes>
    } else {
      return <div>
        <Loader isLoading={true}/>
      </div>
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <Box sx={{ display: 'flex', height: "calc(100% - 64px)" }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: colorDarkBlue }}>
          <Toolbar>
            <BurgerMenu />
            {user ?
              <div style={{ display: "flex", flex: "1", justifyContent: "right" }}>
                <SwitchLang />
                <ProfileActions
                  goToProfile={() => navigate("/explorer/profile")}
                  logOut={() => signOut()}
                  goToAdminPage={() => navigate("/explorer/admin")}
                  isAdmin={isAdminRef.current}
                  style={{ height: "100%", userSelect: "none", margin: "0px", top: "0px", display: "inline", right: "28px", cursor: "pointer" }} />
              </div>
              : <></>}
          </Toolbar>
        </AppBar>
        {
          isLoggedIn?.LoggedIn && <Menu isAdminRef={isAdminRef.current} />
        }
        <div style={{ flexGrow: 1, height: "100%" }}>
          <Toolbar />
          {ShowCorrectView(isAdminRef.current)}
        </div>
      </Box>
      {
        errorMessage ?
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={errorMessage !== null}
            autoHideDuration={6000}
            onClose={() => { clearErrorMessage() }}
            message={errorMessage.message}
            sx={{
              '& .MuiSnackbarContent-root': {
                backgroundColor: errorMessage.color,
                fontWeight: "600"
              }
            }}
          /> : <></>
      }
    </div>
  );
}
export default RoutesPempem;