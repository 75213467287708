import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import dayjs from "dayjs"
import React, { useRef, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { ContractList } from "../../models/contract/ContractList"
import { Organisation } from "../../models/organisation/Organisation"
import ContractDetail from "./ContractDetail"
import PreviousContract, { CompletedContractOptions, ContractListItem } from "./PreviousContract"
import { InvoiceDetail } from "../invoice/InvoiceDetail"

type ContractsProps = {
    client: ApolloClient<NormalizedCacheObject>
}

function CompletedContracts({ client }: ContractsProps) {
    const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs().startOf('month'))
    const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs())
    const [startDateOpen, setStartDateOpen] = useState(false)
    const [endDateOpen, setEndDateOpen] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState<Organisation>(null)
    const [organisations, setOrganisations] = useState<Organisation[]>(undefined)
    const [contracts, setContracts] = useState<ContractList[]>([])
    const [contractToDisplay, setContractToDisplay] = useState<ContractListItem[]>([])
    const [optionSelected, setOptionSelected] = useState<CompletedContractOptions>(CompletedContractOptions.NONE)

    const navigate = useNavigate()

    const selectedContract = useRef<ContractList>(undefined)

    const onContractDetailClick = (contract: ContractList) => {
        selectedContract.current = contract
        navigate(`contract_detail?id=${contract.statement.id}&contract_type=${contract.contract_type}`)
    }

    return (
        <div>
            <Routes>
                <Route path="contract_detail" element={<ContractDetail client={client} />}></Route>
                <Route path="invoice_detail/:id" element={<InvoiceDetail client={client} />}></Route>
                <Route path="/" element={<PreviousContract
                    client={client}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDateOpen={startDateOpen}
                    setStartDateOpen={setStartDateOpen}
                    setEndDateOpen={setEndDateOpen}
                    endDateOpen={endDateOpen}
                    onViewMoreClick={c => onContractDetailClick(c)}
                    selectedOrg={selectedOrg}
                    setSelectedOrg={setSelectedOrg}
                    organisations={organisations}
                    contractToDisplay={contractToDisplay}
                    setContractToDisplay={setContractToDisplay}
                    setOrganisations={setOrganisations}
                    contracts={contracts}
                    setContracts={setContracts}
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                    />}></Route>
            </Routes>
        </div>
    )
}

export default CompletedContracts