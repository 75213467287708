import React, { CSSProperties, useEffect, useState } from "react"
import 'firebase/compat/storage';
import { firebaseApp } from "../pages/login/firebaseInit";
import { Modal } from "./Modal";

type FirebaseImageProps = {
    style: CSSProperties
    uri: string
    onMetadataLoaded?: (metadata: any) => void
}


export function FirebaseImage({ uri, style, onMetadataLoaded }: FirebaseImageProps) {

    const [imageToLoad, setImageToLoad] = useState("")
    const [isImageClicked, setIsImageClicked] = useState(false)

    useEffect(() => {
        const loadImage = async () => {
            let ref = firebaseApp.storage().refFromURL(uri);
            let metadata = await ref.getMetadata()
            let url = await ref.getDownloadURL();
            if (onMetadataLoaded) {
                onMetadataLoaded(metadata.customMetadata)
            }
            setImageToLoad(url)
        }
        loadImage()
        // eslint-disable-next-line
    }, [])

    return (
        <div style={style}>
            <img style={{ width: "100%", height: "100%" }} alt="" src={imageToLoad} onClick={() => setIsImageClicked(true)}></img>
            {
                isImageClicked ?
                    <Modal onClose={() => setIsImageClicked(false)}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                            <img style={{ width: "25%" }} alt="" src={imageToLoad}></img>
                        </div>
                    </Modal>
                    : <></>
            }
        </div>
    )
}