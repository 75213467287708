import { gql } from '@apollo/client';

export const myPrices = gql`
query palmoil_price_within_radius($args: palmoil_price_within_radius_args!) {
    price: palmoil_price_within_radius(args: $args) {
      __typename
      price {
        id
        price_per_quantity_unit
      }
      organisation {
        id
        organisation_type
        positionepsg4326
      }
    }
  }
`
export const myCompanies = gql`
    query palmoil_organisation_by_pk($transactorId: Int!) {
        organisations: palmoil_transactor_organisation(where:{
            transactor_id: {_eq: $transactorId},
            _or: [
              {organisation: {organisation_type: {_eq: "mill"}}}
            ]
            organisation: {deleted_at: {_is_null: true}}
        }){
            transactor {
                person_id
            }
            organisation {
                id,
                organisation_type,
                location {
                    id,
                    village,
                    province
                   },
                positionepsg4326,
                name,
                reputation_level,
                employees {
                    transactor {
                        person {
                               firstname
                           }
                    }
                }
            }
}
}`

export const myAdminCompanies = gql`
    query palmoil_organisation_by_pk($transactorId: Int!) {
        organisations: palmoil_transactor_organisation(where:{
            transactor_id: {_eq: $transactorId},
            _or: [
              {organisation: {organisation_type: {_eq: "conglomerate"}}}
            ]
            organisation: {deleted_at: {_is_null: true}}
        }){
            transactor {
                person_id
            }
            organisation {
                id,
                organisation_type,
                location {
                    id,
                    village,
                    province
                   },
                positionepsg4326,
                name,
                reputation_level,
                employees {
                    transactor {
                        person {
                               firstname
                           }
                    }
                }
            }
}
}`

export const myCompaniesV2 = gql`
    query palmoil_organisation_by_pk($transactorId: Int!) {
        organisations: palmoil_transactor_organisation(where:{
            transactor_id: {_eq: $transactorId},
            _or: [
              {organisation: {organisation_type: {_eq: "mill"}}},
              {organisation: {organisation_type: {_eq: "conglomerate"}}}
            ]
            organisation: {deleted_at: {_is_null: true}}
        }){
            transactor {
                person_id
            }
            organisation {
                id,
                organisation_type,
                location {
                    id,
                    village,
                    province
                   },
                positionepsg4326,
                name,
                reputation_level,
                employees {
                    transactor {
                        person {
                               firstname
                           }
                    }
                }
            }
}
}`

export const myCompaniesContract = gql`
    query palmoil_organisation_by_pk($transactorId: Int!) {
        organisations: palmoil_transactor_organisation(where:{
            transactor_id: {_eq: $transactorId},
            _or: [
              {organisation: {organisation_type: {_eq: "mill"}}}
            ]
            organisation: {deleted_at: {_is_null: true}}
        }){
            transactor {
                person_id
            }
            organisation {
                id,
                organisation_type,
                location {
                    id,
                    village,
                    province
                   },
                positionepsg4326,
                name,
                apply_vat,
                reputation_level,
                employees {
                    transactor {
                        person {
                               firstname
                           }
                    }
                }
            }
}
}`


export const removePersonFromCompany = gql`
mutation removePersonFromCompany(
  $transactorId: Int!,
  $organisationId: Int!,
) {
  delete_palmoil_transactor_organisation(where: {transactor_id: {_eq: $transactorId}, organisation_id: {_eq: $organisationId}})
  {
    returning {
      organisation_id
      organisation {
        employees {
          transactor_id
          transactor {
            person {
              firstname
              lastname
              user {
                avatar_url
                email
              }
              location {
                id
                village
              }
            }
          }
        }
    }
    }
  }

  delete_palmoil_organisation_permissions_request(
      where: {
          transactor_id: {_eq: $transactorId},
          organisation_id: {_eq: $organisationId}
      }
  ){
      affected_rows
  }
}`


export const getCompanyDetail = gql`
query palmoil_organisation_by_pk($organisationId: Int!) {
    organisation: palmoil_organisation_by_pk(id: $organisationId) {
      id
      organisation_type
      positionepsg4326
      reputation_level
      location {
        id
        village
        province
      }
      name
      employees {
        transactor_id
        transactor {
          id
          person {
            id
            firstname
            lastname
            user {
              avatar_url
              email
            }
            location {
              id
              village
            }
          }
        }
      }
    }
  }`

export const getTransactorOrganisationDetail = gql`
query palmoil_transactor_organisation($organisationId: Int!, $transactorId: Int!) {
    organisation: palmoil_transactor_organisation(where: {organisation_id: {_eq: $organisationId}, transactor_id: {_eq: $transactorId}}) {
      control_org
      organisation {
      id
      organisation_type
      positionepsg4326
      reputation_level
      location {
        id
        village
        district
        subdistrict
        province
      }
      name
      employees {
        transactor_id
        transactor {
          id
          person {
            id
            firstname
            lastname
            user {
              avatar_url
              email
            }
            location {
              id
              village
            }
          }
        }
      }
      apply_vat
      is_withholding_tax
      tax_identification_number
      address
      legal_name
      rt
      rw
      email
      vat_transaction_type
      legal_location {
        id
        village
        district
        subdistrict
        province
      }
      postal_code
      phonenumber
    }
    }
  }`

export const MyProfile = gql`
  query palmoil_person($userId: String!) {
    person: palmoil_person(where: {user: {id: {_eq: $userId}}}) {
      firstname
      lastname
      user {
        avatar_url
      }
    }
  }`


export const GetActiveContracts = gql`
  query niagaContractsActive($market_day: date!, $market_day_end: date!) {
    palmoil_sale(where:{_and:[
      {transport_crew_contract_sales:{transport_crew_contract:{market_day:{_gte:$market_day}}}},
      {transport_crew_contract_sales: {transport_crew_contract: {market_day: {_lte:$market_day_end}}}},
      {transport_crew_contract_sales: {party: {_eq: "buyer"}}},
      {transport_crew_contract_sales: {transport_crew_contract: {canceled_at: {_is_null: true}}}}
    ],
    _or: [{transport_crew_contract_sales: {transport_crew_contract: {status: {_eq: "OPEN"}}}},
      {transport_crew_contract_sales: {transport_crew_contract: {status: {_eq: "OPEN_DELIVERED"}}}}
      ]}){
         id
         status: transaction_state
          sale_invoice_item{
            invoice_id
          }
          sale_items{
            id
            quantity
            price_per_quantity_unit
            updated_at
            created_at
            product{
              sku
            }
          }
        transport_crew_contract_sales{
          transport_crew_contract_id
          sale_statement {
            id
          }
          transport_crew_contract{
            market_day
            contract_type
          }
      }
    }
}`

export const GetCompletedContract = gql`
query niagaContractsCompleted(
  $orgId: Int!
  $market_day: date!
  $market_day_end: date!
) {
  palmoil_transport_crew_contract_sale(
    where: {
      _and: [
        { sale: { buyer_organisation_id: { _in: [$orgId] } } }
        {
          _or: [
            { transport_crew_contract: { status: { _eq: "COMPLETED" } } }
            { transport_crew_contract: { status: { _eq: "OPEN_DELIVERED" } } }
          ]
        }
        { transport_crew_contract: { market_day: { _gte: $market_day } } }
        { transport_crew_contract: { market_day: { _lte: $market_day_end } } }
      ]
    }
  ) {
    id
    transport_crew_contract_id
    sale_statement {
      id
      line_items {
        quantity
        price_per_unit
        updated_at
        created_at
        product {
          sku
        }
      }
    }
    transport_crew_contract {
      market_day
      contract_type
      status
    }
  }
}`

export const GetHarvestInfosExport = gql`
query GetHarvestInfosExport($contracts: [Int!]!) {
  niaga_contract_list_export(filter: {contract_ids: $contracts}) {
    items {
      contract_id
      market_day
      net_quantity
      harvests {
        fruit_pile_uri
        fruit_bunch_uri
        fruit_pile_position
        quantity
        loaded_quantity
        farmer {
          organisation {
            land_plots(where: {deleted_at: {_is_null: true}}) {
              id
            }
            id
            name
            location {
              id
              village
              subdistrict
              district
              province
            }
          }
        }
        land_plot {
          id
          polygon
          size
          pickup_position
          polygon
          location {
            village
          }
        }
      }
    }
  }
}
`

export const GetContractsDashBoard = gql`
  query niagaContracts($filter: contract_filter_input) {
    contracts: niaga_contracts(filter: $filter) {
      id
      status
      market_day
      sale_statements {
        party
        sale_statement {
          id
          total
          state
          status
          photos {
            photo {
              uri
              created_at
            }
          }
          line_items {
            sku
            total
            quantity
            price_per_unit
          }  
        }
      }
      invoice_item {
        invoice_id
      }
      primary_buyer_id
      primary_buyer {
        organisation {
          id
          name
          positionepsg4326
        }
      }
      sellers {
        seller {
          organisation {
            location {
              id
              position
              province
              subdistrict
              village
            }
          }
        }
      }
      harvests {
        harvest {
          farmer {
            transactor {
                id
                person {
                  id
                }
            }
          }
          quantity
          land_plot {
            id
            name
            polygon
          }
        }
      }
    }
}`

export const GetNiagaContract = gql`
query niagaPrimaryBuyerContractById($statementId: Int!) {
  niaga_contract_detail(filter: {statement_id: $statementId}) {
      contract_id
      statement_id
      status
      market_day
      harvests {
        id
        photos {
          photo {
            type
            uri
          }
        }
        quantity
        land_plot {
          id
          name
          polygon
          pickup_position
        }
      }
      statement {
          statement {
            id
            state
            status
            total
            tax_total
            pre_tax_total
            photos {
              photo {
                type
                uri
                created_at
              }
            }
            line_items {
              sku
              total
              quantity
              price_per_unit
              line_item_sale_item_static_description {
                title
              }
            }
          }
      }
      primary_buyer  {
        party_context {
          transactor {
            person {
              firstname
              lastname
              user {
                avatar_url
              }  
            }
          }
          organisation {
            id
            name
          }
        }
          invoice_id
      }
      transporter {
          party_context {
              transactor {
                person {
                  firstname
                  lastname
                  location {
                    village
                    district
                  }  
                  user {
                    avatar_url
                  }  
                }
              }
              organisation_id
          }
      }
  }
}
`

export const getContractAgent = gql`
query GetAgentContract($statementId: Int!) {
  niaga_agent_contract_detail(filter: { statement_id: $statementId }) {
    contract_id
    status
    party
    market_day
    harvests {
      photos {
        photo {
          type
          uri
        }
      }
      quantity
      land_plot {
        id
        name
        polygon
        pickup_position
      }
    }
    statement {
      sale_statement_id
      statement {
        id
        state
        status
        total
        tax_total
        pre_tax_total
        photos {
          photo {
            type
            uri
            created_at
          }
        }
        line_items {
          sku
          total
          quantity
          price_per_unit
          line_item_sale_item_static_description {
            title
          }
        }
      }
    }
    agent {
      party_context {
        transactor {
          person {
            firstname
            lastname
            location {
              village
              district
            }  
            user {
              avatar_uri
            }
          }
        }
        organisation {
          id
          name
        }
      }
    }
    primary_buyer {
      party_context {
        organisation {
          id
          name
        }
      }
    }
  }
}
`

export const UpdateOrganisation = gql`
  mutation updateOrganisation($organisationId: Int!, $object: palmoil_organisation_set_input!) {
    organization: update_palmoil_organisation_by_pk(
        pk_columns: { id: $organisationId },
        _set: $object
    ) {
        id
    }
}`

export const GetMyPrices = gql`
query GetMyPrices {
  prices: palmoil_my_prices {
      price_type
      prices {
        id
        price_per_quantity_unit
        publisher_organisation {
              id
        }
        market_day
        transactor {
          id
          person {
            lastname
            firstname
          }
        }
        created_at
      }
  }
}`

export const NiagaVisionPrice = gql`
query NiagaVisionPrice {
  prices: niaga_get_price_historic {
    organisation_id
    prices_map
    last_market_day
    last_market_price
  }
}`

export const UpdatePrice = gql`
mutation upsertNiagaPrice($input: niaga_set_price_input!) {
  price: niaga_set_price(input: $input) {
    price {
      id
      price_per_quantity_unit
      publisher_organisation {
           id
      }
      market_day
      transactor {
        id
        person {
          lastname
          firstname
        }
      }
      created_at
    }
  }
}`

export const UpsertBid = gql`
mutation NE_UpsertNiagaBid($input: niaga_upsert_bid_input!) {
  bid: niaga_upsert_bid(input: $input) {
    organisation_id
    bids {
      market_day
      price_per_kg
      updated_at
      updated_by
    }
  }
}`

export const GetBids = gql`
query NE_NiagaBuyerBid {
  bids: niaga_bid {
    organisation_id
    bids {
      market_day
      price_per_kg
      updated_at
      updated_by
    }
  }
}`

export const DeletePrice = gql`
mutation deletePrice($orgId: Int!, $startDate: timestamptz!, $endDate: timestamptz!, $marketDay: date!) {
  publicDeleted: delete_palmoil_price(where: {_and: {start_at: {_gte: $startDate}, end_at: {_lte: $endDate}, publisher_organisation_id: {_eq: $orgId}}}) {
    returning {
      id
    }
  }

  marketDeleted: delete_palmoil_price(where: {_and: {market_day: {_eq: $marketDay}, publisher_organisation_id: {_eq: $orgId}}}) {
    returning {
      id
    }
  }
}`

export const GetUserBankProvider = gql`
query getPaymentProviderConfig($userId: String!) {
  config: palmoil_payment_provider_config_by_pk(user_id : $userId) {
    provider_id
    bank_account_number
    account_name
    status
  }
}`

export const getPerson = gql`
query getPerson($email: String) {
  persons: palmoil_person(where: {user: {email: {_eq: $email}}}) {
    __typename
    id
    firstname
    lastname
    transactor {
      id
    }
    user {
      avatar_url
      email
    }
    location {
      id
      village
    }
  }
}`

export const addPersonMutation = gql`
mutation addEmployee(
  $transactorId: Int!,
  $organisationId: Int!,
  $controlOrg: Boolean!,
  $controlSales: Boolean!,
  $controlPrices: Boolean!,
  $readSales: Boolean!,
  $readPrices: Boolean!
) {
  manager: palmoil_create_employee(
      object: {
          transactorId: $transactorId
          organisationId: $organisationId
          controlOrg: $controlOrg
          controlSales: $controlSales
          controlPrices: $controlPrices
          readSales: $readSales
          readPrices: $readPrices
      }
  )
  {
      transactor_id
      organisation_id
  }
}`

export const createPersonQuery = gql`
mutation createAssociate($transactor: palmoil_create_transactor_input!) {
  associate: palmoil_create_transactor(object: $transactor) {
      transactor {
          id
      }
  }
}`

// INVITATION
export const getInvitedUserQuery = gql`
query palmoil_invitation($orgId: Int!) {
  users: palmoil_invitation(where: {inviter_organisation_id: {_eq: $orgId}}) {
    email
    firstname
    lastname
  }
}`


export const createInvitationMutation = gql`
mutation invite_user($object: palmoil_invitation_insert_input!) {
  user: insert_palmoil_invitation_one(object: $object, on_conflict:{constraint:unique_email_organisation, update_columns: [firstname, lastname]}) {
    email
    __typename
  }
}`

export const updateInvitationMutation = gql`
mutation update_user($object: palmoil_invitation_set_input!, $email: String!, $orgId: Int!, $oob: String!) {
  user: update_palmoil_invitation(_set: $object, where: {email: {_eq: $email}, inviter_organisation_id: {_eq: $orgId}, oob: {_eq: $oob}}){
    returning {
      status
    }
  }
}`

export const removeInvitationMutation = gql`
mutation MyMutation($email: String!, $orgId: Int!) {
  delete_palmoil_invitation(where: {email: {_eq: $email}, inviter_organisation_id: {_eq: $orgId}}) {
    returning {
      status
    }
  }
}`

export const reinviteMutation = gql`
mutation MyMutation($email: String!, $orgId: Int!, $userInfo: palmoil_invitation_insert_input!) {
  delete_palmoil_invitation(where: {email: {_eq: $email}, inviter_organisation_id: {_eq: $orgId}}) {
    returning {
      status
    }
  }

  user: insert_palmoil_invitation_one(object: $userInfo){
    email
  }
}`

export const SearchLocation = gql`
query searchLocation($locationText: String!){
  locations: palmoil_search_location(args: {search: $locationText}, limit: 20){
    id
    village
    district
    subdistrict
    province
  }
}`

export const GetLocationById = gql`
query getLocationById($locationId: Int!){
  location: palmoil_location(where: {id: {_eq: $locationId}}) {
    id
    village
    district
    subdistrict
    province
  }
}`

export const FetchProfile = gql`
query getMyProfile($userId: String!) {
    person: palmoil_person(where: {user: {id: {_eq:$userId}}}){
        firstname
        lastname
        nickname
        phones {
            id
            phonenumber
        }
        user {
            email
            avatar_uri
            avatar_url
        }
        location {
          id
          village
          district
          subdistrict
          province
        }
    }
}`

export const UpdateProfile = gql`
mutation updatePersonProfile(
  $personId: Int!
  $person: palmoil_person_set_input!
) {
  person: update_palmoil_person_by_pk(pk_columns: { id: $personId }, _set: $person){
      id
  }
}`

export const CreateInvoice = gql`
mutation insertInvoiceFromContract($data: [palmoil_invoice_item_insert_input!]!) {
  insert_palmoil_invoice_one(object:{invoice_items:{data:
    	$data
  	}}){
    id
  }
}
`

export const FetchLocationWithFilters = gql`
query fa_FilterLocation($province: String, $district: String, $subDistrict: String, $distinct: palmoil_location_select_column!) {
  palmoil_location(where: {province: {_eq: $province}, subdistrict: {_eq: $subDistrict}, district: {_eq: $district}}, distinct_on: [$distinct]) {
    id
    village
    district
    subdistrict
    province
  }
}
`

export const FetchOnboardedLandplot = gql`
query FetchOnboardedFarmers {
  palmoil_land_plot(where: {affiliate_reward_program_participant: {_is_null: false}}, order_by: {created_at: desc}) {
    created_at
    owner_person_id
  }
}`

export const FetchOnboardedFarmers = gql`
query FetchOnboardedFarmers($distinct: palmoil_land_plot_select_column!) {
  farmers: palmoil_land_plot(where: {affiliate_reward_program_participant: {_is_null: false}}, distinct_on: [$distinct]) {
    created_at
    owner_person_id
    owner {
      location {
        province
        subdistrict
        district
        village
      }
    }
  }

  landplots: palmoil_land_plot(where: {affiliate_reward_program_participant: {_is_null: false}}, order_by: {created_at: desc}) {
    created_at
    owner_person_id
    owner {
      location {
        province
        subdistrict
        district
        village
      }  
    }
  }
}`

export const GetMillsDashBoard = gql`
query GetMillsDashBoard {
  palmoil_organisation(where: {organisation_type: {_eq: "mill"}}) {
    id
  }
}
`

export const GetSaleFromContract = gql`
query GetSaleFromContract($id: Int!) {
  palmoil_sale(where: {transport_crew_contract_sales: {transport_crew_contract: {id: {_eq: $id}}}}) {
    transport_crew_contract_sales {
      sale_id
      transport_crew_contract {
        contract_type
      }
    }
  }
}
`