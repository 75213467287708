import { toIndoFormat } from "../../helper/helper"
import { ContractPhoto, buildContractPhoto } from "./ContractPhoto"
import { LineItem, buildLineItem } from "./LineItem"
import { Payment, buildPaymentForContractDetail } from "./Payment"

const SKU_PALM_FRUIT_NIAGA_PRIMARY_BUYER_CONTRACT = "PALM_0003"
const SKU_PALM_FRUIT_NIAGA_PRIMARY_TAXABLE_BUYER_CONTRACT = "PALM_0005"


export class SaleStatement {
    id: number
    state?: string
    status?: string
    payments?: Payment[]
    total?: number
    pre_tax_total?: number
    tax_total?: number
    line_items: LineItem[]
    photos?: ContractPhoto[]

    getBuyerLineItemBySku(): LineItem[] | null {
        const lineItem = this.line_items.filter(li => li.sku === SKU_PALM_FRUIT_NIAGA_PRIMARY_BUYER_CONTRACT || li.sku === SKU_PALM_FRUIT_NIAGA_PRIMARY_TAXABLE_BUYER_CONTRACT)
        if (lineItem.length > 0) {
            return lineItem
        }
        return null
    }

    getTotal(): number {
        return this.total
    }

    getPreTaxTotal(): number {
        return this.pre_tax_total
    }

    getTaxTotal(): number {
        return this.tax_total
    }

    getQuantity(): number {
        const lineItem = this.getBuyerLineItemBySku()
        if (lineItem) {
            return lineItem.reduce(((value, item) => value + item.quantity), 0)
        }
        return null
    }

    getPricePerUnit(): string {
        const lineItem = this.getBuyerLineItemBySku()
        if (lineItem) {
            return toIndoFormat(this.getTotal()/this.getQuantity())
        }
        return null
    }

    constructor(id: number, state?: string, status?: string, total?: number, lineItems?: LineItem[], payments?: Payment[], photos?: ContractPhoto[], pre_tax_total?: number, tax_total?: number) {
        this.id = id
        this.state = state
        this.status = status
        this.total = total
        this.payments = payments
        this.line_items = lineItems
        this.photos = photos
        this.pre_tax_total = pre_tax_total
        this.tax_total = tax_total
    }
}

export function buildSaleStatement(ss: any): SaleStatement {
    return new SaleStatement(
        ss.id,
        ss.state,
        ss.status,
        ss.total,
        ss.line_items?.map((item: any) => buildLineItem(item)),
        ss.payments?.map((p) => buildPaymentForContractDetail(p)),
        ss.photos?.map(p => buildContractPhoto(p.photo)),
        ss.pre_tax_total,
        ss.tax_total
    )
}