import { Organisation, buildOrganisationFromDetail } from "../organisation/Organisation";
import { Transactor, buildTransactorFromDetail } from "./Transactor";

export class TransactorOrganisation {
    transactor: Transactor
    organisation: Organisation
    control_org?: boolean = false

    constructor(transactor: Transactor, organisation: Organisation, control_org?: boolean) {
        this.transactor = transactor
        this.organisation = organisation
        this.control_org = control_org
    }
}

export function buildTransactorOrganisationDetail(to: any): TransactorOrganisation {
    return new TransactorOrganisation(
        to?.transactor ? buildTransactorFromDetail(to.transactor) : undefined,
        to.organisation ? buildOrganisationFromDetail(to.organisation) : undefined,
        to.control_org)
}