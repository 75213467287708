import { Landplot } from "../organisation/Landplot"
import { Person } from "../person/Person"
import { Contract } from "./Contract"
import { Harvest } from "./Harvest"

export class ContractProcessedValues {

    fruitQuantity: number
    ulFruitQuantity: number
    nonUlFruitQuantity: number

    farmerIds: number[]
    ulFarmerIds: number[]
    nonUlFarmerIds: number[]

    farmersDeclared: Person[]
    farmersUndeclared: Person[]

    harvests: Harvest[]
    ulHarvest: Harvest[]
    nonUlHarvest: Harvest[]

    landplots: Landplot[]
    ulLandplots: Landplot[]

    totalNetRp: number

    fcrop: number
    ppunch: number

    constructor(contract: Contract) {
        this.fruitQuantity = this.getFruitQuantity(contract);
        this.ulFruitQuantity = this.getUnileverFruitQuantity(contract);
        this.nonUlFruitQuantity = this.getNonUnileverFruitQuantity(contract);

        this.farmerIds = this.getFarmerPersonIds(contract);
        this.ulFarmerIds = this.getUnileverFarmerPersonIds(contract);
        this.nonUlFarmerIds = this.getNonUnileverFarmerPersonIds(contract);

        this.farmersDeclared = this.getFarmersDeclared(contract);
        this.farmersUndeclared = this.getFarmersUndeclared(contract);

        this.harvests = this.getHarvests(contract);
        this.ulHarvest = this.getUnileverHarvest(contract);
        this.nonUlHarvest = this.getNonUnileverHarvest(contract);

        this.landplots = this.getLandPlots(contract)
        this.ulLandplots = this.getUnileverLandPlots(contract)

        this.totalNetRp = this.getTotalNetRp(contract);

        this.fcrop = this.getFcrop(contract);

        this.ppunch = this.getPpunch(contract);
    }

    getFruitQuantity(contract: Contract): number {
        if (contract.isNamContract()) {
            return contract.quantityGross * (1 - contract.grading)
        } else {
            const res = contract.harvests.reduce((v, ch) => {
                return v + (ch.harvest.quantityFarmerAfterGrading ?? 0)
            }, 0)
            return res * (1 - contract.grading)
        }
    }

    getUnileverFruitQuantity(contract: Contract): number {
        const res = contract.harvests.filter(h => h.harvest.isUnileverLandPlot()).reduce((v, ch) => {
            return v + (ch.harvest.quantityFarmerAfterGrading ?? 0)
        }, 0)
        return res * (1 - contract.grading)
    }

    getNonUnileverFruitQuantity(contract: Contract): number {
        if (contract.isNamContract()) {
            return (contract.quantityGross * (1 - contract.grading)) - this.getUnileverFruitQuantity(contract)
        } else {
            const res = contract.harvests.filter(h => !h.harvest.isUnileverLandPlot()).reduce((v, ch) => {
                return v + (ch.harvest.quantityFarmerAfterGrading ?? 0)
            }, 0)
            return res * (1 - contract.grading)
        }
    }

    getUnileverFarmerPersonIds(contract: Contract): number[] {
        return contract.harvests.filter(h => {
            return h.harvest.isUnileverLandPlot() && h.harvest.farmer.transactor?.person
        }).map(h => h.harvest.farmer.transactor.person.id)
    }

    getNonUnileverFarmerPersonIds(contract: Contract): number[] {
        return contract.harvests.filter(h => !h.harvest.isUnileverLandPlot() && h.harvest.farmer.transactor?.person).map(h => h.harvest.farmer.transactor.person.id)
    }

    getFarmerPersonIds(contract: Contract): number[] {
        return contract.harvests.map(h => h.harvest.farmer.transactor.person.id)
    }

    getFarmersDeclared(contract: Contract): Person[] {
        if (contract.isNamContract()) {
            return contract.harvests.filter(h => h.harvest.isDeclared).map(h => h.harvest.farmer.transactor.person)
        }
        return contract.harvests.map(h => h.harvest.farmer.transactor.person)
    }

    getFarmersUndeclared(contract: Contract): Person[] {
        if (contract.isNamContract()) {
            return contract.harvests.filter(h => !h.harvest.isDeclared).map(h => h.harvest.farmer.transactor.person)
        }
        return []
    }

    getHarvests(contract: Contract): Harvest[] {
        return contract.harvests.map(ch => ch.harvest)
    }

    getUnileverHarvest(contract: Contract): Harvest[] {
        return contract.harvests.filter(h => h.harvest.isUnileverLandPlot()).map(ch => ch.harvest)
    }

    getNonUnileverHarvest(contract: Contract): Harvest[] {
        return contract.harvests.filter(h => !h.harvest.isUnileverLandPlot()).map(ch => ch.harvest)
    }

    getLandPlots(contract: Contract): Landplot[] {
        return contract.harvests.map(h => h.harvest.land_plot)
    }

    getUnileverLandPlots(contract: Contract): Landplot[] {
        return contract.harvests.filter(h => h.harvest.isUnileverLandPlot()).map(h => h.harvest.land_plot)
    }

    getTotalNetRp(contract: Contract): number {
        if (contract.isNamContract()) {
            const harvest = contract.harvests.filter(h => h.harvest.priceBuyer !== undefined)
            if (harvest.length > 0) {
                const price = harvest.at(0).harvest.priceBuyer
                return (contract.quantityGross * price) * (1 - contract.grading)
            }
            return 0
        } else {
            const t = contract.harvests.filter(h => h.harvest.priceBuyer !== undefined)
                .map(h => h.harvest.priceBuyer * h.harvest.quantityFarmerAfterGrading).reduce((value, item) => value + item, 0) ?? 0
            return t * (1 - contract.grading)
        }
    }

    getFcrop(contract: Contract): number {
        return contract.harvests.map(h => h.harvest.fcropTotal ? h.harvest.fcropTotal : 0).reduce((value, item) => value + item, 0) ?? 0
    }

    getPpunch(contract: Contract): number {
        return contract.harvests.map(h => h.harvest.ppunchTotal ? h.harvest.ppunchTotal : 0).reduce((value, item) => value + item, 0) ?? 0
    }
}