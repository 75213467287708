import React, { useEffect, useRef, useState } from "react"
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import AddEmployee from "./AddEmployee";
import { UpdateOrganisation, getInvitedUserQuery, getTransactorOrganisationDetail, reinviteMutation, removeInvitationMutation, removePersonFromCompany } from "../../api";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router";
import MapBox from "../../components/MapBox";
import { addDragForCompanyDetailPage, buildDataForCompanyDetailPage, getMyCompaniyDetailSource, removeCompanyDetailSource, removeDragForCompanyDetailPage } from "../../models/MapBoxDataModel";
import { MapEvent } from "../../models/MapEvent";
import { defaultLat, defaultLng, formatAddress, getUserPosition, showErrorMessage } from "../../helper/helper";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Organisation } from "../../models/organisation/Organisation";
import { Transactor } from "../../models/person/Transactor";
import { TransactorOrganisation, buildTransactorOrganisationDetail } from "../../models/person/TransactorOrganisation";
import { transactorId, userId } from "../../helper/TokenHelper";
import { Person } from "../../models/person/Person";
import { Employee, buildEmployeeFromInvitedUser } from "../../models/organisation/Employee";
import { Observable, zip } from "rxjs";
import { ReactComponent as Expand } from "../../rsc/ic_expand.svg";
import { ReactComponent as Reduce } from "../../rsc/ic_reduce.svg";
import { ReactComponent as EditIcon } from "../../rsc/ic_edit.svg"
import { ReactComponent as LocationIcon } from "../../rsc/ic_location.svg"
import { ReactComponent as PhoneIcon } from "../../rsc/ic_phone.svg"
import { ReactComponent as TaxIcon } from "../../rsc/ic_tax.svg"
import { colorPrimaryDark } from "../../rsc/colors/colors";


type CompanyDetailProps = {
    client: ApolloClient<NormalizedCacheObject>,
}

// type CompanyDetailState = {
//     organisation: Organisation,
//     showAddUser: boolean,
//     employeeToRemove: Transactor
// }

function CompanyDetail(props: CompanyDetailProps) {

    const mapExpandInfo = {
        isExpand: true,
        mapHeight: "500px"
    }

    const mapReducedInfo = {
        isExpand: false,
        mapHeight: "300px"
    }

    const { id } = useParams()

    const { t } = useTranslation();

    const navigate = useNavigate()

    const [organisation, setOrganisation] = useState<TransactorOrganisation>(null)
    const [employees, setEmployees] = useState<Employee[]>([])
    const [showAddUser, setShowAddUser] = useState(false)
    const [employeeToRemove, setEmployeeToRemove] = useState<Transactor>(null)
    const [employeeToInvite, setEmployeeToInvite] = useState<Transactor>(null)
    const [coords, setCoords] = useState([defaultLng, defaultLat])
    const [editMode, setEditMode] = useState(false)
    const [mapSizeInfo, setMapSizeInfo] = useState(mapReducedInfo)

    const mapRef = useRef(null)

    useEffect(() => {
        fetchCompany()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCompany = () => {

        const companyCall = new Observable<TransactorOrganisation>((sub) => {
            props.client.query({
                query: getTransactorOrganisationDetail,
                variables: {
                    organisationId: parseInt(id),
                    transactorId: transactorId()
                },
                context: {
                    headers: {
                        "role": "usermill"
                    }
                }
            }).then(result => {
                if (result.data.organisation.length > 0) {
                    let org = buildTransactorOrganisationDetail(result.data.organisation[0])
                    setOrganisation(org)
                    getOrganisationCoords(org.organisation)
                    sub.next(org)
                }
            }).catch(e => {
                showErrorMessage(e.message)
            })
        })

        const invitedUserCall = new Observable<Employee[]>((sub) => {
            props.client.query({
                query: getInvitedUserQuery,
                variables: {
                    orgId: parseInt(id)
                }
            }).then(result => {
                if (result.data.users.length > 0) {
                    let employees = result.data.users.map((e) => buildEmployeeFromInvitedUser(e))
                    sub.next(employees)
                } else {
                    sub.next([])
                }
            })
        })

        const observer = {
            next: (res) => {
                let employeesRes: Employee[] = (res[0] as TransactorOrganisation).organisation.employees
                employeesRes = [...employeesRes, ...res[1]]
                setEmployees(employeesRes)
            },
            error: (error) => {
                console.log(error.message)
            },
            complete: () => {
            }
        }

        zip(companyCall, invitedUserCall)
            .subscribe(observer)
    }

    function getRole() {
        return organisation.organisation.isMill() ? "usermill" : "user"
    }

    const EmployeeHeader = () => {
        return <tr>
            <td width={"33%"}>{t('name')}</td>
            <td width={"33%"}>{t('location')}</td>
            <td width={"33%"}></td>
        </tr>
    }

    const EmployeeAvatar = (firstname: string, lastname: string, uri?: string) => {
        if (uri != null) {
            return <div style={{ display: "inline-block", verticalAlign: "middle" }}><img alt="avatar" style={{ width: "40px", borderRadius: "50%", height: "40px" }} src={uri}>
            </img></div>
        } else {
            return <div className='primaryDarkBgColor' style={{
                display: "inline-block", verticalAlign: "middle",
                textAlign: "center", width: "40px", borderRadius: "50%", height: "40px"
            }}>
                <div style={{ transform: "translateY(-50%)", color: "white", position: "relative", top: "50%", }}>{firstname.charAt(0)}{lastname.charAt(0)}</div></div>
        }
    }


    function reinviteUser(person: Person) {
        const orgId = parseInt(id)
        props.client.mutate({
            mutation: reinviteMutation,
            variables: {
                email: person.user.email,
                orgId: orgId,
                userInfo: {
                    email: person.user.email,
                    is_pempem_user: false,
                    inviter_organisation_id: orgId,
                    inviter_user_id: userId(),
                    firstname: person.firstname,
                    lastname: person.lastname
                }
            },
            context: {
                headers: {
                    "role": getRole()
                }
            }
        }).then(result => {
            setEmployeeToInvite(null)
            fetchCompany()
        })
    }

    const removePerson = (employee: Transactor, orgId: number) => {
        if (employee.id === -1) {
            props.client.mutate({
                mutation: removeInvitationMutation,
                variables: {
                    email: employee.person.user.email,
                    orgId: orgId,
                },
                context: {
                    headers: {
                        "role": getRole()
                    }
                }
            }).then(result => {
                setEmployeeToRemove(null)
                fetchCompany()
            })
        } else {
            props.client.mutate({
                mutation: removePersonFromCompany,
                variables: {
                    transactorId: employee.id,
                    organisationId: orgId,
                }
            }).then(result => {
                if (employee.id === transactorId()) {
                    if (organisation.organisation.isMill()) {
                        navigate('/explorer/companies')
                    } else {
                        navigate('/explorer/admin')
                    }
                } else {
                    setEmployeeToRemove(null)
                    fetchCompany()    
                }
            })
        }
    }

    const EmployeeRow = (employee: Transactor) => {
        var person: Person = employee.person
        if (person.id === -1) {
            const firstname = person.firstname ? person.firstname : person.user.email[0]
            const lastname = person.lastname ? person.lastname : person.firstname ? "" : person.user.email[1]
            return (
                <tr key={employee.person.user.email} className='yellowLightBgColor'>
                    <td>{EmployeeAvatar(firstname, lastname, person.user?.avatar_url)}<span style={{ marginLeft: "10px" }}>{person.firstname ? `${firstname} ${lastname}` : person.user.email}</span></td>
                    <td style={{ color: "red" }}>{t('invited_user')}</td>
                    <td style={{ textAlign: "center" }}>
                        {
                            organisation?.control_org === true ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "1", display: "flex", justifyContent: "end" }}>
                                        <button className="button-no-background primaryColor" onClick={() => { setEmployeeToInvite(employee) }}>{t('invite_user')}</button>
                                    </div>
                                    <div style={{ flex: "1", display: "flex", justifyContent: "start", marginLeft: "10px" }}>
                                        <button className="button-no-background" onClick={() => { displayRemovePersonDialog(employee) }} style={{ color: "red" }}>{t('remove')}</button>
                                    </div>
                                </div>
                                : <></>
                        }
                    </td>
                </tr>
            )
        } else {
            return <tr key={employee.id}>
                <td>{EmployeeAvatar(person.firstname, person.lastname, person?.user.avatar_url)}<span style={{ marginLeft: "10px" }}>{person.firstname} {person.lastname}</span></td>
                <td>{person.location?.village}</td>
                <td style={{ textAlign: "center" }}>
                    {
                        organisation?.control_org === true ?
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: "1", display: "flex", justifyContent: "end" }}>
                                </div>
                                <div style={{ flex: "1", display: "flex", justifyContent: "start", marginLeft: "10px" }}>
                                    <button className="button-no-background" onClick={() => { displayRemovePersonDialog(employee) }} style={{ color: "red" }}>{t('remove')}</button>
                                </div>
                            </div>
                            : <></>
                    }
                </td>
            </tr>
        }
    }

    const displayRemovePersonDialog = (employee: Transactor) => {
        setEmployeeToRemove(employee)
    }

    const companyName = () => {
        if (organisation?.organisation) {
            return organisation.organisation.name
        }
        return "Company Detail"
    }


    const getOrganisationCoords = (org: Organisation) => {
        if (org?.positionepsg4326?.coordinates) {
            setCoords(org.positionepsg4326.coordinates)
        } else {
            getUserPosition().then((coords) => {
                setCoords(coords)
            }, error => {
                setCoords([defaultLng, defaultLat])
            })
        }
    }

    let dragEvent = useRef<MapEvent>(null)

    const onEditCompanyPosition = (map) => {
        if (organisation?.organisation.positionepsg4326?.coordinates) {
            dragEvent.current = new MapEvent(map, getMyCompaniyDetailSource(organisation.organisation.positionepsg4326?.coordinates, organisation.organisation.name), 'organisation', organisation.organisation.positionepsg4326.coordinates)
            addDragForCompanyDetailPage(map, dragEvent.current)
        } else {
            buildDataForCompanyDetailPage(map, organisation.organisation, [coords[0], coords[1]])
                .then((value) => {
                    if (value) {
                        dragEvent.current = new MapEvent(map, getMyCompaniyDetailSource([coords[0], coords[1]], organisation.organisation.name), 'organisation', null)
                        addDragForCompanyDetailPage(map, dragEvent.current)
                    }
                })
        }
    }

    const onCancelEditPosition = () => {
        setEditMode(false)
        if (!organisation?.organisation.positionepsg4326?.coordinates) {
            removeCompanyDetailSource(mapRef.current)
        }
        dragEvent.current?.onCancel()
        removeDragForCompanyDetailPage(mapRef.current, dragEvent.current)
    }

    const onSavePostion = () => {
        if (organisation.organisation.positionepsg4326) {
            organisation.organisation.positionepsg4326.coordinates = dragEvent.current?.coords
        } else {
            organisation.organisation.positionepsg4326 = {
                type: "Point",
                coordinates: dragEvent.current?.coords
            }
        }
        let newPosition = organisation.organisation.positionepsg4326
        props.client.mutate({
            mutation: UpdateOrganisation,
            variables: {
                object: {
                    positionepsg4326: newPosition
                },
                organisationId: organisation.organisation.id,
            }
        }).then(result => {
        })
        dragEvent.current.initialCoords = dragEvent.current?.coords
        dragEvent.current?.onCancel()
        removeDragForCompanyDetailPage(mapRef.current, dragEvent.current)
        setEditMode(false)
    }

    function changeMapSize() {
        if (mapSizeInfo.isExpand) {
            setMapSizeInfo(mapReducedInfo)
        } else {
            setMapSizeInfo(mapExpandInfo)
        }
        setTimeout(() => {
            mapRef.current.resize()
        }, 100)
    }

    function showCompanyInformations() {
        if (organisation?.organisation.isMill()) {
            return (
                <div className="flex flex-col gap-[10px]">
                    <div className="flex pl-[28px] pr-[20px]">
                        <div className="flex flex-col flex-1 gap-[10px]">
                            <div className="flex items-center">
                                <LocationIcon style={{ width: "25px", height: "auto", margin: "0 10px 0 0" }} />
                                <p >{`${formatAddress(organisation.organisation) ?? '-'}`}</p>
                            </div>
                            <div className="flex items-center">
                                <PhoneIcon style={{ width: "25px", height: "auto", margin: "0 10px 0 0" }} />
                                <p>{`${organisation.organisation.taxInformations.phoneNumber ?? '-'}`}</p>
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col items-end gap-[10px]">
                            <div className="flex items-center">
                                <TaxIcon style={{ width: "35px", height: "auto", margin: "0 10px 0 0" }} />
                                <p>{`${organisation.organisation.taxInformations.taxNumber ?? '-'}`}</p>
                            </div>
                        </div>
                    </div>
                </div>)
        } else {
            return <></>
        }
    }

    return (
        <div style={{ width: "100%", paddingBottom: "20px" }}>
            <div className="flex place-content-between pr-[20px] items-center">
                <h1 className="mb-[20px]">{companyName()}</h1>
                {organisation?.organisation.isMill() ?
                    <Button onClick={() => navigate("/explorer/company_profil/" + organisation.organisation.id)} style={{ border: "solid black 2px", height: "40px" }} className="border-[2px] border-[black] rounded-[3px]">
                        <EditIcon className="w-[20px] h-[20px] mr-[7px]" />
                        <p style={{ color: "black" }}>{t('edit')}</p>
                    </Button>
                    : <></>}
            </div>
            {showCompanyInformations()}
            {organisation?.organisation.isMill() ?
                <div style={{ position: "relative", padding: "20px 20px 0px 28px", height: mapSizeInfo.mapHeight }}>
                    <MapBox
                        width="100%"
                        lng={coords[0]}
                        lat={coords[1]}
                        stateToReload={coords}
                        height="100%"
                        onLoaded={(map) => { mapRef.current = map; buildDataForCompanyDetailPage(map, organisation.organisation) }}
                    />
                    <div>
                        {
                            editMode ?
                                <div style={{ position: "absolute", width: "330px", right: "35px", top: "35px", background: "#fff", padding: "15px", borderRadius: "5px" }}>
                                    <p style={{ color: colorPrimaryDark, fontWeight: "700", margin: "10px 0" }}>{t('edit_mill_position')}</p>
                                    <p style={{ color: colorPrimaryDark, fontWeight: "300", margin: "10px 0" }}>{t('edit_mill_position_instructions')}</p>
                                    <div style={{ textAlign: "end" }}>
                                        <Button style={{ color: colorPrimaryDark, fontWeight: "500", marginRight: "10px" }} onClick={onCancelEditPosition}>{t('cancel')}</Button>
                                        <Button style={{ background: colorPrimaryDark }} variant="contained" onClick={onSavePostion}>{t('save')}</Button>
                                    </div>
                                </div>
                                : <Button onClick={() => { setEditMode(true); onEditCompanyPosition(mapRef.current) }}
                                    style={{ border: "solid black 2px", position: "absolute", background: "white" }}
                                    className="border-[2px] border-[black] rounded-[3px] right-[35px] top-[35px]">
                                    <EditIcon className="w-[20px] h-[20px] mr-[7px]" />
                                    <p style={{ color: "black" }}>{t('edit')}</p>
                                </Button>

                        }
                        <div onClick={changeMapSize} style={{
                            cursor: "pointer",
                            width: "40px",
                            height: "40px",
                            position: "absolute",
                            left: "40px",
                            borderRadius: "5px",
                            top: "30px",
                            background: colorPrimaryDark,
                            display: "inline-block",
                            padding: "10px"
                        }}>
                            {
                                mapSizeInfo.isExpand ? <Reduce style={{ width: "100%", height: "100%" }} />
                                    : <Expand style={{ width: "100%", height: "100%" }} />
                            }
                        </div>
                    </div>
                </div>
                : <></>}
            <div style={{ position: "relative", height: "50px", marginTop: "20px" }}>
                <span className="greyColor" style={{ fontSize: "20px", marginLeft: "28px", position: "absolute", bottom: "0" }}>{t('employee')}</span>
                <button style={{ right: "20px", width: "auto", position: "absolute" }} className="pem-button" onClick={() => setShowAddUser(true)}>{t('add_user')}</button>
            </div>
            {
                employees.length > 0 ?
                    <table className="company-detail-table" style={{ borderCollapse: "collapse", width: "calc(100% - 48px)", marginLeft: "28px", marginTop: "10px" }}>
                        <tbody>
                            {EmployeeHeader()}
                            {(() => {
                                if (organisation != null) {
                                    return employees.map((employee) => EmployeeRow(employee.transactor))
                                } else {
                                    return <tr><td></td></tr>
                                }
                            })()
                            }
                        </tbody>
                    </table>
                    : <></>
            }
            {employeeToRemove ?
                <Dialog
                    open={employeeToRemove !== null}
                    onClose={() => setEmployeeToRemove(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('remove_person_company', { name: employeeToRemove.person.fullName() ? employeeToRemove.person.fullName() : employeeToRemove.person?.user.email })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => removePerson(employeeToRemove, parseInt(id))}>{t('yes')}</Button>
                        <Button onClick={() => setEmployeeToRemove(null)} autoFocus>
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>
                : <></>
            }
            {
                employeeToInvite ?
                    <Dialog
                        open={employeeToInvite !== null}
                        onClose={() => setEmployeeToInvite(null)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`Are you sure you want to invite ${employeeToInvite.person.fullName()} to join your company ?`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => reinviteUser(employeeToInvite.person)}>{t('yes')}</Button>
                            <Button onClick={() => setEmployeeToInvite(null)} autoFocus>
                                {t('no')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : <></>
            }
            {
                showAddUser ?
                    <AddEmployee isOpen={showAddUser} organisation={organisation.organisation} client={props.client} onClose={() => {
                        fetchCompany()
                        setShowAddUser(false)
                    }} />
                    : <div></div>
            }
        </div >
    )
}

export default CompanyDetail