import { NFMStore } from "../stores/store"
import { Buffer } from "buffer";

export const TRANSACTOR_ID = "transactor_id"
export const TOKEN = "token"
export const PATH = "path"

export function transactorId() {
  var token = NFMStore.getState().token.value
  token = token ? token : getLocalStorageToken()
  var tokenInfos = token.split(".")

  if (tokenInfos.length < 3) {
    return null
  }
  var info = Buffer.from(tokenInfos[1], 'base64')
  var transactor_id = JSON.parse(info.toString())["https://hasura.io/jwt/claims"]["x-hasura-transactor-id"]
  return parseInt(transactor_id)
}

export function asCorrectRole(token: string): boolean {
  var tokenInfos = token.split(".")

  if (tokenInfos.length < 3) {
    return null
  }
  var info = Buffer.from(tokenInfos[1], 'base64')

  const claims = JSON.parse(info.toString())["https://hasura.io/jwt/claims"]
  if (claims) {
    return true
  }
  return false  
}

export function getUserRoles(): string[] {
  var token = NFMStore.getState().token.value
  var tokenInfos = token.split(".")

  if (tokenInfos.length < 3) {
    return null
  }
  var info = Buffer.from(tokenInfos[1], 'base64')

  const roles = JSON.parse(info.toString())["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"]
  if (roles) {
    return roles
  }
  return []  
}

export function getToken() {
  //console.log("link")
  var token = NFMStore.getState().token.value
  if (token) {
    var tokenInfos = token.split(".")

    var info = Buffer.from(tokenInfos[1], 'base64')

    return {decode: JSON.parse(info.toString()), token: token}
  } else {
    return null
  }
}

export function getJsonToken() {
  var token = NFMStore.getState().token.value
  var tokenInfos = token.split(".")

  if (tokenInfos.length < 3) {
    return null
  }
  var info = Buffer.from(tokenInfos[1], 'base64')

  return JSON.parse(info.toString())["https://hasura.io/jwt/claims"]
}

export function userId() {
  var token = NFMStore.getState().token.value
  token = token ? token : getLocalStorageToken()
  var tokenInfos = token.split(".")

  if (tokenInfos.length < 3) {
    return null
  }
  var info = Buffer.from(tokenInfos[1], 'base64')

  return JSON.parse(info.toString())["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
}

export function saveUserInfo(token) {
  window.localStorage.setItem(TOKEN, token)
}

export function saveCurrentPath(path: string) {
  window.localStorage.setItem(PATH, path)
}

export function getCurrentPath(): string {
  return window.localStorage.getItem(PATH)
}

export function personId() {
  var token = NFMStore.getState().token.value
  var tokenInfos = token.split(".")

  var info = Buffer.from(tokenInfos[1], 'base64')

  return JSON.parse(info.toString())["https://hasura.io/jwt/claims"]["x-hasura-person-id"]
}


export function getLocalStorageToken() {
  return window.localStorage.getItem(TOKEN)
}
