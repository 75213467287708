import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogContent, FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import axios from "axios"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { Loader } from "../../components/Loader"
import Logo from "../../components/LogoPemPem"
import { checkPassword, showErrorMessage } from "../../helper/helper"
import { colorPrimary } from "../../rsc/colors/colors"
import { ReactComponent as Check } from "../../rsc/ic_round_check.svg"
import { NFMStore, setIsLoggedIn } from "../../stores/store"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export function CreateAccount() {
    const [param] = useSearchParams()

    const orgName = param.get("orgName")
    const inviterName = param.get("inviterName")
    const inviteeName = param.get("inviteeName")
    const oob = param.get("oob")
    const orgType = param.get("orgType")

    const { t } = useTranslation()

    const [showPwd, setShowPwd] = useState<Boolean>(false)
    const [showConfirmPwd, setShowConfirmPwd] = useState<Boolean>(false)
    const [pwd, setPwd] = useState<string>("")
    const [confirmPwd, setConfirmPwd] = useState<string>("")
    const [loading, setLoading] = React.useState(false);
    const [isConfirmSucceed, setIsConfirmSucceed] = useState(false)

    function sendPassword(pwd: string) {
        const data = {
            "password": pwd
        }

        axios.post(`${process.env.REACT_APP_BASE_URL_PUBLIC}/auth/api/createUser`, data, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "x-app-id": process.env.REACT_APP_APP_ID,
                "x-oob": oob,
                "x-app-name": "NFM"
            }
        }).then(() => {
            setIsConfirmSucceed(true)
        }).catch(e => {
            if (e?.message) {
                showErrorMessage(e?.message)
            } else {
                showErrorMessage(t('an_error_occured'))
            }
        })
            .finally(() => {
                setLoading(false)
            })
    }

    function submitInfo() {
        if (pwd !== confirmPwd) {
            showErrorMessage(t('password_not_matching'))
            return
        }
        if (!checkPassword(pwd)) {
            showErrorMessage(t('password_pattern'))
            return
        }
        setLoading(true)
        sendPassword(pwd)
    }

    function isConglomerate(): boolean {
        return orgType === "conglomerate"
    }

    return (
        <div style={{ height: "70%", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <div style={{ height: "200px", width: "700px", display: "inline-block", marginTop: "30px" }}>
                <Logo />
            </div>
            <div onKeyUp={(e) => { if (e.code === "Enter") { submitInfo() } }} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <p style={{ fontSize: "20px", lineHeight: "1.3em" }}>{isConglomerate() ? t('log_in_for_unilever', { invitee: inviteeName }) : t('log_in_for_mill', { invitee: inviteeName, inviter: inviterName, orgName: orgName })}</p>
                    <p style={{ fontSize: "20px", lineHeight: "1.3em", margin: "0px" }}>{isConglomerate() ? t('create_unilever_account_message') : t('create_account_message')}</p>
                    <div style={{ width: "320px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <FormControl sx={{ marginTop: "30px", width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t("enter_password")}</InputLabel>
                            <OutlinedInput
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                id="outlined-adornment-password"
                                type={showPwd ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div
                                            onClick={() => setShowPwd(!showPwd)} className="cursor-pointer">
                                            {showPwd ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    </InputAdornment>
                                }
                                label={t("enter_password")}
                            />
                        </FormControl>
                        <FormControl sx={{ marginTop: "30px", width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t("confirm_password")}</InputLabel>
                            <OutlinedInput
                                onChange={(e) => setConfirmPwd(e.target.value)}
                                value={confirmPwd}
                                id="outlined-adornment-password"
                                type={showConfirmPwd ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div
                                            onClick={() => setShowConfirmPwd(!showConfirmPwd)} className="cursor-pointer">
                                            {showConfirmPwd ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    </InputAdornment>
                                }
                                label={t("enter_password")}
                            />
                        </FormControl>
                        <LoadingButton
                            disabled={!checkPassword(pwd)}
                            style={{ marginTop: "20px", height: "48px" }}
                            variant="contained"
                            loading={loading}
                            onClick={() => {
                                submitInfo()
                            }}>
                            {t('submit')}
                        </LoadingButton>
                    </div>
                </div>
                <Dialog
                    disableEscapeKeyDown
                    open={isConfirmSucceed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                <Check fill={colorPrimary} style={{ height: "40px", width: "40px" }} />
                                <p style={{ fontSize: "20px", lineHeight: "30px" }}>{isConglomerate() ? t('join_unilever_success') : t('confirm_account_success')}</p>
                            </div>
                            <Button variant="contained" style={{ background: colorPrimary }} onClick={() => {
                                NFMStore.dispatch(setIsLoggedIn({ LoggedIn: false, forceRedirectLogin: true }))
                            }}>{t('go_to_login')}</Button>
                        </div>

                    </DialogContent>
                </Dialog>

            </div>
            <Loader isLoading={loading} />
        </div>
    )
}