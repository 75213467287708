import { User } from "firebase/auth";
import React, { useEffect, useState } from 'react'
import Login from "./Login"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useTranslation } from "react-i18next";
import { ReactComponent as Underline } from "../../rsc/underline_title.svg"
import { colorPrimaryDark, colorYellowMedium } from "../../rsc/colors/colors";


type AuthState = {
    status: string,
    user?: User
}

export function isValidToken(user: User): Promise<boolean> {
    return new Promise(async (resolve) => {
        const idTokenResult = await user.getIdTokenResult()
        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims']
        if (hasuraClaim) {
            resolve(true)
        }
        resolve(false)
    })
}


export default function Auth({ client }) {


    const [authState, setAuthState] = useState<AuthState>({ status: 'out' })

    const [fontSize, setFontSize] = useState({paddingTop:10 });

    const { t } = useTranslation()

    let content
    if (authState.status === 'loading') {
        content = null
    } else {
        content = (
            <div className="flex flex-col flex-[45_1_0%]">
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <Login setAuthState={setAuthState} />
                </GoogleOAuthProvider>
            </div>
        )
    }

    const updateFontSize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Ensure the font size doesn't go below the minimum

        const ratio = 1
        const currentRatio = windowHeight/windowWidth
        const pt = 10 * (currentRatio/ratio)

        setFontSize({paddingTop: pt})
    };

    useEffect(() => {
        // Call the updateFontSize function on page load
        updateFontSize();

        // Add event listener for window resize
        window.addEventListener('resize', updateFontSize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateFontSize);
        };
    }, []);

    function getTitle() {
        const sentence = t('pempem_header_title');

        const words = sentence.split(' ');

        const lastWord = words[words.length - 1];

        return (
            <div style={{ background: `linear-gradient(0deg, rgba(0, 152, 166, 0.00) 17.03%, ${colorPrimaryDark} 110.03%)` }} className="w-[100%] h-[100%]">
                <div style={{paddingTop:`${fontSize.paddingTop}%`}} className="flex flex-col items-center">
                    <div className="inline-block text-end">
                        <div className=" text-center">
                            <p style={{
                                whiteSpace: "nowrap"
                            }} className="text-[white] font-[600] leading-[43px] text-[50px]">
                                {words.slice(0, -1).join(' ')}{' '}
                                <span className="yellowMediumColor">{lastWord}</span>
                            </p>
                        </div>
                        <Underline fill={colorYellowMedium} style={{ width: "250px" }} />
                    </div>
                    <p className="mt-[20px] text-[white] font-[600] text-[25px] leading-[25px] text-center">{t('login_subtitle_message')}</p>
                </div>
            </div>
        );
    }

    return <div className="flex h-[100%]">
        {content}
        <div style={{ backgroundImage: "url(login_side_img.png)", backgroundSize: "cover", backgroundPosition: "center center" }}
            className="flex-[55_1_0%] max-md:hidden">
            {getTitle()}
        </div>
    </div>
}