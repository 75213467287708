import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { Button, Dialog, DialogContent, Button as MuiButton, TextField } from "@mui/material"
import { useGoogleLogin } from "@react-oauth/google"
import axios from "axios"
import { GoogleAuthProvider, User, signInWithEmailAndPassword } from "firebase/auth"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { updateInvitationMutation } from "../../api"
import { Loader } from "../../components/Loader"
import Logo from "../../components/LogoPemPem"
import { checkEmail, checkPassword, showErrorMessage } from "../../helper/helper"
import { colorPrimary, colorRed } from "../../rsc/colors/colors"
import { ReactComponent as GoogleLogo } from "../../rsc/google_logo.svg"
import { ReactComponent as Check } from "../../rsc/ic_round_check.svg"
import { NFMStore, setIsLoggedIn } from "../../stores/store"
import { signOut } from "../RoutesPempem"
import { isValidToken } from "../login/Auth"
import { firebaseAuth } from "../login/firebaseInit"

type MessageType = {
    message: any,
    color: string
}

export function ConfirmAccount(props) {

    const [param] = useSearchParams()

    const orgName = param.get("orgName")
    const inviterName = param.get("inviterName")
    const inviteeName = param.get("inviteeName")
    const orgId = param.get("orgId")
    const oob = param.get("oob")
    const orgType = param.get("orgType")


    const [message, setMessage] = useState<MessageType>(null)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmSucceed, setIsConfirmSucceed] = useState(false)

    const { t } = useTranslation()

    const onLoginClick = () => {
        signInWithEmail(email, password, (user) => {
            user.getIdToken(true).then(token => confirmaMailAddress(user.uid, email, token)).catch(e => showErrorMessage(e.message))
        }, (error) => {
            updateMessage({ message: error, color: colorRed })
        })
    }

    const updateMessage = (message: MessageType) => {
        setMessage(message)
        setIsLoading(false)
    }

    const signInWithEmail = (email: string, password: string, success: (user: User) => void, failure: (error: any) => void) => {
        const isEmailValid = checkEmail(email) !== null
        const isPwdValid = checkPassword(password)
        if (!isEmailValid) {
            updateMessage({ message: t('email_pattern'), color: colorRed })
        } else if (!isPwdValid) {
            updateMessage({ message: t('password_pattern'), color: colorRed })
        } else {
            setIsLoading(true)
            signInWithEmailAndPassword(firebaseAuth(), email, password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    isValidToken(user).then((isValid) => {
                        if (isValid) {
                            signOut().then(() => {
                                props.onVerificationMailEnd()
                            })
                            success(user)
                        } else {
                            updateMessage({ message: t('user_unauthorised'), color: colorRed })
                        }
                    })
                }, (reason) => {
                    failure(reason)
                })
                .catch(async (error) => {
                    updateMessage({ message: error, color: colorRed })
                });
        }
    }

    var userGoogleAccessToken = null

    function isConglomerate(): boolean {
        return orgType === "conglomerate"
    }


    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            userGoogleAccessToken = tokenResponse.access_token
            // fetching userinfo can be done on the client or the server
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);

            signInWithGoogle(userInfo)
        },
    })

    const signInWithGoogle = async (userInfo) => {
        setIsLoading(true)
        firebaseAuth().fetchSignInMethodsForEmail(userInfo.email)
            .then((providers) => {
                if (providers.length > 0) {
                    firebaseAuth().signInWithCredential(GoogleAuthProvider.credential(null, userGoogleAccessToken))
                        .then((userCredential) => {
                            userCredential.user.getIdToken(true).then(token => {
                                confirmaMailAddress(userCredential.user.uid, userInfo.email, token)
                            }).catch(e => showErrorMessage(e.message))
                        })
                } else {
                    updateMessage({ message: t('user_unauthorised'), color: colorRed })
                }
            }, (reason) => {
                updateMessage({ message: reason, color: colorRed })
            }).catch(async (error) => {
                updateMessage({ message: error, color: colorRed })
            });
    }

    const confirmaMailAddress = (uid: string, email: string, token: string) => {
        const client = (props.client as ApolloClient<NormalizedCacheObject>)
        client.mutate({
            mutation: updateInvitationMutation,
            variables: {
                email: email,
                orgId: parseInt(orgId),
                oob: oob,
                object: {
                    user_id: uid
                }
            },
            context: {
                headers: {
                    token: token
                }
            }
        }).then((data) => {
            if (data.data.user.returning.length > 0) {
                setIsConfirmSucceed(true)
            } else {
                updateMessage({ message: t('user_unauthorised'), color: colorRed })
            }
        }).catch(e => showErrorMessage(e.message))
            .finally(() => {
                setIsLoading(false)
                signOut().then(() => {
                    props.onVerificationMailEnd()
                })
            })
    }

    return (
        <div style={{ height: "70%", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <div style={{ height: "200px", width: "700px", display: "inline-block", marginTop: "30px" }}>
                <Logo />
            </div>
            <div className="loginDiv" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "40px" }}>
                <div onKeyUp={(e) => { if (e.code === "Enter") { onLoginClick() } }}>
                    <p style={{ fontSize: "20px", lineHeight: "1.3em" }}>{isConglomerate() ? t('log_in_for_unilever', { invitee: inviteeName }) : t('log_in_for_mill', { invitee: inviteeName, inviter: inviterName, orgName: orgName })}</p>
                    <p style={{ fontSize: "20px", lineHeight: "1.3em", margin: "0px" }}>{isConglomerate() ? t('confirm_unilever_account_message') : t('confirm_account_message')}</p>
                    <div id="divInput" style={{ width: "320px" }}>
                        <div className="loginDiv">
                            <TextField style={{ marginTop: "30px", width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label={t("enter_email")} variant="outlined" />
                        </div>
                        <div className="loginDiv">
                            <TextField style={{ width: "100%" }} type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label={t("enter_password")} variant="outlined" />
                        </div>
                        {message ?
                            <div className="loginDiv">
                                <p style={{ color: message.color }}>{String(message.message)}</p>
                            </div>
                            : <></>}
                        <div className="loginDiv">
                            <MuiButton style={{ width: "100%", padding: "13px" }} variant="contained" onClick={() => onLoginClick()} id="login">{t("login")}</MuiButton>
                        </div>
                        <div className="loginDiv">
                            <div className="google-button" onClick={() => googleLogin()}>
                                <GoogleLogo style={{ display: "inline-block", marginRight: "10px", width: "32px", height: "32px" }}></GoogleLogo>
                                <p style={{ marginLeft: "10px", margin: "0px", padding: "0px", display: "inline-block" }}>{t('connect')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    disableEscapeKeyDown
                    open={isConfirmSucceed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                <Check fill={colorPrimary} style={{ height: "40px", width: "40px" }} />
                                <p style={{ fontSize: "20px", lineHeight: "30px" }}>{isConglomerate() ? t('join_unilever_success') : t('confirm_account_success')}</p>
                            </div>
                            <Button variant="contained" style={{ background: colorPrimary }} onClick={() => {
                                NFMStore.dispatch(setIsLoggedIn({ LoggedIn: false, forceRedirectLogin: true }))
                            }}>{t('go_to_login')}</Button>
                        </div>

                    </DialogContent>
                </Dialog>
            </div>
            <Loader isLoading={isLoading} />
        </div>
    )
}