import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colorDarkBlue, colorGrey, colorPrimary, colorPrimaryPale } from "../rsc/colors/colors";
import { ReactComponent as LogoCompanies } from "../rsc/ic_companies.svg";
import { ReactComponent as DashboardIcon } from "../rsc/ic_dashboard.svg";
import { ReactComponent as PreviousContractIcon } from "../rsc/ic_previous_contract.svg";
import { ReactComponent as PreviousInvoiceIcon } from "../rsc/ic_previous_invoice.svg";
import { ReactComponent as ReportIcon } from "../rsc/ic_report.svg";
import { ReactComponent as MarketIcon } from "../rsc/market.svg";
import { AdminInfo, MenuState, NFMStore, setMenuState } from "../stores/store";

type MenuProps = {
    isAdminRef: AdminInfo
}

const { version } = require('../../package.json')

export function Menu(props: MenuProps) {

    const menuState: MenuState = useSelector((state: any) => state.menu.value)

    type PageMenuType = {
        type: string,
        pageName: string,
        pagePath?: string,
        icon?: any,
        isVisible?: boolean
    }

    const pages: PageMenuType[] = [
        {
            type: "page",
            pageName: t("dashboard"),
            pagePath: "explorer/dashboard",
            icon: <DashboardIcon />,
            isVisible: props.isAdminRef.dashboardEnable
        },
        {
            type: "page",
            pageName: t("report"),
            pagePath: "explorer/dashboard/report",
            icon: <ReportIcon />,
            isVisible: props.isAdminRef.dashboardEnable
        },
        {
            type: "page",
            pageName: t("my_prices"),
            pagePath: "explorer/my_prices",
            icon: <MarketIcon />,
            isVisible: props.isAdminRef.hasMill
        },
        {
            type: "page",
            pageName: t("companies"),
            pagePath: "explorer/companies",
            icon: <LogoCompanies />,
            isVisible: props.isAdminRef.hasMill
        },
        {
            type: "page",
            pageName: t("contracts"),
            pagePath: "explorer/contracts",
            icon: <PreviousContractIcon />,
            isVisible: props.isAdminRef.hasMill
        },
        {
            type: "page",
            pageName: t("invoices"),
            pagePath: "explorer/pempem_invoices",
            icon: <PreviousInvoiceIcon />,
            isVisible: props.isAdminRef.hasMill
        }
    ]

    const [sectionSelected, setSectionSelected] = useState<PageMenuType>(pages[0])

    const pathStore = useSelector((state: any) => state.path.value)

    var location = useLocation()

    const navigate = useNavigate()

    const onChangeRoute = (path: PageMenuType) => {
        setSectionSelected(path)
        navigate(path.pagePath)
    }

    useEffect(() => {
        if (pathStore) {
            onChangeRoute(pathStore)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathStore])

    useEffect(() => {
        let mediaQuery = window.matchMedia("(max-width:1024px)");

        const handleMediaQueryChange = (event) => {
            if (!event.matches) {
                NFMStore.dispatch(setMenuState({ isMenuOpen: !menuState.isMenuOpen, isMobile: false }))
            } else {
                NFMStore.dispatch(setMenuState({ isMenuOpen: menuState.isMenuOpen, isMobile: true }))
            }
        };

        handleMediaQueryChange(mediaQuery);
        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Used to know the user page when reloading
    useEffect(() => {
        const path = location.pathname.substring(1, location.pathname.length)

        if (path !== sectionSelected?.pagePath) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setSectionSelected(pages.find(p => p.pagePath === path))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function onMenuItemClick(item: PageMenuType) {
        onChangeRoute(item)
    }

    function setMenuOpen() {
        NFMStore.dispatch(setMenuState({ isMenuOpen: !menuState.isMenuOpen, isMobile: menuState.isMobile }))
    }

    return (
        <Drawer
            variant={menuState.isMobile ? "temporary" : "permanent"}
            onClose={() => setMenuOpen()}
            open={menuState.isMenuOpen}
            sx={{
                width: "300px",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: "300px", boxSizing: 'border-box' }
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto', flex:1 }}>
                <List>
                    {pages.filter(p => p.isVisible !== false).map((page, index) => {
                        if (page.type === "page") {
                            return (
                                <ListItem key={page.pagePath} disablePadding>
                                    <ListItemButton style={{ marginLeft: "8px" }} sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: colorPrimaryPale,
                                            color: sectionSelected?.pagePath === page?.pagePath ? colorPrimary : colorDarkBlue
                                        }
                                    }} selected={sectionSelected?.pagePath === page?.pagePath} onClick={() => onMenuItemClick(page)}>
                                        {React.cloneElement(page.icon, { fill: sectionSelected?.pagePath === page?.pagePath ? colorPrimary : colorDarkBlue, width: "25px" })}
                                        <ListItemText style={{ marginLeft: "8px" }} primaryTypographyProps={{ fontWeight: 600 }} primary={page.pageName} />
                                    </ListItemButton>
                                </ListItem>
                            )

                        } else {
                            return (
                                <ListItem key={page.pagePath} disablePadding>
                                    <ListItemText style={{ marginLeft: "24px" }} primaryTypographyProps={{ fontWeight: 600, color: colorGrey }} primary={page.pageName} />
                                </ListItem>
                            )
                        }
                    })}
                </List>
            </Box>
            <p style={{padding:"10px"}}>{version}</p>
        </Drawer>
    )
}