import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { centroid } from "@turf/turf";
import { LngLat, Map } from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GetNiagaContract, getContractAgent } from "../../api";
import Avatar from "../../components/Avatar";
import { EmptyView } from "../../components/EmptyView";
import { FirebaseImage } from "../../components/FirebaseImage";
import { Loader } from "../../components/Loader";
import MapBox from "../../components/MapBox";
import { defaultLat, defaultLng } from "../../helper/helper";
import { addContractDetailLayers, updatePickedUps, updatePlots, zoomForCoordinates } from "../../models/MapBoxDataModel";
import { Contract, buildContractForDetail } from "../../models/contract/Contract";
import { Harvest } from "../../models/contract/Harvest";
import { ReactComponent as Check } from "../../rsc/check.svg";
import { colorLightGrey, colorPrimary, colorPrimaryDark } from "../../rsc/colors/colors";
import { ReactComponent as LogoDO } from "../../rsc/ic_do.svg";
import { ReactComponent as Money } from "../../rsc/ic_money.svg";
import { ReactComponent as Supply } from "../../rsc/ic_supply.svg";
import "./ContractDetail.css";
import { Divider } from "@mui/material";

type ContractDetailProps = {
    client: ApolloClient<NormalizedCacheObject>
}

function ContractDetail(props: ContractDetailProps) {

    const [contract, setContract] = useState<Contract>(undefined)
    const [center, setCenter] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const mapRef = useRef(null)
    const contractRef = useRef<Contract>(null)
    const popupsRef = useRef([])
    const pickedUpLocation = useRef([])

    const [param] = useSearchParams()

    var id = param.get("id")
    var contract_type = param.get("contract_type")
    // var contractId = param.get("contract_id")

    const { t, i18n } = useTranslation()

    function isAgentContract(type: string): boolean {
        return type === "NAM"
    }

    function fetchContractFromSale(saleId: number, type) {
        setIsLoading(true)
        const query = isAgentContract(type) ? getContractAgent : GetNiagaContract
        props.client.query({
            query: query,
            variables: {
                statementId: Number(saleId)
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then((data) => {
            const contract = isAgentContract(type) ? data.data.niaga_agent_contract_detail : data.data.niaga_contract_detail
            const contractBuilt = buildContractForDetail(contract)
            setCenter(extractCenter(contractBuilt))
            setContract(contractBuilt)
            contractRef.current = contractBuilt
            updatePlots(mapRef.current, contractRef.current.harvests.map(h => h.harvest))
            centerMap()
        }).catch(e => {
            console.log(e)
        })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // function fetchSaleFromContractId(cId: number) {
    //     setIsLoading(true)
    //     const query = GetSaleFromContract
    //     props.client.query({
    //         query: query,
    //         variables: {
    //             id: cId
    //         },
    //     }).then((data) => {
    //         console.log(data)
    //         // fetchContractFromSale(data.data)
    //     }).finally(() => {
    //         setIsLoading(false)
    //     })
    // }

    function centerMap() {
        var coords: LngLat[]
        if (contractRef.current.harvests?.length > 0) {
            coords = [...contractRef.current.harvests].map(h => h.harvest.land_plot).flatMap(p => p.polygon ? p.polygon.coordinates[0].map(co => new LngLat(co[0], co[1])) : [])
        }
        if (pickedUpLocation.current.length > 0) {
            coords = [...coords, ...pickedUpLocation.current.map(co => new LngLat(co[0], co[1]))]
        }
        zoomForCoordinates(mapRef.current, coords, 14, 0)
    }

    useEffect(() => {
        const changeLanguageHandler = (l) => {
            if (mapRef.current) {
                // if (contract.isAgentContract()) {
                //     addContractDetailNAMLayers(mapRef.current)
                // } else {
                //     addContractDetailNAFLayers(mapRef.current, contractRef.current.harvests.map(h => h.harvest), popupsRef.current, t)
                // }
            }
        };

        i18n.on('languageChanged', changeLanguageHandler);

        return () => {
            i18n.off('languageChanged', changeLanguageHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n])

    const extractCenter = (contractExtract: Contract) => {
        if (contractExtract?.harvests) {
            let coo = contractExtract.harvests[0]?.harvest?.land_plot?.polygon?.coordinates
            if (coo) {
                let middle = centroid(
                    {
                        'type': 'Feature',
                        'properties': {
                        },
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': coo
                        }
                    }
                )
                return middle.geometry.coordinates
            } else {
                return null
            }
        }
    }

    const marketDay = () => {
        return contract?.getMarketDayUtc()
    }

    function harvestsPhotos(harvest: Harvest) {
        var photos = new Set(harvest.fruitPhotos().map(p => p.uri))
        return (
            <div key={harvest.land_plot.name}>
                {
                    photos?.size > 0 ?
                        <div>
                            <div style={{ background: colorLightGrey }}>
                                <div style={{ flex: "1", display: "flex", alignItems: "center", padding: "0px 15px 0px 15px", flexDirection: "row" }}>
                                    <Supply style={{ height: "25px" }} />
                                    <p style={{ margin: "15px 0px 15px 15px", fontSize: "20px", fontWeight: "700" }}>{t('contract_land_plot_header', { name: harvest.land_plot.name })}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {[...photos].map(p => {
                                    return <FirebaseImage onMetadataLoaded={(metadata) => {
                                        const location = metadata.photo_location
                                        try {
                                            const jsonObject = JSON.parse(location);
                                            pickedUpLocation.current.push(jsonObject.coordinates)
                                            if (pickedUpLocation.current.length === photos.size) {
                                                updatePickedUps(mapRef.current, pickedUpLocation.current)
                                                centerMap()
                                            }
                                        } catch (error) {
                                            console.error('Error parsing JSON:', error);
                                        }
                                    }} key={p} style={{ width: "calc(50%)", border: "1px solid black", cursor: "pointer", display: "inline-block" }} uri={p} />
                                }
                                )}
                            </div>
                        </div>
                        : <div key={harvest.land_plot.name}></div>
                }
            </div>
        )
    }

    function harvestsPhotosSeller() {
        var photos = new Set(contract.getNAMHarvestPhoto())
        return (
            <div key={t('farmer')}>
                {
                    photos?.size > 0 ?
                        <div>
                            <div style={{ background: colorLightGrey }}>
                                <div style={{ flex: "1", display: "flex", alignItems: "center", padding: "0px 15px 0px 15px", flexDirection: "row" }}>
                                    <Supply style={{ height: "25px" }} />
                                    <p style={{ margin: "15px 0px 15px 15px", fontSize: "20px", fontWeight: "700" }}>{t('farmer')}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {[...photos].map(p => {
                                    return <FirebaseImage onMetadataLoaded={(metadata) => {
                                        const location = metadata.photo_location
                                        try {
                                            const jsonObject = JSON.parse(location);
                                            pickedUpLocation.current.push(jsonObject.coordinates)
                                            if (pickedUpLocation.current.length === photos.size) {
                                                updatePickedUps(mapRef.current, pickedUpLocation.current)
                                                centerMap()
                                            }
                                        } catch (error) {
                                            console.error('Error parsing JSON:', error);
                                        }
                                    }} key={p} style={{ width: "calc(50%)", border: "1px solid black", cursor: "pointer", display: "inline-block" }} uri={p} />
                                }
                                )}
                            </div>
                        </div>
                        : <></>
                }
            </div>
        )
    }

    function displayReceipt(contract: Contract) {
        const photos = contract.getReceiptPhoto()
        return (
            photos ?
                <div style={{ marginTop: "20px" }}>
                    <div style={{ background: colorLightGrey }}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", padding: "0px 15px 0px 15px", flexDirection: "row" }}>
                            <Money />
                            <p style={{ margin: "15px 0px 15px 15px", fontSize: "20px", fontWeight: "700" }}>{t('contract_receipt_header')}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            photos.map(p =>
                                <FirebaseImage style={{ width: "calc(50%)", border: "1px solid black", cursor: "pointer", display: "inline-block" }} uri={p} />
                            )
                        }
                    </div>
                </div>
                : <></>
        )
    }

    function shouldDisplayHarvest() {
        if (contract?.isAgentContract()) {
            return contract?.getNAMHarvestPhoto().length > 0
        } else {
            return contract?.harvests?.find(h => h.harvest?.photos?.length > 0) != null
        }
    }

    function shouldDisplayReceipt(): boolean {
        return contract?.getReceiptPhoto() !== undefined
    }

    function generateHarvestPicture() {
        if (contract?.isAgentContract()) {
            return (harvestsPhotosSeller())
        } else {
            return (contract?.harvests?.map(h => harvestsPhotos(h.harvest)))
        }
    }

    function displayWeightInfo() {
        const lineItems = contract?.getSaleStatementBuyer()?.line_items?.map(li => {
            const displayTitle = li.isETaxLineItem() ? "none" : "block"
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "2", display: "flex" }}>
                        <div style={{ flex: 1, display:displayTitle }}>
                            <div style={{ fontSize: "12px" }}>{li.title}</div>
                            <div style={{ textAlign: "start", display: "flex", flex: 1 }}>
                                <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "18px" }}>{li.price_per_unit}</span>
                                <span style={{ marginRight: "10px", fontWeight: "700", color: "black", fontSize: "11px" }}>Rp/KG</span>
                                <span style={{ marginRight: "10px", fontWeight: "700", color: "black", fontSize: "11px" }}>x</span>
                                <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "18px" }}>{li.quantity}</span>
                                <span style={{ marginRight: "10px", fontWeight: "700", color: "black", fontSize: "11px" }}>KG</span>
                            </div>
                        </div>
                        <span style={{ marginRight: "10px", fontWeight: "700", color: "black", fontSize: "11px", alignContent: "end", display: displayTitle}}>=</span>
                    </div>
                    <div style={{ flex: "1", textAlign: "end", display: "flex" }}>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: li.total > 0 ? "black" : "red", fontSize: "18px", flex: 1, alignSelf: "end" }}>{li.total.IndoFormat()}</span>
                        <span style={{ marginRight: "0px", fontWeight: "700", color: li.total > 0 ? "black" : "red", fontSize: "11px", alignSelf: "end" }}>Rp</span>
                    </div>
                </div>
            )
        })

        return <div>
            <div style={{ display: "flex", gap: "10px", flexDirection: "column", marginBottom: "8px" }}>
                {lineItems}
            </div>
            <Divider />
            {contract?.getSaleStatement()?.getPricePerUnit() &&
                <div>
                    <div style={{ marginTop: "20px", display: "flex" }}>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "18px", flex: 1 }}>{t('total')}</span>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "20px" }}>{contract.getSaleStatement()?.getPreTaxTotal()?.IndoFormat()}</span>
                        <span style={{ marginRight: "0px", fontWeight: "700", color: "black", fontSize: "11px" }}>Rp</span>
                    </div>
                    <div style={{ marginTop: "20px", display: "flex" }}>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "18px", flex: 1 }}>{t('tax_total')}</span>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "20px" }}>{contract.getSaleStatement()?.getTaxTotal()?.IndoFormat()}</span>
                        <span style={{ marginRight: "0px", fontWeight: "700", color: "black", fontSize: "11px" }}>Rp</span>
                    </div>
                    <div style={{ marginTop: "20px", display: "flex" }}>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "26px", flex: 1 }}>{t('total_plus_tax')}</span>
                        <span style={{ marginRight: "5px", fontWeight: "700", color: "black", fontSize: "26px" }}>{contract.getSaleStatement()?.getTotal()?.IndoFormat()}</span>
                        <span style={{ marginRight: "0px", fontWeight: "700", color: "black", fontSize: "11px" }}>Rp</span>
                    </div>
                </div>
            }
        </div>
    }

    function hasHarvest(): boolean {
        return contract?.harvests?.length > 0
    }

    function onMapLoaded(map: Map) {
        mapRef.current = map
        addContractDetailLayers(mapRef.current, popupsRef.current)
        // if (contractId) {
        //     fetchSaleFromContractId(Number(contractId))
        // } else {
        fetchContractFromSale(Number(id), contract_type)
        // }
    }

    return (
        <div style={{ flex: "1", paddingBottom: "20px" }}>
            <div style={{ paddingLeft: "28px", paddingRight: "20px" }}>
                <h1 style={{ marginLeft: "0px" }}>{t('contract_detail')} {contract?.id}</h1>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: "end" }}>
                        <LogoDO />
                        <p style={{ fontSize: "24px", marginLeft: "10px", fontWeight: "700", display: "inline-block" }}>{contract?.primary_buyer?.organisation?.name}</p>
                        <p className="greyColor" style={{ fontWeight: "700", fontSize: "18px", marginLeft: "15px", display: "inline-block" }}>{marketDay()}</p>
                    </div>
                    <div>
                        <div style={{ padding: "5px 15px", borderRadius: "4px", verticalAlign: "middle", marginRight: "0px", textAlign: "center", border: `solid 2px ${colorPrimary}`, display: "inline-block" }}>
                            <Check fill={colorPrimary} style={{ verticalAlign: "middle" }} />
                            <span className="primaryColor" style={{ textTransform: "capitalize", fontSize: "16px", fontWeight: "700", verticalAlign: "middle", marginLeft: "8px" }}>{contract?.status}</span>
                        </div>
                    </div>
                </div>
                <hr style={{ width: "auto", border: "0", height: "2px", backgroundColor: colorPrimaryDark, margin: "0 0 10px 0" }} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "3" }}>
                        <p className="greyColor" style={{ margin: "10px 0", fontSize: "18px", fontWeight: "500" }}>{contract?.isAgentContract() ? t('agent') : t('transporter')}</p>
                        <Avatar
                            style={{ width: "55px", height: "55px" }}
                            firstname={contract?.getTransporter()?.transactor?.person?.firstname}
                            lastname={contract?.getTransporter()?.transactor?.person?.lastname}
                            uri={contract?.getTransporter().transactor?.person.user.avatar_url} />
                        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                            <p style={{ fontSize: "16px", fontWeight: "700", marginTop: "5px", marginBottom: "5px", marginLeft: "10px" }}>{contract?.getTransporter()?.transactor?.person?.firstname + " " + contract?.getTransporter()?.transactor?.person?.lastname}</p>
                            <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "0px", marginBottom: "0px", marginLeft: "10px" }}>{contract?.getTransporter()?.transactor?.person?.location.village + ", " + contract?.getTransporter()?.transactor?.person?.location.district}</p>
                        </div>
                    </div>

                    <div style={{ paddingTop: "18px", display: "flex", flexDirection: "column", flex: "1", justifyContent: "end" }}>
                        {displayWeightInfo()}
                    </div>
                </div>
                <div className="map-photo-container">
                    {
                        shouldDisplayHarvest() || shouldDisplayReceipt() ?
                            <div className="photo-container">
                                {
                                    shouldDisplayHarvest() ? generateHarvestPicture() : <></>
                                }
                                {
                                    shouldDisplayReceipt() ? displayReceipt(contract) : <></>
                                }
                            </div>
                            : <></>
                    }
                    <div className="map-container-contract">
                        <div className="map-container-ar">
                            <div style={{ position: "relative" }}>
                                <MapBox lat={center ? center[1] : defaultLat} lng={center ? center[0] : defaultLng} onLoaded={(map) => { onMapLoaded(map) }} />
                                {
                                    !hasHarvest() ? <div style={{
                                        padding: "10px", backgroundColor: "#FFFFFF88", position: "absolute", display: "flex", flexDirection: "column",
                                        right: "0px",
                                        bottom: "0px",
                                        top: "0px",
                                        left: "0px"
                                    }}>
                                        <EmptyView isVisible={true} value={t('no_harvest_for_contract')} />
                                    </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Loader isLoading={isLoading}/>
        </div>
    )
}

export default ContractDetail