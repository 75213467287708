import { FormControl, MenuItem, Select } from "@mui/material"
import axios from "axios"
import dayjs from "dayjs"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import { client } from "../.."
import { myCompaniesContract } from "../../api"
import { Loader } from "../../components/Loader"
import { Selector, SelectorType } from "../../components/Selector"
import { convertToDateWithUtc, getLocal, showErrorMessage } from "../../helper/helper"
import { transactorId } from "../../helper/TokenHelper"
import { Organisation, buildOrganisationFromDetail } from "../../models/organisation/Organisation"
import { colorGrey, colorLight, colorYellowLight } from "../../rsc/colors/colors"
import { PempemInvoiceDetail } from "./PempemInvoiceDetail"
import { VATInvoiceDetail } from "./VATInvoiceDetail"

export type InvoiceREST = {
    fileName: string
    fileNameShortened: string
    url: string
    total: any
    date: string
    metadata: any
}

const buildYearFromTimestamp = (): SelectorType[] => {
    const years: SelectorType[] = []
    let timestamp = 1704067200000; //Timestamp of 01/01/2024
    let startDate = new Date(timestamp);
    let startYear = startDate.getUTCFullYear();
    let currentYear = new Date().getUTCFullYear();

    for (let year = startYear; year <= currentYear; year++) {
        years.push({ id: year, value: year.toString() });
    }

    return years
}

const YearSelectorData: SelectorType[] = buildYearFromTimestamp()


export function PempemInvoiceList() {

    const [param] = useSearchParams()
    const orgId = param.get("orgId")

    const buildMonth = () => {
        var allMonths: SelectorType[] = [{ id: 0, value: t('all') }]
        const months = Array.from({ length: 12 }, (_, i) => dayjs().locale(getLocal()).month(i).format('MMMM'));
        months.forEach((m, i) => {
            allMonths.push({ id: i + 1, value: m })
        })
        return allMonths
    }

    const MonthSelectorData: SelectorType[] = buildMonth()


    const token = useSelector((state: any) => state.token.value)

    const [filteredInvoices, setFilteredInvoices] = useState<InvoiceREST[]>([])
    const [loading, setLoading] = useState<boolean>()
    const [orgs, setOrgs] = useState<Organisation[]>([])
    const [selectedOrg, setSelectedOrg] = useState<Organisation>()
    const [monthSelected, setMonthSelected] = useState<SelectorType>(MonthSelectorData[0])
    const [yearSelected, setYearStelected] = useState<SelectorType>(YearSelectorData[0])

    const invoices = useRef<InvoiceREST[]>([])
    const invoiceSelected = useRef<InvoiceREST>(undefined)
    const pagingOffset = useRef(0)

    const navigate = useNavigate()


    useEffect(() => {
        client.query({
            query: myCompaniesContract,
            variables: {
                transactorId: transactorId()
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then(result => {
            let orgs: Organisation[] = result.data.organisations.map(o => buildOrganisationFromDetail(o.organisation))
            orgs.sort((orgA, orgB) => { return orgA.name.localeCompare(orgB.name) })
            var firstOrg = orgs.at(0)
            if (orgId) {
                firstOrg = orgs.find(o => o.id === parseInt(orgId)) ?? orgs.at(0)
            }
            setSelectedOrg(firstOrg)
            setOrgs(orgs)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedOrg) {
            pagingOffset.current = 0
            invoices.current = []
            fetchInvoices(selectedOrg?.id)
        }
        // eslint-disable-next-line
    }, [selectedOrg, monthSelected, yearSelected])

    function setInvoicesWithFilter(newInvoices: InvoiceREST[]) {
        var filteredInvoices = newInvoices

        setFilteredInvoices(filteredInvoices.sort((a: InvoiceREST, b: InvoiceREST) => {
            return b.fileNameShortened.localeCompare(a.fileNameShortened)
        }
        ))
    }

    function onSelectorChange(id: number) {
        const org = orgs.find(o => o.id === id)
        setSelectedOrg(org)
    }

    function OrganisationSelector() {
        if (orgs.length > 0) {
            return (
                <div>
                    <div style={{ textTransform: "uppercase", color: colorGrey, fontSize: "13px", fontWeight: "500" }}>{t('companies')}</div>
                    <FormControl style={{ marginTop: "4px" }} className="w-[200px]">
                        <Select
                            sx={{
                                '& .MuiSelect-select': {
                                    paddingRight: 4,
                                    paddingLeft: 2,
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                }
                            }}
                            displayEmpty={true}
                            defaultValue={selectedOrg?.id ? selectedOrg?.id : orgs[0].id}
                            onChange={e => onSelectorChange(e.target.value as number)}>
                            {
                                orgs.map(o => {
                                    return (
                                        <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>)
        } else {
            return (<></>)
        }
    }

    function isVAT(invoice: InvoiceREST) {
        const split = invoice.fileName.split('.')
        if (split) {
            const size = split.length
            return split[size - 1] === 'pdf'
        }
    }

    function goToInvoiceDetail(invoice: InvoiceREST) {
        invoiceSelected.current = invoice
        const path = encodeURIComponent(invoice.url)
        if (isVAT(invoice)) {
            navigate(`invoice_vat_detail?orgId=${selectedOrg.id}&pdfUrl=${path}`)
        } else {
            navigate(`invoice_pempem_detail?orgId=${selectedOrg.id}&pdfUrl=${path}`)
        }
    }

    function InvoiceHeader() {
        return <tr>
            <td>{t('name')}</td>
            <td>{t('date')}</td>
            <td>{t('total')}</td>
            <td>{t('type')}</td>
        </tr>
    }

    function InvoiceRow(invoice: InvoiceREST) {
        const vat = isVAT(invoice)
        return (
            <tr style={{ background: vat ? colorYellowLight : colorLight }} key={invoice.fileName} onClick={() => goToInvoiceDetail(invoice)} className="cursor-pointer hoverGreenPale">
                <td>{invoice.fileNameShortened}</td>
                <td>{convertToDateWithUtc(invoice.date)}</td>
                <td>{invoice.total.IndoFormat()}</td>
                <td>{isVAT(invoice) ? t('vat') : 'PEMPEM'}</td>
            </tr>
        )
    }

    function InvoicesTab() {
        return (
            <table className="contract-row" style={{ borderCollapse: "collapse", width: "calc(100% - 28px)", marginTop: "30px" }}>
                <tbody>
                    {InvoiceHeader()}
                    {filteredInvoices.map((i) => InvoiceRow(i))}
                </tbody>
            </table>
        )
    }

    function fetchInvoices(orgId: number) {
        if (orgId) {
            setLoading(true)
            const timeParam = `year=${yearSelected.id}${monthSelected.id !== 0 ? `&month=${monthSelected.id}` : ''}`
            axios.get(`${process.env.REACT_APP_BASE_URL_PUBLIC}/invoice/palmoil/invoiceList?orgIds=${orgId}&${timeParam}`, {
                withCredentials: false,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    "x-app-id": process.env.REACT_APP_APP_ID,
                    "x-app-name": "NFM"
                }
            }).then((data) => {
                const inv: InvoiceREST[] = data.data[0].invoices.map(i => { return { fileName: i.fileName, url: i.url, total: i.total, date: i.date, metadata: i.metadata, fileNameShortened: i.fileNameShortened } })
                invoices.current = inv
                setInvoicesWithFilter(inv)
            }).catch(e => {
                if (e?.message) {
                    showErrorMessage(e?.message)
                } else {
                    showErrorMessage(t('an_error_occured'))
                }
            })
                .finally(() => {
                    setLoading(false)
                })
        }

    }

    const onErrorLoading = (orgId: string) => {
        navigate(`/explorer/pempem_invoices?orgId=${orgId}`)
        showErrorMessage(t('error_invoice_expired'))
        fetchInvoices(parseInt(orgId))
    }


    return (
        <Routes>
            <Route path="/" element={
                <div className="ml-[28px]">
                    <h1 style={{ marginLeft: "0px" }}>{t("invoices")}</h1>
                    <div style={{ verticalAlign: "bottom", display: "flex", height: "auto", marginRight: "70px", flexShrink: "0", alignItems: "end", gap: "5px" }}>
                        {OrganisationSelector()}
                        <Selector currentValue={monthSelected} list={MonthSelectorData} onSelectionChange={setMonthSelected} label={t('month')} />
                        <Selector currentValue={yearSelected} list={YearSelectorData} onSelectionChange={setYearStelected} label={t('year')} />
                    </div>
                    {InvoicesTab()}
                    <Loader isLoading={loading} />
                </div>
            }></Route>
            <Route path="/invoice_vat_detail" element={<VATInvoiceDetail invoice={invoiceSelected.current} onErrorLoading={onErrorLoading} />} />
            <Route path="/invoice_pempem_detail" element={<PempemInvoiceDetail invoice={invoiceSelected.current} onErrorLoading={onErrorLoading} />} />
        </Routes>
    )
}