import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Dialog, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createInvitationMutation, getPerson } from "../../api";
import { Loader } from "../../components/Loader";
import { userId } from '../../helper/TokenHelper';
import { Organisation } from "../../models/organisation/Organisation";
import { Person, buildPersonFromOrgDetail } from "../../models/person/Person";
import { Transactor } from "../../models/person/Transactor";
import { ReactComponent as Close } from "../../rsc/close.svg";
import { ReactComponent as Warning } from "../../rsc/ic_warning.svg";
import { firebaseAuth } from "../login/firebaseInit";

type AddUserProps = {
    organisation: Organisation,
    client: ApolloClient<NormalizedCacheObject>,
    onClose: () => void,
    isOpen: boolean
}

function AddEmployee({ isOpen, client, onClose, organisation }: AddUserProps) {

    const { t } = useTranslation()

    const [personsSearch, setPersonsSearch] = useState<Person>(null)
    const [emailToSearch, setEmailToSearch] = useState("")
    const [firstNameEntered, setFirstNameEntered] = useState("")
    const [lastNameEntered, setLastNameEntered] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const EmployeeAvatar = (uri, firstname, lastname) => {
        if (uri != null) {
            return <div style={{ display: "inline-block", verticalAlign: "middle" }}><img alt="avatar" style={{ width: "40px", borderRadius: "50%", height: "40px" }} src={uri}>
            </img></div>
        } else {
            return <div className='primaryDarkBgColor' style={{
                display: "inline-block", verticalAlign: "middle",
                textAlign: "center", width: "40px", borderRadius: "50%", height: "40px"
            }}>
                <div style={{ transform: "translateY(-50%)", color: "white", position: "relative", top: "50%", }}>{firstname.charAt(0)}{lastname.charAt(0)}</div></div>
        }
    }

    const EmployeeHeader = () => {
        return <tr>
            <td style={{ width: "25%" }}>{t('name')}</td>
            <td style={{ width: "25%" }}>{t('location')}</td>
            <td style={{ width: "25%" }}>{t('email')}</td>
            <td style={{ width: "25%" }}></td>
        </tr>
    }

    const EmployeeRow = (person: Person) => {

        function isPersonCompleted(): boolean {
            return person.firstname?.length > 0 && person.lastname?.length > 0
        }

        if (personExistInOurDatabase(person)) {
            return <tr key={person.id}>
                <td>{EmployeeAvatar(person.user != null ? person.user.avatar_url : null, person.firstname, person.lastname)}<span style={{ marginLeft: "10px" }}>{person.firstname} {person.lastname}</span></td>
                <td>{person.location?.village}</td>
                <td>{person.user ? person.user.email : "no email set"}</td>
                <td style={{ textAlign: "center" }}>
                    {
                        isPersonAlreadyInCompany(person.transactor) ?
                            <p style={{ color: "red" }}>{t('person_already_in_company')}</p>
                            : <button style={{ width: "auto" }} onClick={() => invitePerson(person)} className="pem-button">{t('invite_user')}</button>
                    }
                </td>
            </tr>
        } else if (personExistInFirebase(person)) {
            return <tr key={person.id}>
                <td style={{ display: "flex", alignItems: "center" }}>{EmployeeAvatar(person.user != null ? person.user.avatar_url : null, person.user.email[0], person.user.email[1])}
                    <p style={{ marginLeft: "10px", flex: "1", textAlign: "center", color: "red" }}>{t('user_found_no_info')}</p>
                </td>
                <td>{person.location?.village}</td>
                <td>{person.user ? person.user.email : "no email set"}</td>
                <td style={{ textAlign: "center" }}>
                    <button style={{ width: "auto" }} onClick={() => invitePerson(person)} className="pem-button">{t('invite_user')}</button>
                </td>
            </tr>
        } else {
            return (
                <tr className="yellowLightBgColor" key={person.user.email}>
                    <td>{EmployeeAvatar(null, person.firstname, person.lastname)}<span style={{ marginLeft: "10px" }}>{person.firstname} {person.lastname}</span></td>
                    <td>{person.location?.village}</td>
                    <td>{person.user.email}</td>
                    <td style={{ textAlign: "center" }}>
                        <button disabled={!isPersonCompleted()} style={{ width: "auto" }} onClick={() => invitePerson(person)} className="pem-button">{t('invite_user')}</button>
                    </td>
                </tr>
            )
        }
    }

    // function getColorMessage(): string {
    //     if (isUserInfoInputOnError(firstNameEntered) || isUserInfoInputOnError(lastNameEntered)) {
    //         return "red"
    //     } else {
    //         return "green"
    //     }
    // }

    function warningMessage() {
        return !personExist(personsSearch) ?
            <div className='yellowLightBgColor ml-[28px] mr-[20px] mt-[40px] mb-[40px] p-[20px]' style={{ color: "black", textAlign: "center" }}>
                <Warning className='inline-block h-[30px] w-[auto]' />
                <p className='inline-block ml-[15px] align-middle'>{t('add_person_not_found')}</p>
            </div>
            : <></>
    }

    const resultSearch = () => {
        return (
            <div>
                {
                    warningMessage()
                }
                <table className="company-detail-table" style={{ borderCollapse: "collapse", width: "calc(100% - 48px)", marginLeft: "28px", marginTop: "10px" }}>
                    <tbody>
                        {EmployeeHeader()}
                        {(() => {
                            if (personsSearch != null) {
                                return EmployeeRow(personsSearch)
                            } else {
                                return <tr><td></td></tr>
                            }
                        })()
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    // const addPersonToCompany = (person) => {
    //     setIsLoading(true)
    //     props.client.mutate({
    //         mutation: addPersonMutation,
    //         variables: {
    //             transactorId: person.transactor.id,
    //             organisationId: props.organisation.id,
    //             controlOrg: false,
    //             controlSales: true,
    //             controlPrices: true,
    //             readSales: true,
    //             readPrices: true
    //         }
    //     }).then(result => {
    //         setIsLoading(false)
    //     }, e => {
    //         setIsLoading(false)
    //     })
    // }

    const invitePerson = (person: Person) => {
        setIsLoading(true)
        let object
        if (personExist(person)) {
            object = {
                email: person.user.email,
                is_pempem_user: true,
                firstname: person.firstname,
                lastname: person.lastname,
                inviter_organisation_id: organisation.id,
                inviter_user_id: userId()
            }
        } else {
            object = {
                email: person.user.email,
                is_pempem_user: false,
                inviter_organisation_id: organisation.id,
                inviter_user_id: userId(),
                firstname: firstNameEntered,
                lastname: lastNameEntered
            }
        }
        client.mutate({
            mutation: createInvitationMutation,
            variables: {
                object: object
            },
            context: {
                headers: {
                    "role": organisation.isMill() ? "usermill" : "user"
                }
            }
        }).then(user => {
        }).finally(() => {
            setIsLoading(false)
            onClose()
        })
    }

    const isPersonAlreadyInCompany = (transactor: Transactor) => {
        return organisation.employees.find(e => e.transactor_id === transactor.id)
    }

    const personExistInOurDatabase = (person: Person): boolean => {
        return person.transactor !== null && person.transactor !== undefined
    }

    const personExistInFirebase = (person: Person): boolean => {
        return person.id === -1
    }

    const personExist = (person: Person): boolean => {
        return personExistInOurDatabase(person) || personExistInFirebase(person)
    }

    const startSearch = () => {
        if (emailToSearch != null && emailToSearch.length > 0) {
            setIsLoading(true)
            client.query({
                query: getPerson,
                variables: {
                    email: emailToSearch
                }
            }).then(result => {
                if (result.data.persons.length > 0) {
                    const personFound = buildPersonFromOrgDetail(result.data.persons[0])
                    setPersonsSearch(personFound)
                } else {
                    searchInFirebase()
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const searchInFirebase = () => {
        firebaseAuth().fetchSignInMethodsForEmail(emailToSearch)
            .then((providers) => {
                if (providers.length > 0) {
                    setPersonFound(buildPersonFromOrgDetail(buildExistingPersonFromInput()))
                } else {
                    setPersonFound(buildPersonFromOrgDetail(buildPersonFromInput()))
                }
            })
    }

    const setPersonFound = (person: Person) => {
        setPersonsSearch(person)
    }

    const buildPersonFromInput = () => {
        return {
            "firstname": firstNameEntered,
            "lastname": lastNameEntered,
            "user": {
                "email": emailToSearch
            },
            "location": null
        }
    }

    const buildExistingPersonFromInput = () => {
        return {
            "id": -1, // set here to differenciate between existing person with mail found in our database vs mail not found in our database but found in firebase
            "firstname": "",
            "lastname": "",
            "user": {
                "email": emailToSearch
            },
            "location": null
        }
    }

    const isPersonInfoDisable = (): boolean => {
        return personsSearch === null || personExist(personsSearch)
    }

    const editFirstName = (name: string) => {
        if (personsSearch) {
            personsSearch.firstname = name
        }
        setFirstNameEntered(name)
    }

    const editLastName = (name: string) => {
        if (personsSearch) {
            personsSearch.lastname = name
        }
        setLastNameEntered(name)
    }

    const isUserInfoInputOnError = (text: string): boolean => {
        if (!isPersonInfoDisable() && text.length === 0) {
            return true
        }
        return false
    }

    const getErrorMessage = (text): string => {
        if (isUserInfoInputOnError(text)) {
            return "Field requiered"
        }
        return null
    }

    return (

        <Dialog onClose={() => onClose()} open={isOpen}>
            <DialogContent>
                <div onClick={e => e.stopPropagation()} style={{
                    width: "auto", height: "auto", backgroundColor: "white", position: "fixed", top: "70px", left: "70px"
                    , right: "70px", bottom: "70px"
                }}>

                    <Close onClick={() => onClose()} style={{ position: "absolute", top: "28px", right: "28px" }}></Close>

                    <h1 style={{ marginTop: "50px" }}>{t('add_user')}</h1>
                    <div style={{ display: "flex" }} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            startSearch()
                        }
                        e.stopPropagation()
                    }
                    }>
                        <TextField value={emailToSearch}
                            onChange={(event) => setEmailToSearch(event.target.value.trim())} style={{ flex: "0 0 15%", marginLeft: "25px" }}
                            placeholder={t('email')} />
                        <TextField disabled={isPersonInfoDisable()} value={firstNameEntered} error={isUserInfoInputOnError(firstNameEntered)} helperText={getErrorMessage(firstNameEntered)}
                            onChange={(event) => editFirstName(event.target.value)} style={{ flex: "0 0 15%", marginLeft: "25px" }} placeholder={t('firstname')} />

                        <TextField disabled={isPersonInfoDisable()} value={lastNameEntered} error={isUserInfoInputOnError(lastNameEntered)} helperText={getErrorMessage(lastNameEntered)}
                            onChange={(event) => editLastName(event.target.value)} style={{ flex: "0 0 15%", marginLeft: "25px" }} placeholder={t('lastname')} />
                        <button className="pem-button" style={{ flex: "0 0 10%", marginLeft: "25px", height: "56px" }} onClick={() => startSearch()}>{t('search_user')}</button>
                    </div>
                    {
                        personsSearch != null ?
                            resultSearch()
                            : <></>
                    }
                    <Loader isLoading={isLoading} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AddEmployee