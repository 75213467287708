import React, { useEffect, useRef } from "react"

export function Modal({ children, onClose }) {

    const parentRef = useRef(null)

    useEffect(() => {
        if (parentRef) {
            parentRef.current.focus()
        }
    }, [])

    return (
        <div ref={parentRef}
            tabIndex={0} onKeyDown={(e) => { //Tabindex used to get trigger the escape
                if (e.key === "Escape") {
                    onClose()
                }
                e.stopPropagation()
            }
            }
            style={{
                color: "black",
                fontWeight: "initial",
                fontSize: "initial",
                cursor: "default",
                position: "fixed",
                zIndex: "20",
                top: "0",
                right: "0",
                left: "0",
                bottom: "0",
                backgroundColor: "rgba(0, 0, 0, .5)"
            }}
            onClick={() => onClose()}>
            {children}
        </div>
    )
}