import { Person, buildPersonFromOrgDetail } from "./Person";

export class Transactor {
    id: number
    person?: Person
    constructor(id: number, person?: Person) {
        this.id = id
        this.person = person
    }
}

export function buildTransactorFromDetail(t: any): Transactor {
    return new Transactor(t.id,
        t.person ? buildPersonFromOrgDetail(t.person) : null)
}