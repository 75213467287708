import mapboxgl, { FitBoundsOptions, GeoJSONSource, LngLat, LngLatBounds, Map, Popup } from "mapbox-gl";
import Bubble from "../rsc/ic_bubble_price.png"
import DOIcon from "../rsc/ic_do.png"
import RampIcon from "../rsc/ic_ramp.png"
import Middleman from "../rsc/ic_middleman.png"
import MyCompany from "../rsc/ic_my_company.png"
import Pickup from "../rsc/ic_pickup.png"
import { MapEvent } from "./MapEvent";
import { PriceRadius } from "./price/PriceRadius";
import { Harvest } from "./contract/Harvest";
import { Organisation } from "./organisation/Organisation";
import { PriceForOrganisation } from "../pages/my_prices/MyPrices";
import { TFunction, t } from "i18next";

function overlayImageMyCompany(): Promise<string> {
    var image1 = new Image();
    image1.src = MyCompany;
    return new Promise<string>((resolve) => {
        image1.onload = function () {
            var image2 = new Image();
            image2.src = DOIcon;
            image2.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                canvas.width = image1.width;
                canvas.height = image1.height;
                ctx.drawImage(image1, 0, 0);
                const img2Width = image2.width / 3
                const img2Height = image2.height / 3
                ctx.drawImage(image2, (image1.width / 2) - (img2Width / 2), 18, img2Width, img2Height);
                resolve(canvas.toDataURL())
            }
        }
    })
}

function overlayImagePrice(img: any, scale: number, right = 20, top = 15): Promise<string> {
    var image1 = new Image();
    image1.src = Bubble;
    return new Promise((resolver) => {
        image1.onload = function () {
            var image2 = new Image();
            image2.src = img;
            image2.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                canvas.width = image1.width;
                canvas.height = image1.height;
                ctx.drawImage(image1, 0, 0);
                const img2Width = image2.width / scale
                const img2Height = image2.height / scale
                ctx.drawImage(image2, right, top, img2Width, img2Height);
                resolver(canvas.toDataURL())
            }
        }
    })
}

export function buildDataForPricePage(map: Map, prices: PriceForOrganisation[], popups: Popup[], translation: TFunction<"translation", undefined, "translation">) {
    if (map.getLayer('organisation')) {
        map.removeLayer('organisation')
        map.removeSource('organisation')

        popups.forEach(p => p.remove())
    }
    addMyCompaniesFeature(map, prices, popups, translation)
}

export function addPriceCirclRadius(map: Map, organisation: Organisation) {
    if (map.getLayer("priceCircle")) {
        map.removeLayer("priceCircle")
        map.removeSource("priceCircle")
    }
    let points = 64;

    var coords = {
        latitude: organisation.positionepsg4326.coordinates[1],
        longitude: organisation.positionepsg4326.coordinates[0]
    };

    var km = 10;

    var ret = [];
    var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    map.addSource("priceCircle", {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ret]
                },
                "properties": {
                }
            }]
        }
    })

    map.addLayer({
        "id": "priceCircle",
        "type": "fill",
        "source": "priceCircle",
        "layout": {},
        "paint": {
            "fill-color": "blue",
            "fill-opacity": 0.2
        }
    });
}

export function buildDataForMarketPage(map: Map, prices: PriceRadius[], organisation: Organisation) {
    if (map.getLayer('organisation')) {
        map.removeLayer('organisation')
        map.removeSource('organisation')
    }
    if (map.getLayer('prices')) {
        map.removeLayer('prices')
        map.removeSource('prices')
    }
    if (organisation) {
        addMyCompanyFeature(map, organisation)
    }
    if (prices) {
        addPricesFeatures(map, prices)
    }
}

export function buildDataForCompanyDetailPage(map: Map, organisation: Organisation, defaultOrgCoord: number[] = null): Promise<boolean> {
    return new Promise((resolver) => {
        addMyCompanyFeature(map, organisation, defaultOrgCoord).then((value) => {
            resolver(value)
        })
    })
}

export function addDragForCompanyDetailPage(map: Map, mapEvent: MapEvent) {
    map.on('mousedown', 'organisation', mapEvent.onDown)
    map.on('click', mapEvent.onMapClick)
}

export function removeCompanyDetailSource(map: Map) {
    if (map.getLayer('organisation')) {
        map.removeLayer('organisation')
        map.removeSource('organisation')
    }
}

export function removeDragForCompanyDetailPage(map: Map, mapEvent: MapEvent) {
    map.off("mousemove", mapEvent.onMove)
    map.off("mouseup", mapEvent.onUp)
    map.off('mousedown', 'organisation', mapEvent.onDown)
    map.off('click', mapEvent.onMapClick)
}

function loadPickupImage(map: Map, block: () => void) {
    if (map.hasImage('pickup') === false) {
        var img = new Image()
        img.src = Pickup
        img.onload = function () {
            map.addImage('pickup', img, {
                pixelRatio: 5
            })
            block()
        }
    } else {
        block()
    }
}

function loadPricesImage(map: Map, block: () => void) {
    if (map.hasImage('drop_off') === false) {
        overlayImagePrice(DOIcon, 3, undefined, 10)
            .then((src1) => {
                var img = new Image()
                img.src = src1
                img.onload = function () {
                    map.addImage('drop_off', img, {
                        pixelRatio: 1.5
                    })
                    overlayImagePrice(RampIcon, 2, 17)
                        .then((src2) => {
                            const img2 = new Image()
                            img2.src = src2
                            img2.onload = function () {
                                map.addImage('loading_ramp', img2, {
                                    pixelRatio: 1.5
                                })
                                overlayImagePrice(Middleman, 1.5, 17, 4)
                                    .then((src3) => {
                                        const img3 = new Image()
                                        img3.src = src3
                                        img3.onload = function () {
                                            map.addImage('freelance', img3, {
                                                pixelRatio: 1.5
                                            })
                                            block()
                                        }
                                    })
                            }
                        })
                }
            })
    } else {
        block()
    }
}

function loadCompanyImage(map: Map, block: (src?: string) => void) {
    if (map.hasImage('my_company') === false) {
        overlayImageMyCompany().then((src) => {
            var img = new Image()
            img.src = src
            img.onload = function () {
                map.addImage('my_company', img, {
                    pixelRatio: 1.5
                })
                block(src)
            }
        })
    } else {
        block()
    }
}

function addPricesFeatures(map: Map, prices: PriceRadius[]) {
    var source = []
    loadPricesImage(map, () => {
        prices.forEach((price: any) => {
            var position = price.organisation.positionepsg4326.coordinates
            var imgPriceOrgType = price.organisation.organisation_type
            source.push({
                "type": 'Feature',
                "geometry": {
                    'type': 'Point',
                    'coordinates': [position[0], position[1]]
                },
                'properties': {
                    'icon': imgPriceOrgType,
                    'price': price.price.price_per_quantity_unit
                }
            })
        })
        map.addSource('prices', {
            "type": 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': source
            }
        })

        map.addLayer({
            'id': 'prices',
            'type': 'symbol',
            'source': 'prices',
            'layout': {
                'icon-image': ['get', 'icon'],
                'text-field': ['get', 'price'],
                'text-offset': [1, -0.9],
                'text-anchor': 'top'
            }
        })
    })
}

function addMyCompanyFeature(map: Map, organisation: Organisation, defaultOrgCoord?: number[]): Promise<boolean> {
    return new Promise((resolver) => {
        let coordinates = organisation?.positionepsg4326?.coordinates ? organisation?.positionepsg4326?.coordinates : defaultOrgCoord ? defaultOrgCoord : null
        if (coordinates && !map.getSource('organisation')) {
            loadCompanyImage(map, () => {
                map.addSource('organisation', {
                    "type": 'geojson',
                    'data': getMyCompaniyDetailSource(coordinates, organisation.name)
                })
                map.addLayer({
                    'id': 'organisation',
                    'type': 'symbol',
                    'source': 'organisation',
                    'layout': {
                        'icon-image': 'my_company',
                        "icon-anchor": 'bottom'
                    }
                })
                resolver(true)
            })
        } else {
            resolver(false)
        }
    })
}

function addMyCompaniesFeature(map: Map, prices: PriceForOrganisation[], popups: Popup[], translation: TFunction<"translation", undefined, "translation">) {
    loadCompanyImage(map, () => {
        let source = []
        prices.forEach(p => {
            const o = p.org
            const coord = o?.positionepsg4326?.coordinates
            if (coord) {
                source.push({
                    "type": 'Feature',
                    "geometry": {
                        'type': 'Point',
                        'coordinates': [coord[0], coord[1]]
                    },
                    'properties': {
                        'icon': "my_company",
                    }
                })
                let htmlPrice = ""
                p.price.bids.forEach(price => {
                    const priceToSHow = price.price_per_quantity_unit ? `Rp ${price.price_per_quantity_unit}` : translation('no_price_set')
                    htmlPrice += `<div style="display:flex;align-items:center;"><p style="display:inline-block;margin:0 0 0 0">${price.market_day}:</p>`
                    htmlPrice += `<p style="font-weight:bold;display:inline-block;margin:0 0 0 5px;flex:1">${priceToSHow}</p></div>`
                })
                const html = `<div>
            <p style="text-decoration-line: underline;text-align: center;font-weight:bold;">${translation('published_prices')}</p>  
            ${htmlPrice}          
            </div>`
                const popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, offset: 60, anchor: "bottom" }).setLngLat([coord[0], coord[1]]).setHTML(html)
                popups.push(popup)
                popup.addTo(map)
            }
        })
        map.addSource('organisation', {
            "type": 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': source
            }
        })
        map.addLayer({
            'id': 'organisation',
            'type': 'symbol',
            'source': 'organisation',
            'layout': {
                'icon-image': ['get', 'icon'],
                "icon-anchor": 'bottom',
                "icon-allow-overlap": true
            }
        })
    })
}

export function getMyCompaniyDetailSource(defaultOrgCoord: number[], name: string): any {
    return {
        'type': 'FeatureCollection',
        'features': [{
            "type": 'Feature',
            "geometry": {
                'type': 'Point',
                'coordinates': [defaultOrgCoord[0], defaultOrgCoord[1]]
            },
            'properties': {
                'title': name
            }
        }]
    }
}

export function addContractDetailLayers(map: Map, popups: Popup[]) {
    loadPickupImage(map, () => {
    })
    map.addSource('pickups', {
        'type': 'geojson',
        'data': getPickedUpSource([])
    });

    map.addLayer({
        'id': 'pickups',
        'type': 'symbol',
        'source': 'pickups',
        'layout': {
            'icon-image': 'pickup',
            "icon-anchor": 'bottom',
            "icon-allow-overlap": true
        }
    })

    map.addSource('landplot', {
        'type': 'geojson',
        'data': getHarvestsSource([])
    });

    map.addLayer({
        'id': 'landplot',
        'type': 'fill',
        'source': 'landplot', // reference the data source
        'layout': {},
        'paint': {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
        }
    });
    // Add a black outline around the polygon.
    map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'landplot',
        'layout': {},
        'paint': {
            'line-color': '#000',
            'line-width': 3
        }
    });

    map.on('click', 'landplot', e => {
        const html = `<p>${t('palm_fruit_quantity')}: ${e.features[0].properties.quantity}</p>`
        const popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(html)
        popups.push(popup)
        popup.addTo(map)
    })
}

export function updatePlots(map: Map, harvests: Harvest[]) {
    var json = getHarvestsSource(harvests);
    (map.getSource("landplot") as GeoJSONSource).setData(json);
}


export function updatePickedUps(map: Map, pickedUp: number[][]) {
    var json = getPickedUpSource(pickedUp);
    (map.getSource("pickups") as GeoJSONSource).setData(json);
}


function getPickedUpSource(pickedUp: number[][]): any {
    let pickups = []
    pickedUp.forEach((pos: number[]) => {
        pickups.push({
            'type': 'Feature',
            'properties': {
            },
            'geometry': {
                'type': 'Point',
                'coordinates': pos
            }
        })
    })
    return {
        'type': 'FeatureCollection',
        'features': pickups
    }
}


function getHarvestsSource(harvests: Harvest[]): any {
    let h = []
    harvests.forEach((harvest: Harvest) => {
        h.push({
            'type': 'Feature',
            'properties': {
                'quantity': harvest.quantity
            },
            'geometry': {
                'type': 'Polygon',
                'coordinates': harvest.land_plot.polygon.coordinates
            }
        })
    })
    return {
        'type': 'FeatureCollection',
        'features': h
    }
}

export function zoomForCoordinates(map: Map, coords: LngLat[], maxZoom: number = undefined, duration: number = undefined) {
    if (map && coords.length > 0) {
        const bounds = new LngLatBounds(
            coords[0],
            coords[0]
        );
        for (const c of coords) {
            bounds.extend(c);
        }
        var options: FitBoundsOptions = {
            padding: 20,
            duration: 0
        }
        if (maxZoom) {
            options = {
                ...options,
                maxZoom: maxZoom
            }
        }
        if (duration) {
            options = {
                ...options,
                duration: duration
            }
        }

        map.fitBounds(bounds, options)
        map.resize();
    }
}