import React from "react"
import { Harvest } from "../../models/contract/Harvest"
import { t } from "i18next"
import { area } from "@turf/turf"

type ListHarvestDetailsProps = {
    harvests: Harvest[]
}

export function ListHarvestDetails({ harvests }: ListHarvestDetailsProps) {

    type HarvestItemProps = {
        harvest: Harvest
    }

    const HarvestItem = ({ harvest }: HarvestItemProps) => {
        const data = {
            type: 'Polygon',
            coordinates: [harvest.land_plot.polygon.coordinates[0]]
        }
        const areaLP = area(data) / 10000

        return (
            <div key={harvest.land_plot.id} style={{ display: "flex", flex: "1", gap: "4px", marginTop: "4px" }}>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row" style={{ fontWeight: "700" }}>{harvest.contractId}</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row" style={{ fontWeight: "700" }}>{harvest.land_plot.externalId ?? harvest.land_plot.id}</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{harvest.farmer?.transactor?.person?.id}</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{`${areaLP.toFixed(2)} ha`}</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{harvest.getQuantityGradingDeducted()?.toFixed(0)} kg</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{harvest.ppunchTotal?.toFixed(2).IndoFormat()} rp</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{harvest.fcropTotal?.toFixed(2).IndoFormat()} rp</p>
                </div>
            </div>
        )
    }

    console.log(harvests)

    return (
        <div style={{ display: "flex", flex: "1", flexDirection: "column", marginTop: "20px" }}>
            <p className="table-label-indicator" style={{ alignSelf: "center" }}>{t('unilever_harvests')}</p>
            <div style={{ display: "flex", flex: "1", gap: "4px", marginTop: "20px" }}>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('contract_id')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('plot_id')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('farmer_id')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('area')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('quantity')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('ppunch_paid')}</p>
                </div>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title" >{t('fcrop_awarded')}</p>
                </div>
            </div>
            {harvests.sort((h1, h2) => h1.land_plot.id === h2.land_plot.id ? 0 : h1.land_plot.id < h2.land_plot.id ? -1 : 1).map(h => <HarvestItem harvest={h} />)}
        </div>
    )
}