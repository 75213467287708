import React, { useState } from "react"
import { Modal } from "../../../components/Modal"
import { Button, TextField } from "@mui/material"
import { colorPrimaryDark } from "../../../rsc/colors/colors"
import { t } from "i18next"

export function PopupEditPrice({ onSave, onDelete, onCancel, price }) {
    const [newPrice, setNewPrice] = useState(price)

    function onInputChange(value: string) {
        const re = /^[0-9]+$/

        if (value === '' || re.test(value)) {
            setNewPrice(value)
        }
    }

    return (
        <Modal onClose={onCancel}>
            <div onClick={e => e.stopPropagation()} style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "inline-block" }}>
                    <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", padding: "20px", borderRadius: "3px" }}>
                        <p style={{ display: "inline-block" }}>{t('ask_update_price')}</p>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <p style={{ display: "inline-block" }}>{t('new_price')}</p>
                            <TextField
                                variant="standard"
                                value={newPrice}
                                type='numeric'
                                inputProps={{ maxLength: 4, style: { textAlign: 'end', fontSize: "26px", fontWeight: "700", width: "60px", marginLeft: "10px" } }}
                                onChange={(e) => onInputChange(e.target.value)}
                            />
                            <p style={{ display: "inline-block", margin: "0 5px 0 5px", fontWeight: "500" }}>Rp/Kg</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                            <Button style={{ marginLeft: "10px", background: colorPrimaryDark, width: "100px" }} variant="contained" onClick={() => onSave(newPrice)}>{t('save')}</Button>
                            <Button style={{ marginLeft: "10px", background: "red", width: "100px" }} variant="contained" onClick={onDelete}>{t('delete')}</Button>
                            <Button style={{ color: colorPrimaryDark, marginLeft: "10px" }} onClick={onCancel}>{t('cancel')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


