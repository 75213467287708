import { Position, centroid, circle } from "@turf/turf";
import { TFunction, t } from "i18next";
import mapboxgl, { GeoJSONSource, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { LandPlotDashBoardModel } from "../pages/dashboard/models/LandPlotDashBoardModel";
import { colorPrimary, colorRed } from "../rsc/colors/colors";
import MillIcon from "../rsc/ic_mill.png";
import MyCompany from "../rsc/ic_my_company.png";
import MyCompanySelected from "../rsc/ic_my_company_selected.png";
import Pickup from "../rsc/ic_pickup.png";
import PickupNonUnilever from "../rsc/ic_pickup_non_unilever.png";
import NonUnileverCompany from "../rsc/non_unilever_company.png";
import { Contract } from "./contract/Contract";
import { Harvest } from "./contract/Harvest";
import { Landplot } from "./organisation/Landplot";
import { Organisation } from "./organisation/Organisation";
var shpwrite = require("@mapbox/shp-write");

export function addMillDashboard(map: Map, onLoaded: (geojson, source) => void, translation: TFunction<"translation", undefined, "translation">) {
    addMyCompaniesFeature(map, onLoaded, translation)
}

function loadCompanyImage(map: Map, block: (src?: string) => void) {
    overlayImageMyCompany(MyCompany).then((src) => {
        var img = new Image()
        img.src = src
        img.onload = function () {
            map.addImage('my_company', img, {
                pixelRatio: 1.5
            })
            overlayImageMyCompany(MyCompanySelected).then((src) => {
                var img = new Image()
                img.src = src
                img.onload = function () {
                    map.addImage('my_company_selected', img, {
                        pixelRatio: 1.5
                    })
                    overlayImageMyCompany(NonUnileverCompany).then((src) => {
                        var img = new Image()
                        img.src = src
                        img.onload = function () {
                            map.addImage('non_unilever_company', img, {
                                pixelRatio: 1.5
                            })
                            block(src)
                        }
                    })
                }
            })
        }
    })
}

function overlayImageMyCompany(companyImg: any): Promise<string> {
    var image1 = new Image();
    image1.src = companyImg;
    return new Promise<string>((resolve) => {
        image1.onload = function () {
            var image2 = new Image();
            image2.src = MillIcon;
            image2.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                canvas.width = image1.width;
                canvas.height = image1.height;
                ctx.drawImage(image1, 0, 0);
                const img2Width = image2.width / 7
                const img2Height = image2.height / 7
                ctx.drawImage(image2, (image1.width / 2) - (img2Width / 2), 18, img2Width, img2Height);
                resolve(canvas.toDataURL())
            }
        }
    })
}

function getMillsSource(organisations: Organisation[], orgSelected: Organisation): any {
    let source = []
    organisations.forEach(o => {
        const coord = o?.positionepsg4326?.coordinates
        if (coord) {
            source.push({
                "type": 'Feature',
                "geometry": {
                    'type': 'Point',
                    'coordinates': [coord[0], coord[1]]
                },
                'properties': {
                    'icon': o.id === orgSelected?.id ? "my_company_selected" : "my_company", //o.isInProgram() ? "my_company" : "non_unilever_company",
                    'org_id': o.id
                }
            })
            //     let htmlPrice = ""
            //     p.price.forEach(price => {
            //         const priceToSHow = price.price_per_quantity_unit ? `Rp ${price.price_per_quantity_unit}` : translation('no_price_set')
            //         htmlPrice += `<div style="display:flex;align-items:center;"><p style="display:inline-block;margin:0 0 0 0">${price.market_day}:</p>`
            //         htmlPrice += `<p style="font-weight:bold;display:inline-block;margin:0 0 0 5px;flex:1">${priceToSHow}</p></div>`
            //     })
            //     const html = `<div>
            // <p style="text-decoration-line: underline;text-align: center;font-weight:bold;">${translation('published_prices')}</p>  
            // ${htmlPrice}          
            // </div>`
            //     const popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, offset: 60, anchor: "bottom" }).setLngLat([coord[0], coord[1]]).setHTML(html)
            //     popups.push(popup)
            //     popup.addTo(map)
        }
    })
    return {
        'type': 'FeatureCollection',
        'features': source
    }
}

export function updateMills(map: Map, organisations: Organisation[], orgSelected: Organisation) {
    var json = getMillsSource(organisations, orgSelected);
    (map.getSource("organisation") as GeoJSONSource).setData(json);
}

function addMyCompaniesFeature(map: Map, onLoaded: (geojson, source) => void, translation: TFunction<"translation", undefined, "translation">) {
    loadCompanyImage(map, () => {
        let source = getMillsSource([], null)

        map.addSource('organisation', {
            "type": 'geojson',
            'data': source
        })
        map.addLayer({
            'id': 'organisation',
            'type': 'symbol',
            'source': 'organisation',
            'layout': {
                'icon-image': ['get', 'icon'],
                "icon-anchor": 'bottom',
                "icon-allow-overlap": true
            }
        })
        map.on('mouseenter', 'organisation', () => {
            map.getCanvas().style.cursor = 'pointer'
        })
        map.on('mouseleave', 'organisation', () => {
            map.getCanvas().style.cursor = 'grab'
        })
        onLoaded(source, "organisation")
    })
}

export function getCircleCoords(coords: any, radius: number): any {
    let points = 64;

    var km = radius;

    var ret = [];
    var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);
    return ret
}

function getLineDistanceSource(org: Organisation, coordinateTo: any, radius: number): any {

    var ret = []
    if (org && coordinateTo) {
        ret = [
            org.positionepsg4326.coordinates,
            coordinateTo
        ]
    }

    return {
        'type': 'Feature',
        'properties': {
            'distance': `${radius.toFixed(0)} Km`
        },
        'geometry': {
            'type': 'LineString',
            'coordinates': ret
        }
    }
}

function getCircleRadiusSource(org: Organisation, radius: number): any {

    var ret = []
    if (org) {
        ret = getCircleCoords({
            latitude: org.positionepsg4326.coordinates[1],
            longitude: org.positionepsg4326.coordinates[0]
        }, radius)
    }

    return {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [ret]
            },
            "properties": {
            }
        }]
    }
}

export function updateCircleRadius(map: Map, org: Organisation, radius: number, coordinateTo?: any) {
    var json = getCircleRadiusSource(org, radius);
    (map.getSource("millCircle") as GeoJSONSource).setData(json);

    if (coordinateTo) {
        var jsonLine = getLineDistanceSource(org, coordinateTo, radius);
        (map.getSource("millLineDistance") as GeoJSONSource).setData(jsonLine);
    } else {
        (map.getSource("millLineDistance") as GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: []
        });
    }
}

export function addMillCirclRadius(map: Map, organisation: Organisation) {
    map.addSource("millCircle", {
        "type": "geojson",
        "data": getCircleRadiusSource(organisation, 10)
    })

    map.addSource("millLineDistance", {
        "type": "geojson",
        "data": getLineDistanceSource(organisation, null, 10)
    })

    map.addLayer({
        "id": "millCircle",
        "type": "fill",
        "source": "millCircle",
        "layout": {},
        "paint": {
            "fill-color": "blue",
            "fill-opacity": 0.2
        }
    });
    map.addLayer({
        "id": "millLine",
        "type": "line",
        "source": "millCircle",
        "layout": {},
        "paint": {
            "line-color": "blue",
            "line-width": 3
        }
    });

    map.addLayer({
        type: 'line',
        source: 'millLineDistance',
        id: 'line-dashed',
        paint: {
            'line-color': 'white',
            'line-width': 3,
            "line-dasharray": [2, 2]
        },

    });

    map.addLayer({
        "id": "symbols",
        "type": "symbol",
        "source": "millLineDistance",
        "layout": {
            "symbol-placement": "line",
            "text-font": ["Open Sans Bold"],
            "text-field": ['get', 'distance'],
            "text-size": 20,
            "text-offset": [0, 1],
        },
        "paint": {
            "text-color": "white"
        }
    });
}

function getLandplotSource(landplots: Landplot[]): any {
    let polygons = []

    landplots.forEach((plot: Landplot) => {
        if (plot?.polygon?.coordinates) {
            polygons.push({
                'type': 'Feature',
                'properties': {
                    "plot_id": plot.id
                },
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': plot.polygon.coordinates
                }
            })
        }
    })
    return {
        "type": "FeatureCollection",
        "features": polygons
    }
}

function getCenterPolygon(coo: any): Position {
    if (coo) {
        let middle = centroid(
            {
                'type': 'Feature',
                'properties': {
                },
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': coo
                }
            }
        )
        return middle.geometry.coordinates
    } else {
        return null
    }
}

export function addMapAnnotationPlot(map: Map,
    landplot: LandPlotDashBoardModel,
    coordinates?: number[],
    harvestQuantity?: number,
    contractId?: number) {
    let html = ""
    const position = coordinates ?? landplot.landPlot?.polygon?.coordinates
    if (position) {
        let center = coordinates ? coordinates : getCenterPolygon(position)
        const quantity = harvestQuantity ? harvestQuantity : landplot?.quantity
        const fcrop = landplot?.totalFcrop
        const ppunch = landplot?.totalPpunch
        if (landplot?.isUnileverLandPlot()) {
            html += `<div>
            ${contractId ?
                    `<div style="display:flex">
            <p style="font-weight:700; margin-right:5px">${t('contract_id')}:</p>
            <p>${contractId}</p>
        </div>`
                    : ''}
        <div style="display:flex">
            <p style="font-weight:700; margin-right:5px">${t('plot_id')}:</p>
            <p>${landplot.landPlot.externalId}</p>
        </div>
        <div style="display:flex">
            <p style="font-weight:700; margin-right:5px">${t('quantity')}:</p>
            <p>${quantity.toFixed(0)} Kg</p>
        </div>
        <div style="display:flex">
            <p style="font-weight:700; margin-right:5px">FCredit:</p>
            <p>${fcrop?.toFixed(2).IndoFormat()} Rp</p>
        </div>
        <div style="display:flex">
            <p style="font-weight:700; margin-right:5px">Boost:</p>
            <p>${ppunch?.toFixed(2).IndoFormat()} Rp</p>
        </div>
    </div>`
        } else {
            html += `<div>
            ${contractId ?
                    `<div style="display:flex">
                <p style="font-weight:700; margin-right:5px">${t('contract_id')}:</p>
                <p>${contractId}</p>
            </div>`
                    : ''}
        <div style="display:flex">
            <p style="font-weight:700; margin-right:5px">${t('quantity')}:</p>
            <p>${quantity ? ` ${quantity.toFixed(0)} Kg` : "-"}</p>
        </div>
        
    </div>`

        }
        const popup = new mapboxgl.Popup({ anchor: "bottom" }).setLngLat([center[0], center[1]]).setHTML(html)
        popup.addTo(map)

    }
}

export function updateLandplotSource(map: Map, harvestsInfo: LandPlotDashBoardModel[], harvests: Harvest[]) {
    if (map && map.getSource('landplot')) {
        var json = getLandplotSource(harvestsInfo.filter(h => h.isUnileverLandPlot()).map(h => h.landPlot));
        (map.getSource("landplot") as GeoJSONSource).setData(json);
    }

    if (map && map.getSource('picked_up')) {
        var jsonPickedUp = getPickedUpSource(harvests);
        (map.getSource("picked_up") as GeoJSONSource).setData(jsonPickedUp);
    }
    if (map && map.getSource('landplot_non_unilever')) {
        var jsonUnilever = getLandplotSource(harvestsInfo.filter(h => !h.isUnileverLandPlot()).map(h => h.landPlot));
        (map.getSource("landplot_non_unilever") as GeoJSONSource).setData(jsonUnilever);
    }
}

export function addLandPlotLayer(map: Map, landplots: Landplot[], onClick: (feature: MapboxGeoJSONFeature) => void) {
    if (map.hasImage('pickup') === false) {
        var img = new Image()
        img.src = Pickup
        img.onload = function () {
            map.addImage('pickup', img, {
                pixelRatio: 5
            })
        }
    }
    if (map.hasImage('pickup_non_uni') === false) {
        var imgNonUni = new Image()
        imgNonUni.src = PickupNonUnilever
        imgNonUni.onload = function () {
            map.addImage('pickup_non_uni', imgNonUni, {
                pixelRatio: 5
            })
        }
    }

    map.addSource("landplot", {
        "type": "geojson",
        "data": getLandplotSource(landplots)
    })

    map.addSource("landplot_non_unilever", {
        "type": "geojson",
        "data": getLandplotSource(landplots)
    })

    map.addSource('picked_up', {
        type: 'geojson',
        data: getPickedUpSource([])
    });

    map.addLayer({
        "id": "picked_up",
        "type": "fill",
        "source": "picked_up",
        "layout": {},
        "paint": {
            "fill-color": colorRed,
            "fill-opacity": 0.5
        }
    });

    map.addLayer({
        "id": "picked_up_border",
        "type": "line",
        "source": "picked_up",
        "layout": {},
        "paint": {
            "line-color": colorRed,
            "line-width": 2
        }
    });

    // map.addLayer({
    //     id: 'clusters',
    //     type: 'circle',
    //     source: 'picked_up',
    //     filter: ['has', 'point_count'],
    //     paint: {
    //         // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
    //         // with three steps to implement three types of circles:
    //         //   * Blue, 20px circles when point count is less than 100
    //         //   * Yellow, 30px circles when point count is between 100 and 750
    //         //   * Pink, 40px circles when point count is greater than or equal to 750
    //         'circle-color': [
    //             'step',
    //             ['get', 'point_count'],
    //             colorRed,
    //             15,
    //             colorRed,
    //             50,
    //             colorRed
    //         ],
    //         'circle-radius': [
    //             'step',
    //             ['get', 'point_count'],
    //             20,
    //             15,
    //             30,
    //             50,
    //             40
    //         ]
    //     }
    // });

    // map.addLayer({
    //     id: 'cluster-count',
    //     type: 'symbol',
    //     source: 'picked_up',
    //     filter: ['has', 'point_count'],
    //     layout: {
    //         "text-allow-overlap": true,
    //         'text-field': ['get', 'point_count_abbreviated'],
    //         'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
    //         'text-size': 12
    //     },
    //     paint: {
    //         'text-color': "#fff"
    //     }
    // });

    // map.addLayer({
    //     id: 'unclustered-point',
    //     type: 'symbol',
    //     source: 'picked_up',
    //     filter: ['!', ['has', 'point_count']],
    //     'layout': {
    //         'icon-image': ['get', 'icon'],
    //         "icon-anchor": 'bottom',
    //         "icon-allow-overlap": true,
    //         "icon-size": ['interpolate', ['linear'], ['zoom'], 6, 0.1, 7, 0.2, 8, 0.3, 9, 0.4, 10, 0.5, 11, 0.6, 12, 0.7, 13, 0.8, 14, 0.9, 15, 1]
    //     }

    // });


    map.addLayer({
        "id": "landplot",
        "type": "fill",
        "source": "landplot",
        "layout": {},
        "paint": {
            "fill-color": colorPrimary,
            "fill-opacity": 0.2
        }
    });
    map.addLayer({
        "id": "landplotLine",
        "type": "line",
        "source": "landplot",
        "layout": {},
        "paint": {
            "line-color": colorPrimary,
            "line-width": 3
        }
    });

    // map.addLayer({
    //     'id': 'picked_up',
    //     'type': 'symbol',
    //     'source': 'picked_up',
    //     'layout': {
    //         'icon-image': ['get', 'icon'],
    //         "icon-anchor": 'bottom',
    //         "icon-allow-overlap": true
    //     }
    // })

    map.addLayer({
        "id": "landplot_non_unilever",
        "type": "fill",
        "source": "landplot_non_unilever",
        "layout": {},
        "paint": {
            "fill-color": "red",
            "fill-opacity": 0.2
        }
    });
    map.addLayer({
        "id": "landplotLine_unilever",
        "type": "line",
        "source": "landplot_non_unilever",
        "layout": {},
        "paint": {
            "line-color": "red",
            "line-width": 3
        }
    });

    map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        (map.getSource('picked_up') as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;
                map.easeTo({
                    center: (features[0].geometry as any).coordinates,
                    zoom: zoom
                });
            }
        );
    });

    map.on("click", ["landplot", 'landplot_non_unilever', 'picked_up'], (ev) => {
        onClick(ev.features[0])
    })

    map.on('mouseenter', ['landplot', 'landplot_non_unilever', 'picked_up'], () => {
        map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', ['landplot', 'landplot_non_unilever', 'picked_up'], () => {
        map.getCanvas().style.cursor = 'grab'
    })

    map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
    })
}

function getPickedUpSource(harvest: Harvest[]): any {
    let pickups = []
    harvest.filter(h => h.pickedUpPosition).forEach((h: Harvest) => {
        var center = [h.pickedUpPosition[0], h.pickedUpPosition[1]];
        var radius = 0.05;
        var options = {
            steps: 50, properties: {
                'icon': h.isUnileverLandPlot() ? 'pickup' : 'pickup_non_uni',
                "plot_id": h.land_plot.id,
                "coordinates": h.pickedUpPosition,
                "quantity": h.getQuantityGradingDeducted(),
                "contract_id": h.contractId

            }
        };
        var circleFeature = circle(center, radius, options);
        pickups.push(circleFeature)
    })
    return {
        'type': 'FeatureCollection',
        'features': pickups
    }
}

function generatedShapeGeoPlot(plots: LandPlotDashBoardModel[]) {
    let polygons = []

    plots.filter(p => p.landPlot?.polygon?.coordinates).forEach((p: LandPlotDashBoardModel) => {
        const name = p.landPlot.externalId ?? (`${p.landPlot.id} (Pempem)`)
        polygons.push({
            type: "Feature",
            properties: {
                name: name,
                id: name,
                quantity: `${p.quantity.IndoFormat()}Kg`
            },
            geometry: {
                type: "Polygon",
                coordinates: p.landPlot.polygon.coordinates
            }
        })
    })
    return polygons
}

function generatedShapeGeoPickedUp(pickedUps: number[][]) {
    let points = []

    pickedUps.forEach((pos: number[]) => {
        points.push({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: pos
            }
        })
    })
    return points
}


export function generateGeoJSONFromContracts(contracts: Contract[], landplots: LandPlotDashBoardModel[]) {
    const plotPlotFeature = generatedShapeGeoPlot(landplots.filter(p => p.isUnileverLandPlot()))
    const pickedUp = landplots.filter(p => !p.isUnileverLandPlot()).flatMap(p => p.harvests).map(h => h.pickedUpPosition)
    const pickedUpFeature = generatedShapeGeoPickedUp(pickedUp)
    const geo = {
        type: "FeatureCollection",
        features: [
            ...plotPlotFeature,
            ...pickedUpFeature
        ]
    }

    const options = {
        folder: "shape_folder",
        filename: "shape_file_report",
        outputType: "blob",
        compression: "DEFLATE",
        types: {
            point: "picked_up",
            polygon: "plots",
        },
    };

    shpwrite.download(
        geo,
        options
    );
}

export function generateGeoJSONFromContractsList(contracts: Contract[], landplots: LandPlotDashBoardModel[], pickedUp: number[][]): any {
    const plotPlotFeature = generatedShapeGeoPlot(landplots)
    const pickedUpFeature = generatedShapeGeoPickedUp(pickedUp)

    const options = {
        folder: "shape_files",
        filename: "shape",
        types: {
            point: "picked_up_points",
            polygon: "land_plots",
        },
    };

    const zipData = shpwrite.zip(
        {
            type: "FeatureCollection",
            features: [
                ...plotPlotFeature,
                ...pickedUpFeature
            ]
        },
        options
    );
    return zipData
}
