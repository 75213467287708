import { Contract } from "../models/contract/Contract"
import { Harvest } from "../models/contract/Harvest"
import { Person } from "../models/person/Person"
import { LandPlotDashBoardModel } from "../pages/dashboard/models/LandPlotDashBoardModel"

export const fruitQuantity = (contracts: Contract[]) => {
    return contracts.reduce((v, cc) => v + cc.getFruitQuantity(), 0)
}

export const fruitUnileverPlotQuantity = (contracts: Contract[]) => {
    return contracts.reduce((v, cc) => v + cc.getUnileverFruitQuantity(), 0)
}

export const fruitNonUnileverPlotQuantity = (contracts: Contract[]) => {
    return contracts.reduce((v, cc) => v + cc.getNonUnileverFruitQuantity(), 0)
}

export const totalUnileverFarmers = (contracts: Contract[]) => {
    return new Set(contracts.flatMap((c) => c.getUnileverFarmerPersonIds())).size
}


export const totalNonUnileverFarmers = (contracts: Contract[]) => {
    return new Set(contracts.flatMap((c) => c.getNonUnileverFarmerPersonIds())).size
}

export const allFarmers = (contracts: Contract[]) => {
    return new Set(contracts.flatMap((c) => c.getFarmerPersonIds()))
}

export const allFarmersDeclared = (contracts: Contract[]) => {
    const map = new Map()
    const farmers = contracts.flatMap((c) => c.getFarmersDeclared())
    farmers.forEach(f => {
        map.set(f.id, f)
    })
    return map
}

export const allFarmersUndeclared = (contracts: Contract[], farmersDeclared: Map<number, Person>): Map<number, Person> => {
    const map = new Map()
    const farmers = contracts.flatMap((c) => c.getFarmersUndeclared())
    farmers.forEach(f => {
        if (farmersDeclared.get(f.id) === undefined) {
            map.set(f.id, f)
        }
    })
    return map
}

export function generatePlotInfo(contracts: Contract[]): LandPlotDashBoardModel[] {
    const harvests = contracts.flatMap(c => {
        return c.getHarvests()
    })
    const plotSorted: Map<number, LandPlotDashBoardModel> = new Map()
    harvests.forEach(h => {
        var plotAggregate = plotSorted.get(h.land_plot.id)
        if (plotAggregate) {
            plotAggregate.addInfo(h)
        } else {
            plotAggregate = new LandPlotDashBoardModel(h.land_plot)
            plotAggregate.addInfo(h)
        }
        plotSorted.set(h.land_plot.id, plotAggregate)
    })
    return [...plotSorted.values()]
}

export function generatePlotInfoContractList(contracts: Contract[]): LandPlotDashBoardModel[] {
    const harvests = contracts.flatMap(c => {
        return c.getHarvests()
    })
    const plotSorted: Map<number, LandPlotDashBoardModel> = new Map()
    harvests.forEach(h => {
        var plotAggregate = plotSorted.get(h.land_plot.id)
        if (plotAggregate) {
            plotAggregate.addInfoExport(h)
        } else {
            plotAggregate = new LandPlotDashBoardModel(h.land_plot)
            plotAggregate.addInfoExport(h)
        }
        plotSorted.set(h.land_plot.id, plotAggregate)
    })
    return [...plotSorted.values()]
}

export const totalUnileverContracts = (contracts: Contract[]): number => {
    return contracts.filter(c => c.isUnileverContract()).length
}

export const totalAmount = (contracts: Contract[]) => {
    return contracts.map(c => c.getTotalNetRp()).reduce((v, c) => v + c, 0)
}

export const totalFcrop = (contracts: Contract[]): number => {
    return contracts.reduce((v, c) => v + c.getFcrop(), 0)
}

export const totalPpunch = (contracts: Contract[]): number => {
    return contracts.reduce((v, c) => v + c.getPpunch(), 0)
}

export const getUnileverHarvests = (contracts: Contract[]): Harvest[] => {
    return contracts.flatMap(c => c.getUnileverHarvest())
}

export const getHarvests = (contracts: Contract[]): Harvest[] => {
    return contracts.flatMap(c => c.harvests.map(h => h.harvest))
}

export const averageFcrop = (contracts: Contract[]): number => {
    const harvests = contracts.flatMap(c => c.getUnileverHarvest().filter(h => h.isFcroped()))
    if (harvests.length > 0) {
        return contracts.reduce((v, c) => v + c.getFcrop(), 0) / harvests.length
    } else {
        return 0
    }
}

export const percentPpunchOnGMV = (contracts: Contract[]): number => {
    const totalGross = contracts.filter(c => c.isContractPpunched()).reduce((v, c) => v + c.getTotalNetRp(), 0)
    const totalPp = totalPpunch(contracts)
    if (totalPp > 0 && totalGross > 0) {
        return totalPp / totalGross
    }
    return 0
}

export const fruitTracability = (contracts: Contract[]): number => {
    const nonUniQuantity = contracts.reduce((v, c) => v + (c.getNonUnileverFruitQuantity() ?? 0), 0)
    const uniQuantity = contracts.reduce((v, c) => v + (c.getUnileverFruitQuantity() ?? 0), 0)
    const total = nonUniQuantity + uniQuantity
    if (uniQuantity === 0) return undefined
    return uniQuantity / total
}