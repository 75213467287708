export class SaleItem {

    quantity: number
    price_per_unit: number
    sku: string
    createdAt: string
    constructor(quantity: number, price_per_unit: number, sku: string, createdAt: string) {
        this.sku = sku
        this.price_per_unit = price_per_unit
        this.quantity = quantity
        this.createdAt = createdAt
    }

    getTotal(): number {
        return this.quantity*this.price_per_unit
    }
}

export function buildSaleItem(s): SaleItem {
    return new SaleItem(s.quantity, s.price_per_unit, s.product.sku, s.created_at)
}