import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserTokenModel } from '../models/UserTokenModel'

export type MessageType = {
  message: any,
  color: string
}

export type LogState = {
  LoggedIn: boolean,
  forceRedirectLogin: boolean
}

export type AdminInfo = {
  dashboardEnable: boolean,
  hasMill: boolean
}

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    value: undefined
  },
  reducers: {
    updateAdmin: (state, action: PayloadAction<AdminInfo>) => {
      state.value = action.payload
    },
    logout: state => {
      state.value = null
    }
  }
})


const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    value: undefined
  },
  reducers: {
    updateToken: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    logout: state => {
      state.value = null
    }
  }
})

const ErrorState: MessageType = null

const errorMessageSlice = createSlice({
  name: 'error_message',
  initialState: {
    value: ErrorState
  },
  reducers: {
    setErrorMessageAction: (state, action: PayloadAction<MessageType>) => {
      state.value = action.payload
    }
  }
})

const pathSlice = createSlice({
  name: 'path',
  initialState: {
    value: ''
  },
  reducers: {
    setPath: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    }
  }
})

const logState: LogState = null

const isLoggedInSlice = createSlice({
  name: 'path',
  initialState: {
    value: logState
  },
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<LogState|null>) => {
      state.value = action.payload
    }
  }
})

const userSlice = createSlice({
  name: 'path',
  initialState: {
    value: undefined
  },
  reducers: {
    setUser: (state, action: PayloadAction<UserTokenModel|null>) => {
      state.value = action.payload
    }
  }
})

export type MenuState = {
  isMobile: boolean,
  isMenuOpen: boolean
}

const menuState: MenuState = {
  isMobile: false,
  isMenuOpen: false
}

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    value: menuState
  },
  reducers: {
    setMenuState: (state, action: PayloadAction<MenuState|null>) => {
      state.value = action.payload
    }
  }
})

export const {updateToken, logout} = tokenSlice.actions
export const {setErrorMessageAction} = errorMessageSlice.actions
export const {setPath} = pathSlice.actions
export const {setIsLoggedIn} = isLoggedInSlice.actions
export const {updateAdmin} = adminSlice.actions
export const {setUser} = userSlice.actions
export const {setMenuState} = menuSlice.actions

export const NFMStore = configureStore({
  reducer: {
    token: tokenSlice.reducer,
    errorMessage: errorMessageSlice.reducer,
    path: pathSlice.reducer,
    isLoggedIn: isLoggedInSlice.reducer,
    admin: adminSlice.reducer,
    user: userSlice.reducer,
    menu: menuSlice.reducer
  }
})