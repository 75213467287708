import React from "react"

export function EnterLayout({ children, onClick}) {
    return (
        <div onKeyDown={(e) => {
            if (e.key === "Enter") {
                onClick()
            }
            e.stopPropagation()
        }}>
            {children}
        </div>
    )
}