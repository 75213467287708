import { t } from "i18next"
import React from "react"
import { Person } from "../../models/person/Person"

type Props = {
    persons: Person[]
}

export function ListUndeclaredPlots({ persons }: Props) {

    type HarvestItemProps = {
        person: Person
    }

    const PersonItem = ({ person }: HarvestItemProps) => {
        return (
            <div key={person.id} style={{ display: "flex", flex: "1", gap: "4px", marginTop: "4px" }}>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row" style={{ fontWeight: "700" }}>{person.id}</p>
                </div>
            </div>
        )
    }


    return (
        <div style={{ display: "flex", flex: "1", flexDirection: "column", marginTop: "20px", width:"300px" }}>
            <p className="table-label-indicator" style={{ alignSelf: "center" }}>{t('farmers')}</p>
            <div style={{ display: "flex", flex: "1", gap: "4px", marginTop: "20px" }}>
                <div style={{ flex: "2" }} className="table-header-dashboard">
                    <p className="table-header-dashboard-title">{t('id')}</p>
                </div>
            </div>
            {persons.sort((p1, p2) => p1.id === p2.id ? 0 : p1.id < p2.id ? -1 : 1).map(h => <PersonItem person={h} />)}
        </div>
    )
}