import { Map, MapboxGeoJSONFeature } from "mapbox-gl";

export class MapEventDashBoard {
    onClickMill: (id: number) => void
    coords:number[]
    source:string
    map: Map
    onFeatureClick = (feature: MapboxGeoJSONFeature) => {
        const orgId = feature.properties['org_id']
        if (orgId !== undefined && orgId !== null) {
            this.onClickMill(parseInt(orgId))
        }
        // this.geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
        // (this.map.getSource(this.source) as GeoJSONSource).setData(this.geojson)
        // this.coords = [coords.lng, coords.lat]
    }
    geojson: any

    constructor(map: Map, geojson: any, source:string, onClickMill: (id: number) => void) {
        this.map = map
        this.geojson = geojson
        this.source = source
        this.onClickMill = onClickMill
    }
}