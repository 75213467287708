import { SaleStatement, buildSaleStatement } from "./SaleStatement";


export class ContractSaleStatement {
    id?: number
    sale_statement: SaleStatement
    party?: string
    marketDay?: string

    constructor(sale_statement: SaleStatement, party?: string, market_day?: string, id?: number) {
        this.sale_statement = sale_statement
        this.party = party
        this.marketDay = market_day
        this.id = id
    }
}

export function buildContractSaleStatementForDetail(css: any): ContractSaleStatement {
    if (css.sale_statement) {
        return new ContractSaleStatement(buildSaleStatement(css.sale_statement), css.party, css.market_day, css.sale_statement_id)
    }
    return null
}

export function buildContractSaleStatementFromSale(css: any): ContractSaleStatement {
    return new ContractSaleStatement(buildSaleStatement(css), css.party, css.market_day)
}