import { LoadingButton } from "@mui/lab"
import { TextField } from "@mui/material"
import { t } from "i18next"
import React, { useState } from "react"
import { EnterLayout } from "../../../components/EnterLayout"
import { DATE_PATTERN_HH_mm_DD_MMM, convertToDateWithUtc } from "../../../helper/helper"
import { Organisation } from "../../../models/organisation/Organisation"
import { colorPrimary, colorPrimaryDark } from "../../../rsc/colors/colors"
import { PopupEditPrice } from "./PopupEditPrice"
import { BidModel } from "../../../models/bid/BidModel"

type RowPriceProps = {
    org: Organisation
    price: BidModel
    setPrice: (price: BidModel) => void
    isEditable: (org: Organisation, price: BidModel) => boolean
    getErrorReason: (org: Organisation) => string | null
    onValidatePrice: (price: BidModel, org: Organisation, value: number) => Promise<BidModel>
    onDeletePrice: (price: BidModel, org: Organisation) => Promise<BidModel>
}

export function PriceRow(props: RowPriceProps) {
    const [newPrice, setNewPrice] = useState(props.price?.price_per_quantity_unit?.toString() ?? "")
    const [onPriceError, setOnPriceError] = useState(false)
    const [loading, setLoading] = React.useState(false)
    const [displayPopup, setDisplayPopup] = useState(false)

    function isPriceSet(): boolean {
        return props.price?.price_per_quantity_unit !== undefined && props.price?.price_per_quantity_unit !== null
    }

    function onSave(newPrice: string) {
        onCancel()
        let p = parseInt(newPrice)
        if (p) {
            setLoading(true)
            setOnPriceError(false)
            props.onValidatePrice(props.price, props.org, p).then((p) => {
                setLoading(false)
                if (p) {
                    props.setPrice(p)
                    setNewPrice(p?.price_per_quantity_unit?.toString() ?? "")
                }
            }, error => {
                setLoading(false)
            })
        } else {
            setOnPriceError(true)
        }
    }

    function onCancel() {
        setDisplayPopup(false)
    }
    function onDelete() {
        onCancel()
        setLoading(true)
        props.onDeletePrice(props.price, props.org)
            .then((price) => {
                setNewPrice(price?.price_per_quantity_unit?.toString() ?? "")
                props.setPrice(price)
                setLoading(false)
            }, error => {
                setLoading(false)
            })
    }

    function getStyleForButton(): React.CSSProperties {
        if (!props.isEditable(props.org, props.price)) {
            return {
                backgroundColor: "#F5F5F5",
                color: "black"
            }
        }

        if (loading) {
            return {
                backgroundColor: "grey",
                color: "grey"
            }
        } else {
            return {
                backgroundColor: isPriceSet() ? colorPrimaryDark : colorPrimary,
                color: "white"
            }
        }
    }

    function onInputChange(value: string) {
        const re = /^[0-9]+$/

        if (value === '' || re.test(value)) {
            setNewPrice(value)
        }
    }

    function getPriceLayout() {
        if (!isPriceSet()) {
            return (<div style={{ display: "flex", alignItems: "center" }}>
                <p className='greyColor' style={{ display: "inline-block", margin: "0 5px 0 0", fontWeight: "500" }}>Rp/Kg</p>
                <TextField
                    variant="standard"
                    disabled={!props.isEditable(props.org, props.price)}
                    value={newPrice}
                    error={onPriceError}
                    type='numeric'
                    inputProps={{ maxLength: 4, style: { textAlign: 'end', fontSize: "26px", fontWeight: "700", width: "60px", maxWidth: "5ch" } }}
                    onChange={(e) => onInputChange(e.target.value)}
                />
            </div>)
        } else {
            return <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ display: "inline-block", margin: "0" }}>Rp/Kg</p>
                <p style={{ display: "inline-block", margin: "0 0 0 5px", textAlign: 'end', fontSize: "26px", fontWeight: "700" }}>{props.price.price_per_quantity_unit}</p>
            </div>
        }
    }

    return (
        <EnterLayout onClick={() => {
            if (isPriceSet()) {
                setDisplayPopup(true)
            } else {
                onSave(newPrice)
            }
        }}>
            <div style={{ display: "flex", alignItems: "center", marginTop: "10px", paddingLeft: "10px" }}>
                <p style={{ marginRight: "20px", flexShrink: "0" }}>{props.price.market_day}</p>
                {isPriceSet() ?
                    <p className='primaryColor' style={{ marginRight: "10px", flex: "1", textAlign: "center" }}>{t('price_setter_name', { name: props.price.updated_by, date: convertToDateWithUtc(props.price.updated_at, DATE_PATTERN_HH_mm_DD_MMM) })}</p>
                    : <div style={{ flex: "1" }}></div>}
                <div style={{ display: "flex", justifyContent: "end", flexDirection: "row", alignItems: "center" }}>
                    {getPriceLayout()}
                    {
                        displayPopup ? <PopupEditPrice price={newPrice} onSave={(np) => onSave(np)} onDelete={onDelete} onCancel={onCancel} />
                            : <></>
                    }
                    {
                        isPriceSet() ?
                            <LoadingButton
                                disabled={!props.isEditable(props.org, props.price)}
                                style={{ marginLeft: "10px", width: "100px", ...getStyleForButton() }}
                                variant="contained"
                                loading={loading}
                                onClick={() => {
                                    setDisplayPopup(true)
                                }}>
                                {t('edit')}
                            </LoadingButton>
                            : <LoadingButton
                                disabled={!props.isEditable(props.org, props.price)}
                                style={{ marginLeft: "10px", width: "100px", ...getStyleForButton() }}
                                variant="contained"
                                loading={loading}
                                onClick={() => onSave(newPrice)}>
                                {t('save')}
                            </LoadingButton>
                    }
                </div>
            </div>
        </EnterLayout>
    )
}
