import { Location, buildLocationFromDetail } from "../organisation/Location"
import { Transactor, buildTransactorFromDetail } from "./Transactor"
import { User, buildUserFromOrgDetail } from "./User"

export class Person {
    id: number
    firstname: string
    lastname: string
    user?: User
    location?: Location
    transactor?: Transactor

    constructor(id: number, firstname: string = "", lastname: string = "", user?: User, location?: Location,
    transactor?: Transactor) {
        this.id = id
        this.firstname = firstname
        this.lastname = lastname
        this.user = user
        this.location = location
        this.transactor = transactor
    }

    fullName(): string {
        let name: string = this.firstname
        if (this.lastname) {
            name = `${name} ${this.lastname}`
        }
        return name
    }
}

export function buildPersonFromOrgDetail(p: any): Person {
    return new Person(p.id,
        p.firstname,
        p.lastname,
        p.user ? buildUserFromOrgDetail(p.user): null,
        p.location ? buildLocationFromDetail(p.location): null,
        p.transactor ? buildTransactorFromDetail(p.transactor) : null)
}

export function buildPersonForProfile(p: any): Person {
    return new Person(p.id,
        p.firstname,
        p.lastname,
        p.user ? buildUserFromOrgDetail(p.user): null,
        p.location ? buildLocationFromDetail(p.location): null,
        p.transactor ? buildTransactorFromDetail(p.transactor) : null)
}