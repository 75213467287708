import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import './helper/extensions';
import { getLocalStorageToken, getToken } from './helper/TokenHelper';
import './i18n';
import './index.scss';
import './pages/login/firebaseInit';
import { firebaseAuth } from './pages/login/firebaseInit';
import RoutesPempem from './pages/RoutesPempem';
import reportWebVitals from './reportWebVitals';
import { NFMStore } from './stores/store';

require('dayjs/locale/id')

// Needed to use utc plugin
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BASE_URL });

const linkTokenHeader = setContext(async (_, { headers }) => {
  var accessToken
  if (headers?.token) {
    accessToken = headers.token
  } else {
    accessToken = await getAccessTokenPromise()
  }
  return {
    headers: {
      Authorization: "Bearer " + accessToken,
      "x-hasura-role": headers?.role ?? "user",
      "x-app-name": "NFM"
    }
  }
})

export function getAccessTokenPromise() {
  var token = getToken()
  const currentNumericDate = Math.round(Date.now() / 1000)

  if (token && currentNumericDate + 1 * 60 <= token.decode.exp) {
    return new Promise(resolve => resolve(token.token))
  } else {
    return new Promise(resolve => {
      if (firebaseAuth().currentUser) {
        var token = firebaseAuth().currentUser.getIdToken()
        resolve(token)
      } else if (getLocalStorageToken()) {
        resolve(getLocalStorageToken())
      } else {
        resolve(null)
      }
    })
  }
}

const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.forEach(({ message }) => console.log(message))
  if (networkError) {
    console.log(networkError)
  }
}

const linkError = onError(errorHandler)

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = new ApolloClient({
  link: ApolloLink.from([linkError, linkTokenHeader, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={NFMStore}>
      <BrowserRouter >
        <ApolloProvider client={client}>
          <RoutesPempem client={client} />
        </ApolloProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();