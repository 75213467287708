import { Person } from "../person/Person"
import { Transactor, buildTransactorFromDetail } from "../person/Transactor"
import { User } from "../person/User"

export class Employee {
    transactor_id: number
    transactor: Transactor
    constructor(id: number, tr: Transactor) {
        this.transactor_id = id
        this.transactor = tr
    }
}

export function buildEmployeeFromDetail(e: any): Employee {
    return new Employee(e.transactor_id,
        buildTransactorFromDetail(e.transactor))
}

export function buildEmployeeFromInvitedUser(e: any): Employee {
    return new Employee(-1, new Transactor(-1, new Person(-1, e.firstname, e.lastname, new User(null, e.email))))
}