import { TFunction } from "i18next"
import { convertToDate, toIndoCurrency } from "../../helper/helper"

const PREPARING_PAYMENT = "preparing_payment"
const PAYEE_ACCOUNT_INACTIVE = "account_inactive"
const PAYEE_ACCOUNT_LIMIT_EXCEEDED = "account_limit_exceeded"
const WAITING_FUNDS = "waiting_funds"
const PAYING_FAILURE_WAITING = "waiting_funds_failure"
const PAYING_IN_PROGRESS = "paying"
const PAYING_SUCCESS = "paying_success"
const PAYING_FAILURE = "paying_failure"

export class Payment {
    id: number
    total: number
    amount: number
    paid_at: string
    status: string

    constructor(id: number,
        total: number,
        amount: number,
        paid_at: string,
        status: string) {
        this.id = id
        this.total = total
        this.amount = amount
        this.paid_at = paid_at
        this.status = status
    }

    getAmount() {
        return toIndoCurrency(this.amount)
    }

    getPaymentStatus(t: TFunction) {
        return t(this.status)
    }

    isPaymentSucceed() {
        return this.status === PAYING_SUCCESS
    }

    isPaymentPending() {
        return this.status === PAYING_IN_PROGRESS || this.status === WAITING_FUNDS || this.status === PREPARING_PAYMENT
    }

    getPaymentDate(): string|null {
        if (this.paid_at) {
            return convertToDate(this.paid_at, "DD MMMM YYYY")
        } else {
            return null
        }
    }


    isPaymentError() {
        return this.status === PAYEE_ACCOUNT_INACTIVE
            || this.status === PAYEE_ACCOUNT_LIMIT_EXCEEDED
            || this.status === PAYING_FAILURE
            || this.status === PAYING_FAILURE_WAITING
    }
}

export function buildPaymentForContractDetail(p: any): Payment {
    return new Payment(p.id, p.total, p.amount, p.paid_at, p.status)
}