import { toIndoFormat } from "./helper";

declare global {
    interface String {
        IndoFormat(maxDigit?: number): string;
        isEmpty(): boolean;
        isNullOrEmpty(): boolean;
    }

    interface Number {
        IndoFormat(maxDigit?: number): string;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.IndoFormat = function (this: string, maxDigit: number = 0) {
    return toIndoFormat(parseInt(this), maxDigit)
};

// eslint-disable-next-line no-extend-native
String.prototype.isEmpty = function (this: string) {
    if (this === null || this === undefined || this?.length === 0) {
        return true
    }
    return false
};

// eslint-disable-next-line no-extend-native
String.prototype.isNullOrEmpty = function (this: string) {
    if (this === null || this === undefined || this?.length === 0) {
        return true
    }
    return false
};


// eslint-disable-next-line no-extend-native
Number.prototype.IndoFormat = function (this: number, maxDigit: number = 0) {
    return toIndoFormat(this, maxDigit)
};

export { }