import { Employee, buildEmployeeFromDetail } from "./Employee"
import { Landplot, buildLandPlot } from "./Landplot"
import { Location, buildLocationFromDetail } from "./Location"
import { TaxInformationModel, buildTaxInformation } from "./TaxInformationModel"

export class Organisation {
    id: number
    organisation_type: string
    positionepsg4326?: any
    reputation_level: string
    location?: Location
    name: string
    taxInformations?: TaxInformationModel
    employees?: Employee[]
    isUnileverMill: boolean
    landplots?: Landplot[]
    affiliate_reward_program

    constructor(id: number, organisation_type?: string, reputation_level?: string, name?: string, location?: Location, positionepsg4326?: any,
        taxInformations?: TaxInformationModel, employees?: Employee[],
        isUnileverMill?: boolean, landplots?: Landplot[], affiliate_reward_program?: string) {
        this.id = id
        this.organisation_type = organisation_type
        this.positionepsg4326 = positionepsg4326
        this.reputation_level = reputation_level
        this.location = location
        this.name = name
        this.taxInformations = taxInformations
        this.employees = employees
        this.isUnileverMill = isUnileverMill
        this.landplots = landplots
        this.affiliate_reward_program = affiliate_reward_program
    }

    hasPosition(): boolean {
        return this.positionepsg4326 !== null && this.positionepsg4326 !== undefined
    }

    isMill(): boolean {
        return this.organisation_type === "mill"
    }

    isInProgram() {
        return this.affiliate_reward_program !== undefined && this.affiliate_reward_program !== null
    }
}

export function buildOrganisationFromDetail(organisation: any): Organisation {
    return new Organisation(organisation.id,
        organisation?.organisation_type,
        organisation?.reputation_level,
        organisation?.name,
        organisation.location ? buildLocationFromDetail(organisation.location) : undefined,
        organisation?.positionepsg4326,
        buildTaxInformation(organisation),
        organisation.employees?.map((e) => buildEmployeeFromDetail(e)),
        organisation.affiliate_reward_program !== undefined,
        organisation.land_plots?.map(l => buildLandPlot(l)),
        organisation?.affiliate_reward_program
    )
}