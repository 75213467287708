export class MarketTurn {
    startAt: string
    endAt: string
    open: boolean

    constructor(startAt: string,
        endAt: string,
        open: boolean) {
            this.startAt = startAt
            this.endAt = endAt
            this.open = open
    }
}

export function buildMarketTurn(mt: any): MarketTurn|undefined {
    if (mt) {
        return new MarketTurn(mt.startAt, mt.endAt, mt.open)
    }
    return undefined
}