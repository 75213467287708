import axios from "axios"
import React, { useEffect, useState } from "react"
import { Loader } from "../../components/Loader"
import { InvoiceREST } from "./PempemInvoiceList"
import { generateInvoicePDF } from "./generateInvoicePDF"
import { useSearchParams } from "react-router-dom"

type InvoiceDetailProps = {
    invoice: InvoiceREST
    onErrorLoading: (orgId: string) => void
}

export function PempemInvoiceDetail({ invoice, onErrorLoading }: InvoiceDetailProps) {

    const [param] = useSearchParams()
    const pdfUrlParam = param.get("pdfUrl")
    const orgId = param.get("orgId")

    const [pdfUrl, setPdfUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        const url = invoice?.url ?? decodeURIComponent(pdfUrlParam)
        console.log('expired url', url)
        axios.get(url, {
            withCredentials: false,
        }).then((data) => {
            generateInvoicePDF(data.data as any, (pdf) => {
                pdf.getDataUrl(doc => {
                    setPdfUrl(doc)
                })
            })
        }).catch(e => {
            onErrorLoading(orgId)
        })
            .finally(() => {
                setIsLoading(false)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<div style={{ height: '100%', border: '1px solid black' }}>
        {pdfUrl ? (
            <iframe
                title="PDF renderer"
                src={pdfUrl}
                style={{ width: '100%', height: '100%' }}
                frameBorder="0"
            ></iframe>
        ) : <Loader isLoading={isLoading} />}
    </div>)
}