import { CircularProgress } from "@mui/material"
import React from "react"

type LoaderProps = {
    isLoading: boolean
}

export function Loader({isLoading}: LoaderProps) {
    return (
        <div style={{
            display: isLoading === true ? "block" : "none",
            color: "black",
            fontWeight: "initial",
            fontSize: "initial",
            cursor: "default",
            position: "fixed",
            zIndex: "120",
            top: "0",
            right: "0",
            left: "0",
            bottom: "0",
            backgroundColor: "#ffffffaa"
        }}>
            <div style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
            }}>
                <div style={{boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
            padding:"20px", background:"#fff"}}>
                    <CircularProgress />
                </div>
            </div>
        </div>
    )
}