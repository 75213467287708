export class MapCount {

    map: Map<String, MapCount> = new Map()
    count: number = 0

    getOrCreate(key: string): MapCount {
        const loc = this.map.get(key)
        if (loc != null) {
            loc.count += 1
            return loc
        } else {
            const newLoc = new MapCount()
            newLoc.count = 1
            this.map.set(key, newLoc)
            return this.map.get(key)
        }
    }
}