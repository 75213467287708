import { Button, Dialog, DialogContent, List } from "@mui/material"
import { t } from "i18next"
import React, { CSSProperties, useEffect, useState } from "react"
import { averageFcrop, fruitNonUnileverPlotQuantity, fruitUnileverPlotQuantity, generatePlotInfo, getHarvests, percentPpunchOnGMV, totalFcrop, totalPpunch, totalUnileverFarmers } from "../../helper/contractHelper"
import { Contract } from "../../models/contract/Contract"
import { Organisation } from "../../models/organisation/Organisation"
import { colorPrimary } from "../../rsc/colors/colors"
import { ReactComponent as MillIcon } from "../../rsc/ic_mill.svg"
import { ListHarvestDetails } from "./ListHarvestDetails"

type DashboardListProps = {
    organisations: Organisation[],
    contracts: Contract[],
    downloadSHP: () => void
}

type DashboardListItemProps = {
    org: Organisation,
    // setSelectedOrg: React.Dispatch<React.SetStateAction<Organisation>>
    contracts: Contract[]
}

function DashboardOrganisationItem({ org, contracts }: DashboardListItemProps) {

    const [showLandPLots, setShowLoandPlots] = useState(false)

    const boxStyle: CSSProperties = {
        flex: "1",
        display: "flex"
    }

    const boxStyle2: CSSProperties = {
        flex: "2",
        display: "flex"
    }

    const plotsInfo = generatePlotInfo(contracts)
    
    return (
        <div style={{ margin: "0px 0px 30px 0px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <MillIcon width="30px" height="30px" />
                <p style={{ flex: 1, lineHeight: "normal", fontWeight: "700", paddingTop: "4px" }}>{org?.name}</p>
            </div>
            <div style={{ display: "flex", gap: "3px", margin: "10px 0px", alignItems: "stretch" }}>
                <div style={boxStyle}>
                    <Button className="sidebar-section-container" onClick={() => setShowLoandPlots(true)} style={{ backgroundColor: "white", borderRadius: "0px" }}>
                        <p className="sidebar-section-title">{t('landplot_dashboard_column')}</p>
                        <p className="sidebar-number primaryColor" style={{ marginTop: "4px" }}>{plotsInfo.filter(p => p.isUnileverLandPlot()).length}</p>
                    </Button>
                </div>
                <div style={boxStyle}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('farmers_dashboard_column')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{totalUnileverFarmers(contracts)}</p>
                    </div>
                </div>
                <div style={boxStyle}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('contratcs_dashboard_column')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{contracts.length}</p>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("fruit_unilever_volume")}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{fruitUnileverPlotQuantity(contracts).IndoFormat()}</p>
                            <p className="sidebar-suffix">Kg</p>
                        </div>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("fruit_non_unilever_volume")}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{fruitNonUnileverPlotQuantity(contracts).IndoFormat()}</p>
                            <p className="sidebar-suffix">Kg</p>
                        </div>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('fcrop_given')}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{totalFcrop(contracts).IndoFormat()}</p>
                            <p className="sidebar-suffix">Rp</p>
                        </div>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container" >
                        <p className="sidebar-section-title">{t("average_fcrop")}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{averageFcrop(contracts).IndoFormat()}</p>
                            <p className="sidebar-suffix">Rp</p>
                        </div>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("ppunch_given")}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{(totalPpunch(contracts) / 1000 / 1000).IndoFormat(2)}</p>
                            <p className="sidebar-suffix">jt</p>
                        </div>
                    </div>
                </div>
                <div style={boxStyle2}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("ppunch_on_gmv")}</p>
                        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                            <p className="sidebar-number">{(percentPpunchOnGMV(contracts)).IndoFormat(3)}</p>
                            <p className="sidebar-suffix">%</p>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: "1100px"
                    }
                }}
                open={showLandPLots}
                onClose={() => setShowLoandPlots(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <ListHarvestDetails harvests={getHarvests(contracts).sort((h1, h2) => { return h1.contractId > h2.contractId ? -1 : h1.contractId === h2.contractId ? 0 : 1})} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export function DashboardList(props: DashboardListProps) {

    // const [selectedOrg, setSelectedOrg] = useState<Organisation>(props.organisations[0])
    const [contractsOrg, setContractsOrg] = useState<Map<number, { contracts: Contract[], org: Organisation }>>()

    useEffect(() => {
        const map = new Map<number, { contracts: Contract[], org: Organisation }>()
        props.contracts.forEach(c => {
            if (map.has(c.primary_buyer.organisation.id)) {
                const element = map.get(c.primary_buyer.organisation.id)
                element.contracts.push(c)
                map.set(c.primary_buyer.organisation.id, element)
            } else {
                map.set(c.primary_buyer.organisation.id, { contracts: [c], org: c.primary_buyer.organisation })
            }
        })
        setContractsOrg(map)
    }, [props.contracts])

    const generateList = () => {
        if (contractsOrg) {
            const array = [...contractsOrg]
            return array.map(v => <DashboardOrganisationItem key={v[0]} org={v[1].org} contracts={v[1].contracts} />)
        }
    }
    return (
        <div className="greenPaleBgColor" style={{ height: "100%", padding: "30px 10px", flexDirection: "column", display: "flex", flex:1 }}>
            <div style={{ flexGrow: "0", display: "flex" }}>
                <p className="table-label-indicator">{`${contractsOrg ? [...contractsOrg].length : 0} ${t("mills")}`}</p>
            </div>
            <div style={{ display: "flex", flexGrow: "1" }}>
                <List style={{ width: "100%" }}>
                    {generateList()}
                </List>
            </div>
            <div style={{ paddingBottom: "25px", flex: "1", flexGrow: "1", gap:"30px", justifyContent: "center", display: "flex", flexDirection:"column" }}>
                <Button variant="contained" style={{ marginTop: "20px", borderRadius: "100px", color: "white", fontWeight: "700", height: "46px", backgroundColor: colorPrimary, width:"fit-content", alignSelf:"center" }} onClick={props.downloadSHP}>{t("download_shapefile")}</Button>
                <p className="sidebar-warning-message">
                    {t('unilever_data_warning')}
                </p>
            </div>
        </div>
    )
}