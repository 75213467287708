import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
import { Contract } from "../../models/contract/Contract"
import { Location } from "../../models/organisation/Location"
import { Organisation } from "../../models/organisation/Organisation"
import { formatVillageSubDistrict } from "../../helper/helper";
import pempemIcon from '../../rsc/logo_pempem.png'
import customFonts from '../../rsc/fonts/font_vfs.json'

(pdfMake).vfs = pdfFonts.pdfMake.vfs;


type ContractInfo = {
    size: number
    locations: Location[]
    quantity: number
    traceability: number
    categories: string[]
}

function getContractInfo(contracts: Contract[]): ContractInfo {
    var info: ContractInfo = {
        size: 0,
        locations: [],
        quantity: 0,
        traceability: 0,
        categories: []
    }
    var mapLoc = new Map<number, Location>()
    var farmWithPlot = 0
    var allFarm = 0
    var mapSize = new Map<number, number>()
    contracts.forEach(c => {
        c.harvests.forEach(h => {
            mapSize.set(h.harvest.land_plot?.id, h.harvest.land_plot?.size ?? 0)
            if (h.harvest.farmer.organisation?.location?.id) {
                mapLoc.set(h.harvest.farmer.organisation.location.id, h.harvest.farmer.organisation.location)
            }
            allFarm += 1
            if (h.harvest.farmer.organisation?.landplots) {
                farmWithPlot += 1
            }
        })
        info.quantity += c.netQuantity ?? 0
    })
    info.locations = [...mapLoc.values()]
    info.traceability = (farmWithPlot / allFarm) * 100
    return info
}

function getBase64String(imagePath): Promise<string> {
    const image = new Image();
    return new Promise((r) => {
        image.onload = () => {
            // Create a canvas and draw the image on it
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            // Get the base64-encoded data URL
            const base64String = canvas.toDataURL('image/png'); // Adjust the format as needed
            r(base64String)
        };
        image.onerror = (error) => {
            console.error('Error loading image:', error);
        };
        image.src = imagePath;
    })
}


export function generateTTPPdf(contracts: Contract[], mill: Organisation, fileName: string, range: string[]): Promise<any> {
    return new Promise((resolver => {
        getBase64String(pempemIcon).then((img) => {

            var rows = []

            var rowStyle = { color: 'gray', fontSize: 8 }

            var info = getContractInfo(contracts)

            rows.push([{ text: 'Period Covered', style: "table_header" }, { text: `${range[0]}-${range[1]}`, style: rowStyle }])
            rows.push([{ text: 'Supply base', style: "table_header" }, { text: 'PT Pemasok Mitra Pembeli', style: rowStyle }])
            rows.push([{ text: 'Lat, Lon', style: "table_header" }, { text: '0.414660, 101.378757', style: rowStyle }])
            rows.push([{ text: 'Place of Sourcing (Village; Sub-District)', style: "table_header" }, { text: `${info.locations.map((l, i) => formatVillageSubDistrict(l) + (i === info.locations.length - 1 ? '' : '/'))}`, style: rowStyle }])
            rows.push([{ text: 'Category', style: "table_header" }, { text: 'Agent', style: rowStyle }])
            rows.push([{ text: 'FFB supply (ton)', style: "table_header" }, { text: (info.quantity / 1000), style: rowStyle }])
            rows.push([{ text: 'Traceability', style: "table_header" }, { text: `${info.traceability}%`, style: rowStyle }])
            rows.push([{ text: 'Size (ha)', style: "table_header" }, { text: `${info.size}`, style: rowStyle }])

            const doc = {
                content: [
                    {
                        image: img,
                        width: 110
                    },
                    { text: 'PemPem Traceability To Plantation (TTP)', style: 'pempem_title', margin: [0, 40, 0, 0] },
                    { text: 'Summary Report', style: 'pempem_title' },
                    { text: `TTP Report for: ${mill.name}`, style: 'pempem_address', margin: [0, 20, 0, 0] },
                    {
                        style: 'contracts',
                        table: {
                            widths: [150, '*'],
                            body: rows
                        },
                        margin: [0, 20, 0, 20]
                    },
                    {
                        text: [
                            { text: 'Shapefiles of all polygons associated with FFB supply are available in the SHP file acoompanying this Summary Report. For any additional information please contact ', style: 'pempem_footer', margin: [5, 20, 5, 0] },
                            { text: 'hanif@pempem.io', color: '#0097A7', style: 'pempem_footer' }
                        ]
                    }
                ],
                styles: {
                    pempem_title: {
                        font: 'Nunito',
                        fontSize: 20,
                        bold: true,
                        color: 'black',
                        alignment: 'center'
                    },
                    pempem_sub_title: {
                        font: 'Nunito',
                        fontSize: 15,
                        bold: true,
                        color: 'black',
                        alignment: 'center'
                    },
                    pempem_footer: {
                        font: 'Nunito',
                        fontSize: 10,
                        color: 'black',
                        alignment: 'center'
                    },
                    pempem_address: {
                        font: 'Nunito',
                        color: 'black',
                        alignment: 'center'
                    },
                    pempem_phone: {
                        font: 'Nunito',
                        fontSize: 8,
                        color: 'black',
                        alignment: 'center'
                    },
                    pempem_website: {
                        font: 'Nunito',
                        fontSize: 8,
                        color: 'black',
                        alignment: 'center'
                    },
                    table_header: {
                        font: 'Nunito',
                        fillColor: "#e0e0e0",
                        fontSize: 10
                    }
                }
            }
            var fonts = {
                Roboto: {
                  normal: 'Roboto-Regular.ttf',
                  bold: 'Roboto-Medium.ttf',
                  italics: 'Roboto-Italic.ttf',
                  bolditalics: 'Roboto-MediumItalic.ttf'
                },
                Nunito: {
                    normal: 'Nunito-Regular.ttf',
                    bold: 'Nunito-Bold.ttf',
                  }
  
              };
            pdfMake.vfs["Nunito-Regular.ttf"] = customFonts["Nunito-Regular.ttf"]
            pdfMake.vfs["Nunito-Bold.ttf"] = customFonts["Nunito-Bold.ttf"]
            pdfMake.vfs["Nunito-SemiBold.ttf"] = customFonts["Nunito-SemiBold.ttf"]

            const pdfDocGenerator = pdfMake.createPdf(doc, null, fonts, pdfMake.vfs);
            pdfDocGenerator.getBlob((blob) => {
                resolver(blob)
            });
        })
    }))
}