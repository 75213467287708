import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Button, Dialog, DialogContent, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "../../models/provider/Provider";
import { UserProvider } from "../../models/provider/UserProvider";
import { firebaseApp } from "../login/firebaseInit";
import "./ActiveContract.scss";
import { ListItemContract } from "./ListItemContract";
// import contractsImported from "../../rsc/content/contract.json"
import { CreateInvoice, GetCompletedContract, GetHarvestInfosExport, myCompaniesContract } from "../../api";
import { EmptyView } from "../../components/EmptyView";
import { Loader } from "../../components/Loader";
import { transactorId } from "../../helper/TokenHelper";
import { generatePlotInfoContractList } from "../../helper/contractHelper";
import { addFileToZip, getPickedUp, isSameMonth, showErrorMessage, showSuccessMessage } from "../../helper/helper";
import { generateGeoJSONFromContractsList } from "../../models/MapBoxDashBoardModel";
import { Contract, buildContractForExport } from "../../models/contract/Contract";
import { ContractList, buildContractList } from "../../models/contract/ContractList";
import { Organisation, buildOrganisationFromDetail } from "../../models/organisation/Organisation";
import { colorPrimary, colorRed } from "../../rsc/colors/colors";
import { ConfirmInvoiceCreation } from "./component/ConfirmInvoiceCreation";
import OptionsCompletedContractButton from "./component/OptionsCompletedContractButton";
import { generateTTPPdf } from "./generateTTP";

export enum CompletedContractOptions {
    CREATE_INVOICE,
    DOWNLOAD_CSV,
    DOWNLOAD_TTP,
    NONE
}

export type ProviderState = {
    list: Provider[]
    userProvider: UserProvider
}

type ContractsProps = {
    client: ApolloClient<NormalizedCacheObject>,
    onViewMoreClick: (contract: ContractList) => void,
    contracts: ContractList[],
    setContracts: React.Dispatch<React.SetStateAction<ContractList[]>>,
    endDate: dayjs.Dayjs,
    startDate: dayjs.Dayjs,
    setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>,
    setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>,
    startDateOpen: boolean,
    setStartDateOpen: React.Dispatch<React.SetStateAction<boolean>>,
    endDateOpen: boolean,
    setEndDateOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectedOrg: Organisation,
    setSelectedOrg: React.Dispatch<React.SetStateAction<Organisation>>,
    organisations: Organisation[],
    setOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>,
    contractToDisplay: ContractListItem[],
    setContractToDisplay: React.Dispatch<React.SetStateAction<ContractListItem[]>>,
    optionSelected: CompletedContractOptions,
    setOptionSelected: React.Dispatch<React.SetStateAction<CompletedContractOptions>>
}

export type ContractListItem = {
    isSelected: boolean,
    contract: ContractList
}

function PreviousContract({ client,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateOpen,
    endDateOpen,
    setStartDateOpen,
    setEndDateOpen,
    onViewMoreClick,
    selectedOrg,
    setSelectedOrg,
    organisations,
    setOrganisations,
    contractToDisplay,
    setContractToDisplay,
    contracts,
    setContracts,
    optionSelected,
    setOptionSelected }: ContractsProps) {

    const [loading, setLoading] = useState(false)
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
    const canGenerateInvoice = useRef(false)

    const { t } = useTranslation()
    useEffect(() => {
        const collection = firebaseApp.firestore().collection('niagaEnterprise')
        const unsubscribe = collection.onSnapshot((changes) => {
            var doc = changes.docs.find(d => d.id === "flags").data()
            var isEnbale = doc.canGenerateInvoice
            canGenerateInvoice.current = isEnbale
        })
        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const contractsFiltered = contracts.map((c) => { return { isSelected: false, contract: c } })
        setContractToDisplay(contractsFiltered)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts])

    useEffect(() => {
        if (organisations === undefined) {
            client.query({
                query: myCompaniesContract,
                variables: {
                    transactorId: transactorId()
                },
                context: {
                    headers: {
                        "role": "usermill"
                    }
                }
            }).then(result => {
                let orgs: Organisation[] = result.data.organisations.map(o => buildOrganisationFromDetail(o.organisation))
                orgs.sort((orgA, orgB) => { return orgA.name.localeCompare(orgB.name) })
                const firstOrg = orgs.at(0)
                setSelectedOrg(firstOrg)
                fetchContracts(firstOrg)
                setOrganisations(orgs)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function isCreateInvoiceOption() {
        return optionSelected === CompletedContractOptions.CREATE_INVOICE
    }

    function isDownloadCSVOption() {
        return optionSelected === CompletedContractOptions.DOWNLOAD_CSV
    }

    function isDownloadTTPOption() {
        return optionSelected === CompletedContractOptions.DOWNLOAD_TTP
    }

    function isNoneOption() {
        return optionSelected === CompletedContractOptions.NONE
    }

    const fetchContracts = (org: Organisation) => {

        if (org && startDate && endDate) {
            var marketDayStart
            var marketDayEnd

            if (startDate) {
                marketDayStart = startDate.format('YYYY-MM-DD')
            }
            if (endDate) {
                marketDayEnd = endDate.format('YYYY-MM-DD')
            }
            setLoading(true)
            client.query({
                query: GetCompletedContract,
                variables: {
                    orgId: org.id,
                    market_day: marketDayStart,
                    market_day_end: marketDayEnd
                },
                context: {
                    headers: {
                        "role": "usermill"
                    }
                }
            }).then((data) => {
                var contracts: ContractList[] = data.data.palmoil_transport_crew_contract_sale.map((s) => buildContractList(s))
                contracts = contracts.sort((c1, c2) => { return c1.id > c2.id ? -1 : c1.id === c2.id ? 0 : 1 })
                setContracts(contracts)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchContracts(selectedOrg)
        onNoneSelected()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, selectedOrg])

    function createInvoice(contracts: ContractList[]) {
        const invoiceItems: any[] = contracts.map(c => {
            return {
                contract_id: c.id,
                sale_id: c.sale_id
            }
        })
        setLoading(true)
        client.mutate({
            mutation: CreateInvoice,
            variables: {
                data: invoiceItems
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then(() => {
            fetchContracts(selectedOrg)
            showSuccessMessage(t('invoice_created'))
            onNoneSelected()
        }).catch(e => {
            showErrorMessage(e.message)
            setLoading(false)
        }).finally(() => {
            setShowConfirmDialog(false)
        })
    }

    function ContractHeader() {
        return <tr>
            <td>
                {/* <input style={{ marginRight: "5px", width: "18px", height: "18px" }} onChange={e => setAllContractSelected(!allContractSelected)} checked={allContractSelected} onClick={(e) => {
                    selectContracts()
                    e.stopPropagation()
                }} type="checkbox" /> */}
            </td>
            <td>{t('contract_id')}</td>
            <td>{t('date')}</td>
            <td>{t('delivery_date')}</td>
            <td>{t('status')}</td>
            <td>{t('quantity')}</td>
            <td>{t('price')}</td>
            <td>{t('invoice_id')}</td>
            <td></td>
        </tr>
    }

    const onStartDateSelected = (newValue) => {
        setStartDateOpen(false)
        setStartDate(newValue)
        if (endDate) {
        } else {
            setEndDateOpen(true)
        }
    }

    const onEndDateSelected = (newValue) => {
        setEndDateOpen(false)
        setEndDate(newValue)
        if (startDate) {
        } else {
            setStartDateOpen(true)
        }
    }

    function hasContractOrgVat(): boolean {
        return selectedOrg.taxInformations.taxActivated
    }

    // function isContractButtonEnable(): boolean {
    //     return contractSelected().length > 0
    // }

    function contractSelected(): ContractList[] {
        return contractToDisplay.filter(c => c.isSelected).map(c => c.contract)
    }


    function onSelectorChange(id: string) {
        const org = organisations.find(o => o.id.toString() === id)
        setSelectedOrg(org)
    }

    function OrganisationSelector() {
        if (selectedOrg) {
            return (
                <FormControl className="w-[200px]">
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        {t('companies')}
                    </InputLabel>
                    <NativeSelect
                        defaultValue={selectedOrg.id}
                        onChange={e => onSelectorChange(e.target.value)}>
                        {
                            organisations.map(o => {
                                return (
                                    <option key={o.id} value={o.id}>{o.name}</option>
                                )
                            })
                        }
                    </NativeSelect>
                </FormControl>)
        } else {
            return <></>
        }
    }

    // const selectContracts = () => {
    //     contractToDisplay.filter(c => c.contract.invoiceId === null || c.contract.invoiceId === undefined).forEach(c => c.isSelected = !allContractSelected)
    //     setContractToDisplay([...contractToDisplay])
    // }


    function getContractSelectedError(contractClicked: ContractListItem): string {
        if (isCreateInvoiceOption()) {
            if (contractClicked.contract.invoiceId) {
                return t('contract_already_in_invoice')
            } else if (contractClicked.contract.status === "ESTIMATED") {
                return t('contract_estimated_error')
            } else if (!hasContractOrgVat()) {
                return t('vat_needed_invoice')
            } else if (contractSelected().length > 0 && !isSameMonth(contractSelected()[0].getDeliveryDate(), contractClicked.contract.getDeliveryDate())) {
                return t('contract_invoice_same_month_requiered')
            }
        } else if (isDownloadCSVOption()) {
            return undefined
        } else if (isDownloadTTPOption()) {
            return undefined
        } else {
            return "none"
        }
    }


    function onDownloadCSV() {
        setOptionSelected(CompletedContractOptions.DOWNLOAD_CSV)
    }

    function onDownloadTTP() {
        contractToDisplay.forEach(c => c.isSelected = true)
        setContractToDisplay([...contractToDisplay])
        setOptionSelected(CompletedContractOptions.DOWNLOAD_TTP)
    }

    function onNoneSelected() {
        setOptionSelected(CompletedContractOptions.NONE)
        contractToDisplay.forEach(c => c.isSelected = false)
        setContractToDisplay(contractToDisplay)
    }

    function ConfirmOption() {
        var text: string
        var action: () => void
        if (isCreateInvoiceOption()) {
            text = t('contract_invoice')
            action = (() => setShowConfirmDialog(true))
        } else if (isDownloadCSVOption()) {
            text = t('download_csv')
            action = (() => downloadCSV())
        } else if (isDownloadTTPOption()) {
            text = t('download_ttp')
            action = (() => downloadTTP())
        }
        return <div>
            <Button style={{ color: colorRed, marginRight: "10px" }} onClick={() => onNoneSelected()}>{t('cancel')}</Button>
            <Button style={{ background: colorPrimary }} variant="contained" onClick={() => action()}>{text}</Button>
        </div>
    }

    function buildCSV() {
        if (contractSelected().length > 0) {
            const table = [[t('contract_number').split(' ').join('_'),
            t('market_day').split(' ').join('_'),
            t('quantity'),
            t('price_paid').split(' ').join('_')],
            ...contractSelected().map(c => {
                return [
                    c.id,
                    c.getMarketDay().split(' ').join('_'),
                    c.getQuantity().toFixed(0),
                    c.getTotal().toFixed(0)]
            })]

            let csvContent = "data:text/csv;charset=utf-8,"
                + table.map(e => e.join(",")).join("\n")

            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
            onNoneSelected()
        } else {
            showErrorMessage(t('selected_requirement_contract'))
        }

    }

    const downloadCSV = () => {
        buildCSV()
    }

    const downloadTTP = () => {
        setLoading(true)
        const contracts = contractSelected()?.map(c => c.id) ?? []
        if (contracts.length > 0) {
            client.query({
                query: GetHarvestInfosExport,
                variables: {
                    contracts: contracts
                }
            }).then(result => {
                const contractsExport: Contract[] = result.data.niaga_contract_list_export.items.map(c => buildContractForExport(c))
                const startName = startDate.format("YYYY-MM-DD")
                const endName = endDate.format("YYYY-MM-DD")
                generateTTPPdf(contractsExport, selectedOrg, "ttp", [startName, endName]).then(file => {
                    const positions = contractsExport.flatMap(c => c.harvests).filter(ch => ch.harvest.pickedUpPosition).map(ch => ch.harvest.pickedUpPosition)
                    const photos: string[] = contractsExport.flatMap(c => c.harvests).filter(ch => ch.harvest.hasFruitPhoto() && ch.harvest.pickedUpPosition === undefined).map(ch => ch.harvest.getFruitPhoto())
                    getPickedUp(photos).then(pos => {
                        pos.forEach(p => {
                            positions.push(p)
                        })
                        generateSHP(contractsExport, pos).then(z => {
                            addFileToZip(z, "ttp.pdf", file, `ttp_${startName}_${endName}.zip`).then(r => {
                                setLoading(false)
                            })
                        })
                    })
                })
            })
        } else {
            showErrorMessage(t('selected_requirement_ttp_contract'))
            setLoading(false)
        }
    }

    const generateSHP = (contracts: Contract[], pos: number[][]): any => {
        if (contractSelected().length > 0) {
            var plots = generatePlotInfoContractList(contracts)
            const zipFolder = generateGeoJSONFromContractsList(contracts, plots, pos)
            onNoneSelected()
            return zipFolder
        } else {
            showErrorMessage(t('selected_requirement_ttp_contract'))
            setLoading(false)
        }
        return undefined
    }

    return (
        <div>
            <div style={{ paddingLeft: "28px", boxSizing: "border-box", width: "100%", marginTop: "0px", display: "inline-block", paddingRight: "20px" }}>
                <h1 style={{ marginLeft: "0px" }}>{t("contracts")}</h1>
                <div>
                    <div style={{ display: "flex", alignItems: "end" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", gap: "20px", marginRight: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ verticalAlign: "bottom", display: "inline-block" }}>
                                    <DatePicker maxDate={dayjs()} onClose={() => setStartDateOpen(false)} open={startDateOpen} onOpen={() => setStartDateOpen(!startDateOpen)} defaultValue={startDate} onChange={(newValue) => onStartDateSelected(newValue)} label={t('chose_start_date')} />
                                </div>
                                <div style={{ verticalAlign: "bottom", display: "inline-block" }}>
                                    <DatePicker maxDate={dayjs()} onClose={() => setEndDateOpen(false)} open={endDateOpen} onOpen={() => setEndDateOpen(!endDateOpen)} defaultValue={endDate} onChange={(newValue) => onEndDateSelected(newValue)} label={t('chose_end_date')} />
                                </div>
                            </LocalizationProvider>
                        </div>
                        <div className="flex">
                            {
                                OrganisationSelector()
                            }
                        </div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "end" }}>
                            {isNoneOption() ?
                                <OptionsCompletedContractButton
                                    onDownloadCSV={() => onDownloadCSV()}
                                    onDownloadTTP={() => onDownloadTTP()} />
                                : <ConfirmOption />
                            }
                        </div>
                    </div>
                    <table className="contract-row" style={{ borderCollapse: "collapse", width: "100%", marginTop: "30px" }}>
                        <tbody>
                            {ContractHeader()}
                            {contractToDisplay.map((contract) => {
                                return <ListItemContract isDisable={isDownloadTTPOption()}
                                    isNotSelectable={getContractSelectedError(contract)} contractError={(c: ContractListItem) => getContractSelectedError(c)}
                                    key={contract.contract.id} contractList={contract} onClick={(contractClicked: ContractListItem) => {
                                        const error = getContractSelectedError(contractClicked)
                                        if (isNoneOption()) {
                                            return
                                        }
                                        if (error) {
                                            showErrorMessage(error)
                                        } else {
                                            if (!isDownloadTTPOption()) {
                                                contractClicked.isSelected = !contractClicked.isSelected
                                                setContractToDisplay([...contractToDisplay])
                                            }
                                        }
                                    }}
                                    onContractClick={(contract) => { onViewMoreClick(contract) }} />
                            })}
                        </tbody>
                    </table>
                    <EmptyView value={t('no_contract_filtered')} isVisible={contractToDisplay.length === 0} />
                </div>
            </div>
            <Dialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <ConfirmInvoiceCreation invoiceContracts={contractSelected()} onAccept={() => createInvoice(contractSelected())} onDecline={() => setShowConfirmDialog(false)} />
                </DialogContent>
            </Dialog>
            <Loader isLoading={loading}/>
        </div>

    )
}

export default PreviousContract