import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import PempemLogo from '../../rsc/pempem_title.png';

(pdfMake).vfs = pdfFonts.pdfMake.vfs;

type Invoice = {
   invoice_number: number
   file_name_shortened: string
   title: string
   pempem_info: string
   pempem_address: string,
   pempem_phone: string,
   pempem_website: string,
   subtotal: number
   'pre-tax': number
   note: string
   file_name_truncated: string
   pph: number
   ppn: number
   created_at: string
   updated_at: string
   buyer_organisation_id: number
   seller_organisation_id: number
   total: number
   payment_info: {
      bank_account_name: string
      bank_name: string
      bank_account: string
      payment_note: string
   }
   status: string
   buyer_organisation_data: {
      name: string
      legal_name: string
      complete_address: string
      phonenumber: string
      postal_code: string
      phone: string
      phonenumber_formatted: string
      legal_location: {
         rt: string
         rw: string
         village: string
         subdistrict: string
         district: string
         complete_address: string
      }
   }
   contract_details: [
      {
         external_id: string
         contract_id: number
         invoice_date: string
         line_items: LineItem[]
      }
   ]
}

type LineItem = {
   id: number
   sku: string
   line_total: number
   quantity: number
   price: number
   quantity_unit: string
   sku_description: string
}

function getBase64String(imagePath): Promise<string> {
   const image = new Image();
   return new Promise((r) => {
      image.onload = () => {
         // Create a canvas and draw the image on it
         const canvas = document.createElement('canvas');
         canvas.width = image.width;
         canvas.height = image.height;
         const context = canvas.getContext('2d');
         context.drawImage(image, 0, 0);

         // Get the base64-encoded data URL
         const base64String = canvas.toDataURL('image/png'); // Adjust the format as needed
         r(base64String)
      };
      image.onerror = (error) => {
         console.error('Error loading image:', error);
      };
      image.src = imagePath;
   })
}

function toIndoFormat(value: number): string {
   return Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
   }).format(value)
}


export function generateInvoicePDF(invoice: Invoice, onCompleted: (pdf: any) => void) {
   generatePdf(invoice, onCompleted)
}

function buildKota(invoice: Invoice): string {
   const org = invoice.buyer_organisation_data
   return `${org.legal_location.district}`
}

function generatePdf(invoice: Invoice, onCompleted: (pdf: any) => void) {
   var rows = []
   rows.push([{ text: 'Kontrak ID', style: "table_header" },
   { text: 'Deskripsi', style: "table_header" },
   { text: 'Timbangan', style: "table_header" },
   { text: 'Unit', style: "table_header" },
   { text: 'Harga Unit', style: "table_header" },
   { text: 'Jumlah', style: "table_header" }])

   var rowStyle = { color: 'black', fontSize: 8 }
   var rowStyleRight = { color: 'black', fontSize: 8, alignment: 'right' }

   invoice.contract_details.forEach((c: any) => {
      c.line_items.forEach((li: LineItem) => {
         rows.push(
            [{ text: `${c.contract_id}`, ...rowStyle },
            { text: `${li.sku_description}`, ...rowStyle },
            { text: `${li.quantity}`, ...rowStyle },
            { text: `${li.quantity_unit}`, ...rowStyleRight },
            { text: `${li.price}`, ...rowStyleRight },
            { text: `${toIndoFormat(li.line_total)} Rp`, ...rowStyleRight }]
         )
      })
   })
   rows.push([{ text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, true, false] },
   { text: 'Subtotal:', fontSize: 8, border: [true, false, true, false] }, { text: `${toIndoFormat(invoice.subtotal)} Rp`, alignment: 'right', fontSize: 8, border: [true, false, true, false] }])
   rows.push([{ text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] },
   { text: 'PPh 22 0.25%', fontSize: 8, border: [true, false, true, false] }, { text: `${toIndoFormat(invoice.pph)} Rp`, alignment: 'right', fontSize: 8, border: [true, false, true, false] }])
   rows.push([{ text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] },
   { text: 'PPN  1.1%', fontSize: 8, border: [true, false, true, false] }, { text: `${toIndoFormat(invoice.ppn)} Rp`, alignment: 'right', fontSize: 8, border: [true, false, true, false] }])
   rows.push([{ text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] },
   { text: 'Total keseluruhan:', fontSize: 8, border: [true, false, true, true] }, { text: `${toIndoFormat(invoice.total)} Rp`, fontSize: 8, alignment: 'right', border: [true, false, true, true] }])

   getBase64String(PempemLogo).then((img) => {
      const doc = {
         content: [
            { image: img, width: 250, alignment: "center" },
            { text: invoice.pempem_info, style: 'pempem_sub_title', margin: [0, 5, 0, 5] },
            {
               style: 'contracts',
               columns: [
                  {
                     width: 115,
                     text: ""
                  },
                  {
                     width: "*",
                     text: invoice.pempem_address, style: 'pempem_address'
                  },
                  {
                     width: 115,
                     text: ""
                  }
               ],
               margin: [0, 0, 0, 5]
            },
            { text: `Tel: ${invoice.pempem_phone}                                               ${invoice.pempem_website}`, style: 'pempem_phone' },
            {
               canvas: [
                  {
                     type: 'line',
                     x1: 0,
                     y1: 0,
                     x2: 515,
                     y2: 0,
                     lineWidth: 2,
                     lineColor: 'black', // Line color (optional)
                  },
               ],
               margin: [0, 10, 0, 0]
            },
            { text: invoice.title, fontSize: 18, alignment: 'center', margin: [0, 20, 0, 20] },
            { text: `Tanggal faktur: ${invoice.contract_details[0].invoice_date}`, fontSize: 8 },
            { text: `Faktur ID: ${invoice.file_name_truncated}`, fontSize: 8, alignment: 'right' },
            { text: `Kepada: ${invoice.buyer_organisation_data.legal_name ?? invoice.buyer_organisation_data.name}`, fontSize: 8 },
            { text: `Alamat: ${invoice.buyer_organisation_data?.complete_address}`, fontSize: 8 },
            { text: `Kota: ${buildKota(invoice)}`, fontSize: 8 },
            { text: `Telepon: ${invoice.buyer_organisation_data.phonenumber_formatted}`, fontSize: 8 },
            {
               style: 'contracts',
               table: {
                  widths: ['auto', '*', 'auto', 'auto', 90, 90],
                  body: rows
               },
               margin: [0, 20, 0, 20],
               layout: {
                  hLineWidth: function (i, node) {
                     return 0.5; // Set the horizontal line width
                  },
                  vLineWidth: function (i, node) {
                     return 0.5; // Set the vertical line width
                  }
               }
            },
            // {
            //    style: 'contracts',
            //    table: {
            //       widths: ['*', 89.5, 89.5],
            //       body: [
            //          [{ text: '', border: [false, false, false, false] },
            //          { text: 'Total keseluruhan:', fontSize: 7, alignment: 'right', border: [true, false, false, true] }, { text: `${toIndoCurrency(invoice.total)}`, fontSize: 8, border: [false, false, true, true] }]
            //       ]
            //    },
            //    margin: [0, 0, 0, 20]
            // },
            {
               columns: [
                  {
                     stack: [
                        { text: invoice.payment_info.payment_note, fontSize: 8, width: 200 },
                        { text: invoice.payment_info.bank_account_name, fontSize: 8, bold: true },
                        { text: invoice.payment_info.bank_name, fontSize: 8, bold: true },
                        { text: `Nomor rekening bank: ${invoice.payment_info.bank_account}`, fontSize: 8, bold: true },
                     ],
                     width: 250
                  },
                  {
                     columns: [
                        {
                           text: invoice.note,
                           margin: [30, 0, 0, 0],
                           fontSize: 8,
                           bold: true
                        }
                     ]
                  }
               ]
            },
         ],
         footer: function (currentPage, pageCount) {
            return {
               text: currentPage.toString() + ' / ' + pageCount,
               alignment: 'right',
               margin: [0, 10, 30, 0],
               fontSize: 10
            }
         },
         styles: {
            pempem_title: {
               fontSize: 18,
               bold: true,
               color: 'black',
               alignment: 'center'
            },
            pempem_sub_title: {
               fontSize: 15,
               bold: true,
               color: 'black',
               alignment: 'center'
            },
            pempem_address: {
               color: 'black',
               alignment: 'center'
            },
            pempem_phone: {
               fontSize: 8,
               color: 'black',
               alignment: 'center'
            },
            pempem_website: {
               fontSize: 8,
               color: 'black',
               alignment: 'center'
            },
            table_header: {
               fillColor: "#e0e0e0",
               fontSize: 8
            }
         }
      }
      // pdfMake.createPdf(doc, null, null, pdfFonts.pdfMake.vfs).download()
      onCompleted(pdfMake.createPdf(doc, null, null, pdfFonts.pdfMake.vfs))
   })
}