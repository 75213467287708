import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { getAccessTokenPromise } from "..";
import { Contract, buildContractForDashBoard } from "../models/contract/Contract";
import { Organisation } from "../models/organisation/Organisation";
import { NFMStore, setErrorMessageAction } from "../stores/store";
import { Location } from "../models/organisation/Location";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import 'firebase/compat/storage';
import { firebaseApp } from "../pages/login/firebaseInit";

export const defaultLng = 106.816666
export const defaultLat = -6.200000
export const defaultIndoCoo = [-6.200000, 106.816666]
const USER_POSITION = "USER_POSITION"

const DASHBOARD_URL = "/enterprise/unilever/reporting"


//Date Pattern
export const DATE_PATTERN_DD_MMMM_YYYY_HH_mm = "DD MMMM YYYY HH:mm"
export const DATE_PATTERN_DD_MMMM_YYYY = "DD MMMM YYYY"
export const DATE_PATTERN_DD_MM_YYYY = "DD MM YYYY"
export const DATE_PATTERN_MM_YYYY = "MM YYYY"
export const DATE_PATTERN_MMM = "MMM"
export const DATE_PATTERN_DD = "DD"
export const DATE_PATTERN_DD_MMM_YYYY_DASH = "DD-MMM-YYYY"
export const DATE_PATTERN_YYYY_MM_DD_DASH = 'YYYY-MM-DD'
export const DATE_PATTERN_HH_mm_DD_MMM = 'HH:mm, DD MMM'

export const checkEmail = (email): RegExpMatchArray => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const checkPassword = (password: string): boolean => {
    return password.length > 5
}

export function formatAddress(org: Organisation): string | null {
    var address = ""
    if (org.taxInformations?.address) {
        address += org.taxInformations?.address
    }


    if (org.taxInformations.postalCode) {
        address = `${address ? address + `, ${org.taxInformations.postalCode}` : org.taxInformations.rt}`
    }

    if (org.taxInformations.rw) {
        address = `${address ? address + `, RW ${org.taxInformations.rw}` : org.taxInformations.rw}`
    }

    if (org.taxInformations.rt) {
        address = `${address ? address + `, RT ${org.taxInformations.rt}` : org.taxInformations.rt}`
    }

    return address.length === 0 ? null : address
}

export function getUserPosition(): Promise<number[]> {
    return new Promise((resolve, reject) => {
        const successCallback = (position) => {
            let coordinates = JSON.stringify([position.coords.longitude, position.coords.latitude])
            window.localStorage.setItem(USER_POSITION, coordinates)
            resolve([position.coords.longitude, position.coords.latitude])
        };

        const errorCallback = (error) => {
            reject(error)
        };
        let posStorage = window.localStorage.getItem(USER_POSITION)
        let userPos = JSON.parse(posStorage)
        if (userPos) {
            resolve(userPos)
        } else {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
        }
    })
}


export function toIndoFormat(value: number, maxDigit: number = 0): string {
    return Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDigit
    }).format(value)
}

export function toIndoCurrency(value: number): string {
    return Intl.NumberFormat('id-ID', {
        currency: "IDR", style: "currency", minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value)
}

export function convertDayjsToString(day: Dayjs, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm): string {
    return day.format(pattern)
}

// DATE utc() is used to take the date as raw, need to send a Z date though
export function convertToDateRaw(day: string, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm) {
    return dayjs(day).format(pattern)
}

// DATE utc() is used to take the date as raw, need to send a Z date though
export function convertToDate(day: string, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm) {
    return dayjs(day).utcOffset(7).format(pattern)
}

export function convertToDateWithUtc(day: string, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm, inPatern: string = undefined) {
    if (inPatern) {
        return dayjs(day, inPatern).utc().format(pattern)
    }
    return dayjs(day).format(pattern)
}

export function convertToDateWithUtcJakartaTime(day: string, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm, inPatern: string = undefined) {
    if (inPatern) {
        return dayjs(day, inPatern).tz('Asia/Jakarta').format(pattern)
    }
    return dayjs(day).tz('Asia/Jakarta').format(pattern)
}

export function addDayToDate(day: string, nbDayToAdd: number, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm) {
    return dayjs(day).add(nbDayToAdd, 'day').format(pattern)
}

export function removeDayToDate(day: string, nbDayToAdd: number, pattern: string = DATE_PATTERN_DD_MMMM_YYYY_HH_mm) {
    return dayjs(day).subtract(nbDayToAdd, 'day').format(pattern)
}

export function isDateBeforeOrEqual(compare: string, to: string, pattern: string = DATE_PATTERN_YYYY_MM_DD_DASH): boolean {
    const firstDate = dayjs(compare).format(pattern)
    const secondDate = dayjs(to).format(pattern)
    return dayjs(firstDate).isBefore(secondDate) || dayjs(firstDate).isSame(secondDate)
}

export function isSameDate(compare: string, to: string, pattern: string = DATE_PATTERN_YYYY_MM_DD_DASH): boolean {
    const firstDate = dayjs(compare).format(pattern)
    const secondDate = dayjs(to).format(pattern)
    return dayjs(firstDate).isSame(secondDate)
}

export function isSameMonth(compare: string, to: string): boolean {
    const firstDate = dayjs(compare).format("MMM-YYYY")
    const secondDate = dayjs(to).format("MMM-YYYY")
    return dayjs(firstDate).isSame(secondDate, 'month')
}

export function formatVillageSubDistrict(location: Location): string {
    return `${location.village}; ${location.subDistrict}`
}

export function downloadInvoiceHelper(invoiceId: number, outputName: string): Promise<string> {
    const data = {
        "invoiceId": invoiceId,
    }
    return new Promise((resolve, reject) => {
        getAccessTokenPromise().then(token => {
            axios.post(`${process.env.REACT_APP_BASE_URL_PUBLIC}/invoice-pdf`, data, {
                // axios.post(`https://us-east1-palmoil-qa8f2aa60d.cloudfunctions.net/getInvoicePdf`, data, {
                // withCredentials: false,
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }).then((response) => {
                const binaryString = atob(response.data);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                resolve(url)
                // return
                // const link = document.createElement('a');
                // link.href = url;
                // link.download = outputName;
                // document.body.appendChild(link);
                // link.click();
                // URL.revokeObjectURL(url);
                // resolve("success")
            }).catch(e => {
                reject(e)
            })
        })
    })
}

export function getBlobFromBase64(base64: string): Blob {
    const binaryString = atob(base64);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    return blob
}
export const addFileToZip = async (base64Zip: string, newFileName: string, newFileContent: string, newZipName: string) => {
    const zip = new JSZip()

    // Load the ZIP from Base64
    const zipData = getBlobFromBase64(base64Zip);
    await zip.loadAsync(zipData);

    // Add a new text file to the ZIP
    zip.file(newFileName, newFileContent);

    // Generate the new ZIP as a binary blob
    const updatedZipBlob = await zip.generateAsync({ type: 'blob' });

    // Trigger download if needed
    saveAs(updatedZipBlob, newZipName);

    // Optionally, return as Base64
    const updatedZipBase64 = await zip.generateAsync({ type: 'base64' });
    return updatedZipBase64;
};

export function showErrorMessage(error: string) {
    NFMStore.dispatch(setErrorMessageAction({ message: error, color: "red" }))
}

export function showSuccessMessage(message: string) {
    NFMStore.dispatch(setErrorMessageAction({ message: message, color: "green" }))
}

export function clearErrorMessage() {
    NFMStore.dispatch(setErrorMessageAction(null))
}

export function devLog(message: any) {
    if (process.env.REACT_APP_BASE_ENV === "develop") {
        console.log(message)
    }
}


export const getPickedUp = (photos: string[]): Promise<number[][]> => {
    return new Promise(r => {
        var pos = []
        var photoLenght = photos.length
        if (photoLenght === 0) {
            r([])
            return
        }
        photos.forEach(async p => {
            let ref = firebaseApp.storage().refFromURL(p);
            let metadata = await ref.getMetadata()
            const location = metadata.customMetadata.photo_location
            try {
                const jsonObject = JSON.parse(location);
                pos.push(jsonObject.coordinates)
                if (pos.length === photoLenght) {
                    r(pos)
                }
            } catch (error) {
                photoLenght -= 1
                console.error('Error parsing JSON:', error);
                if (photoLenght === 0) {
                    r([])
                }
            }
        })
    })
}

export const getFireStoreFile = (file: string): Promise<any> => {
    return new Promise(async r => {
        let ref = firebaseApp.storage().refFromURL(file);
        try {
            console.log('ref', ref)
        } catch (error) {
            console.log(error)
        }
    })
}

export function isNullOrEmpty(value: string) {
    return value === null || value === undefined || value.length === 0
}

export const fetchReport = (startDate: string, endDate: string): Promise<Contract[]> => {
    return new Promise((resolve, reject) => {
        getAccessTokenPromise().then(token => {
            axios.get(`${process.env.REACT_APP_BASE_URL_PUBLIC}${DASHBOARD_URL}?minDate=${startDate}&maxDate=${endDate}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }).then((response) => {
                const contracts: Contract[] = response.data.map(c => buildContractForDashBoard(c))
                // eslint-disable-next-line
                resolve(contracts.filter(c => c.id != 2979 && c.primary_buyer_id != 53106))
            }).catch(e => {
                reject(e)
            })
        })
    })
}

export function getLocal() {
    return localStorage.getItem('lang') ?? "en-US"
}