import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { Button, Grid } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { distance, point } from "@turf/turf"
import dayjs from "dayjs"
import { LngLat, Map as MapUI } from "mapbox-gl"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes } from "react-router-dom"
import { Loader } from "../../components/Loader"
import MapBox from "../../components/MapBox"
import { getUserRoles } from "../../helper/TokenHelper"
import { fruitQuantity, fruitTracability, fruitUnileverPlotQuantity, totalAmount, totalFcrop, totalPpunch } from "../../helper/contractHelper"
import { DATE_PATTERN_YYYY_MM_DD_DASH, convertDayjsToString, defaultIndoCoo, fetchReport, showErrorMessage } from "../../helper/helper"
import { addLandPlotLayer, addMapAnnotationPlot, addMillCirclRadius, addMillDashboard, generateGeoJSONFromContracts, getCircleCoords, updateCircleRadius, updateLandplotSource, updateMills } from "../../models/MapBoxDashBoardModel"
import { zoomForCoordinates } from "../../models/MapBoxDataModel"
import { MapEventDashBoard } from "../../models/MapEventDashBoard"
import { Contract } from "../../models/contract/Contract"
import { Location } from "../../models/organisation/Location"
import { Organisation } from "../../models/organisation/Organisation"
import { colorPrimary, colorRed, colorYellowLight } from "../../rsc/colors/colors"
import { ReactComponent as ListIcon } from "../../rsc/ic_list.svg"
import { ReactComponent as MapIcon } from "../../rsc/ic_map.svg"
import { firebaseApp } from "../login/firebaseInit"
import "./Dashboard.css"
import { DashboardList } from "./DashboardList"
import DownloadDashboardButton from "./DownloadButton"
import { LocationFilter } from "./LocationFilter"
import { Report } from "./Report"
import { SideBarDashBoard } from "./SideBarDashBoard"
import { SideBarOnboarding } from "./SideBarOnboarding"
import { LandPlotDashBoardModel } from "./models/LandPlotDashBoardModel"


type DashboardProps = {
    client: ApolloClient<NormalizedCacheObject>
}

type DashBoardDate = {
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs
}

type ReportSorts = {
    id: number
}

function Dashboard(props: DashboardProps) {

    const { t } = useTranslation()

    const [dateRange, setDateRange] = useState<DashBoardDate>({ startDate: dayjs().startOf('year'), endDate: dayjs() })
    const [startDateOpen, setStartDateOpen] = useState(false)
    const [endDateOpen, setEndDateOpen] = useState(false)
    const [organisations, setOrganisations] = useState<Organisation[]>([])
    const [contracts, setContracts] = useState<Contract[]>([])
    const [contractsFiltered, setContractsFiltered] = useState<Contract[]>([])
    const [contractsReport, setContractsReport] = useState<Contract[]>([])
    const [locationFilter, setLocationFilter] = useState<Location>(null)
    const [selectedOrg, setSelectedOrg] = useState<Organisation>(null)
    const [loading, setLoading] = React.useState(false)
    const [sorts, setSort] = useState<ReportSorts>({ id: 2 })

    const [isDashboardEnabled, setIsDashboardEnabled] = useState<boolean>(true)
    // const [shouldUpdateDashboard, setShouldUpdateDashboard] = useState<boolean>(false)

    const landplots = useRef<Map<number, LandPlotDashBoardModel>>(new Map())
    const landplotsSelected = useRef<Map<number, LandPlotDashBoardModel>>(new Map())

    const organisationsRef = useRef<Organisation[]>()

    const [showModal, setShowModal] = useState(false);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);

    const [mapMode, setMapMode] = useState(true)

    let mapEvent = useRef<MapEventDashBoard>(null)

    const mapRef = useRef<MapUI>(null)

    useEffect(() => {
        const collection = firebaseApp.firestore().collection('niagaEnterprise')
        const unsubscribe = collection.onSnapshot((changes) => {
            var doc = changes.docs.find(d => d.id === "flags").data()
            var isEnbale = doc.dashboardEnabled
            // var shouldUpdateDashboard = doc.re
            const adminRoles = doc.adminRoles
            const userRoles = getUserRoles()
            adminRoles.forEach(role => {
                if (userRoles.find(ur => role === ur)) {
                    isEnbale = true
                }
            });
            setIsDashboardEnabled(isEnbale)
        })
        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log('redraw')

    const fetchContracts = () => {
        if (dateRange) {
            const s = convertDayjsToString(dateRange.startDate, DATE_PATTERN_YYYY_MM_DD_DASH)
            const e = convertDayjsToString(dateRange.endDate, DATE_PATTERN_YYYY_MM_DD_DASH)
            setLoading(true)
            fetchReport(s, e).then(contratcsReceived => {
                let orgs: Organisation[] = contratcsReceived.map(c => c.primary_buyer.organisation)

                orgs.sort((orgA, orgB) => { return orgA.name.localeCompare(orgB.name) })
                let orgsFilterd: Organisation[] = []
                orgs.forEach(p => {
                    if (orgsFilterd.find(ps => ps.id === p.id) === undefined) {
                        orgsFilterd.push(p)
                    }
                })
                organisationsRef.current = orgsFilterd
                setOrganisations(orgsFilterd)
                setContracts(contratcsReceived)
                filterReportContracts(contratcsReceived, locationFilter)
                buildLandplots(contratcsReceived)
            }).catch(e => {
                console.log(e)
                showErrorMessage(e.message)
            })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    function buildLandplots(contracts: Contract[]) {
        const map = new Map<number, LandPlotDashBoardModel>()
        contracts.forEach(c => {
            c.getUnileverHarvest().forEach(h => {
                var plot = map.get(h.land_plot.id)
                if (plot) {
                    plot.addInfo(h, c)
                } else {
                    plot = new LandPlotDashBoardModel(h.land_plot)
                    plot.addInfo(h, c)
                    map.set(h.land_plot.id, plot)
                }
            })
        })
        landplots.current = map
    }

    const getContractFiltered = (): Contract[] => {
        return contracts.filter((c) => {
            let isValid = true
            if (c.primary_buyer?.organisation?.id !== selectedOrg.id) {
                isValid = false
            }
            return isValid
        })
    }

    useEffect(() => {
        if (selectedOrg && contracts) {
            setContractsFiltered(getContractFiltered())
            updateMills(mapRef.current, organisations, selectedOrg)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrg, contracts])

    useEffect(() => {
        showLandplot(mapRef.current, contractsFiltered.length > 0 ? contractsFiltered : [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractsFiltered])

    useEffect(() => {
        if (mapRef.current) {
            updateMills(mapRef.current, organisations, selectedOrg)
            const coords = organisations.map(o => new LngLat(o.positionepsg4326.coordinates[0], o.positionepsg4326.coordinates[1]))
            zoomForCoordinates(mapRef.current, coords, 12)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations])

    function onMapLoaded(map: MapUI) {
        mapRef.current = map
        addMillCirclRadius(map, null)
        addLandPlotLayer(map, [], (feature) => {
            const plotId = feature.properties['plot_id']
            const coordinatesString = feature.properties['coordinates']
            const quantity = feature.properties['quantity']
            const contractId = feature.properties['contract_id']
            var coordinates: number[] = undefined
            if (coordinatesString) {
                coordinates = JSON.parse(coordinatesString);
            }
            addMapAnnotationPlot(mapRef.current,
                landplotsSelected.current.get(plotId),
                coordinates,
                quantity,
                contractId)
        })
        map.resize()
        addMillDashboard(mapRef.current, (geojson, source) => {
            map.on('click', source, (ev) => {
                mapEvent.current.onFeatureClick(ev.features[0])
            })
            mapEvent.current = new MapEventDashBoard(map, geojson, source, (id) => {
                const org = organisationsRef.current.find((o: Organisation) => o.id === id)
                setSelectedOrg(org)
                setShowModal(true)
            })
            if (organisations.length > 0) {
                updateMills(map, organisations, selectedOrg)
            }
        }, t)
        if (contractsFiltered.length > 0) {
            showLandplot(map, contractsFiltered.length > 0 ? contractsFiltered : [])
        }
    }

    function showLandplot(map: MapUI, contracts: Contract[]) {
        const ulHarvest = contracts.flatMap(c => {
            return c.getUnileverHarvest()
        })
        const nonULHarvest = contracts.flatMap(c => {
            return c.getNonUnileverHarvest()
        })
        // We gathered the different info from the harvest into a landplot
        const plotSorted: Map<number, LandPlotDashBoardModel> = new Map()
        ulHarvest.forEach(h => {
            var plotAggregate = plotSorted.get(h.land_plot.id)
            if (plotAggregate) {
                plotAggregate.addInfo(h)
            } else {
                plotAggregate = new LandPlotDashBoardModel(h.land_plot)
                plotAggregate.addInfo(h)
            }
            plotSorted.set(h.land_plot.id, plotAggregate)
        })
        landplotsSelected.current = plotSorted
        updateLandplotSource(map, [...plotSorted.values()], nonULHarvest)
        // We convert the harvest in LngLat picked_up position
        var pickedUp = nonULHarvest.filter(h => h.pickedUpPosition).map(h => h.pickedUpPosition).map(p => new LngLat(p[0], p[1]))
        // We take the unlilever harvest and convert them as position LngLat
        var coords: LngLat[] = [...plotSorted.values()].map(h => h.landPlot).flatMap(p => p.polygon ? p.polygon.coordinates[0].map(co => new LngLat(co[0], co[1])) : [])
        coords = [...coords, ...pickedUp]
        // we zoom based on the UL landplots
        zoomForCoordinates(mapRef.current, coords, 12, 1000)

        if (selectedOrg) {
            // We check which point is the further to determine the radius
            const refPoint = [selectedOrg.positionepsg4326.coordinates[0], selectedOrg.positionepsg4326.coordinates[1]]
            const points = coords.map(c => [c.lng, c.lat])
            var maxDistance = 0
            var endPoint = null
            points.forEach(p => {
                const d = distance(point(refPoint), point(p));
                if (d > maxDistance && d < 2000) {
                    maxDistance = d;
                    endPoint = p
                }
            });
            const cCoords = getCircleCoords({
                latitude: selectedOrg.positionepsg4326.coordinates[1],
                longitude: selectedOrg.positionepsg4326.coordinates[0]
            }, maxDistance)
            coords = cCoords.map(co => new LngLat(co[0], co[1]))
            zoomForCoordinates(mapRef.current, coords, 12, 1000)

            showMillRadius(map, selectedOrg, maxDistance, endPoint)
        }
    }

    function sortContracts(cs: Contract[]) {
        var contractsI = cs
        if (sorts.id > 0) {
            if (sorts.id === 1) {
                contractsI = [...contractsI.sort((c1, c2) => { if (c1.id > c2.id) { return 1 } else return -1 })]
            } else {
                contractsI = [...contractsI.sort((c1, c2) => { if (c1.id > c2.id) { return -1 } else return 1 })]
            }
        }
        setContractsReport(contractsI)
    }

    useEffect(() => {
        sortContracts(contracts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorts])

    function getNewSortReport(current: ReportSorts): ReportSorts {
        const sorts = {
            id: 0
        }
        return {
            ...sorts,
            ...current
        }
    }

    function getNextValue(v: number): number {
        if (v === 0) {
            return 1
        } else if (v === 1) {
            return 2
        }
        return 1
    }

    function showMillRadius(map: MapUI, mill: Organisation, radius: number, endPoint: any) {
        updateCircleRadius(map, mill, radius, endPoint)
    }

    const onStartDateSelected = (newValue) => {
        setStartDateOpen(false)
        setDateRange({ ...dateRange, startDate: newValue })
        if (dateRange.endDate) {
        } else {
            setEndDateOpen(true)
        }
    }

    const onEndDateSelected = (newValue) => {
        setEndDateOpen(false)
        setDateRange({ ...dateRange, endDate: newValue })
        if (dateRange.startDate) {
        } else {
            setStartDateOpen(true)
        }
    }

    const onModeClick = (isMap: boolean) => {
        setMapMode(isMap)
    }

    const generateSHP = () => {
        generateGeoJSONFromContracts(contracts, [...landplots.current.values()])
    }

    const DateRangeView = () => {
        return (
            <div style={{ verticalAlign: "bottom", display: "flex", alignItems: "center", gap: "20px" }}>
                <DatePicker maxDate={dayjs()} onClose={() => setStartDateOpen(false)} open={startDateOpen} onOpen={() => setStartDateOpen(!startDateOpen)} defaultValue={dateRange.startDate} onChange={(newValue) => onStartDateSelected(newValue)} label={t('chose_start_date')} />
                <p className="to" style={{}}>{t('to')}</p>
                <DatePicker maxDate={dayjs()} onClose={() => setEndDateOpen(false)} open={endDateOpen} onOpen={() => setEndDateOpen(!endDateOpen)} defaultValue={dateRange.endDate} onChange={(newValue) => onEndDateSelected(newValue)} label={t('chose_end_date')} />
                <Button style={{ background: colorPrimary }} variant="contained" onClick={() => fetchContracts()}>{t('fetch_contracts')}</Button>
            </div>
        )
    }

    const filterReportContracts = (contracts: Contract[], location: Location) => {
        setLocationFilter(location)
        var filtered = [...contracts]
        if (location?.province) {
            filtered = filtered.filter(c => {
                return c.primary_buyer?.organisation?.location?.province === location.province
            })
        }
        if (location?.district) {
            filtered = filtered.filter(c => c.primary_buyer?.organisation?.location?.district === location.district)
        }
        if (location?.subDistrict) {
            filtered = filtered.filter(c => c.primary_buyer?.organisation?.location?.subDistrict === location.subDistrict)
        }
        sortContracts(filtered)
    }

    const downloadCSV = () => {
        const table = [[t('contract_number').split(' ').join('_'),
        t('market_day').split(' ').join('_'),
        t('quantity'),
        t('unilever_quantity').split(' ').join('_'),
        t('price_paid').split(' ').join('_'),
        t('ppunch_paid').split(' ').join('_'),
        t('fcrop_awarded').split(' ').join('_'),
        t('report_traceable_column').split(' ').join('_')],
        ...contractsReport.map(c => {
            const tracabilityTotal = fruitTracability([c])
            var tracabilityText: string
            if (tracabilityTotal) {
                tracabilityText = `${(tracabilityTotal * 100).toFixed(2)}%`
            } else {
                tracabilityText = "No_UL_Harvest"
            }
            return [
                c.id,
                c.getMarketDay().split(' ').join('_'),
                fruitQuantity([c]).toFixed(0),
                fruitUnileverPlotQuantity([c]).toFixed(0),
                totalAmount([c]).toFixed(0),
                totalPpunch([c]).toFixed(0),
                totalFcrop([c]).toFixed(0),
                tracabilityText]
        })]

        let csvContent = "data:text/csv;charset=utf-8,"
            + table.map(e => e.join(",")).join("\n")

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const downloadSHP = () => {
        generateSHP()
    }

    const sortBy = (column: string) => {
        if (column === "id") {
            setSort({ ...getNewSortReport({ id: getNextValue(sorts.id) }) })
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {isDashboardEnabled ?
                <Routes>
                    <Route path="/" element={
                        <div style={{ height: '100%', display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{
                                    marginTop: "12px",
                                    padding: "10px 20px",
                                    boxShadow: mapMode ? "" : "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                                    zIndex: "10",
                                    paddingBottom: "12px",
                                    display: "flex",
                                    flex: "1",
                                }}>
                                    <div style={{ display: "flex", flex: "2" }}>
                                        <DateRangeView />
                                    </div>
                                    <div style={{ flex: "1", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "right" }}>
                                            <div style={{ display: "flex", gap: "12px", height: "46px" }}>
                                                <div className={mapMode ? "dashboard-type-container-selected" : "dashboard-type-container"} onClick={() => onModeClick(true)}>
                                                    <MapIcon style={{ height: "100%" }} className={mapMode ? "dashboard-type-selected" : "dashboard-type"} />
                                                </div>
                                                <div className={mapMode ? "dashboard-type-container" : "dashboard-type-container-selected"} onClick={() => onModeClick(false)}>
                                                    <ListIcon style={{ height: "100%" }} className={mapMode ? "dashboard-type" : "dashboard-type-selected"} />
                                                </div>
                                            </div>
                                            <Button variant="contained"
                                                style={{ marginLeft: "60px", borderRadius: "100px", color: "white", fontWeight: "700", height: "46px", backgroundColor: colorPrimary }}
                                                onClick={() => setShowOnboardingModal(true)}>{t("onboarding")}</Button>
                                        </div>
                                    </div>
                                    <SideBarOnboarding showModal={showOnboardingModal}
                                        setShowModal={setShowOnboardingModal}
                                        client={props.client} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flex: "1", width: "100%" }}>
                                {
                                    mapMode ?
                                        <div style={{ position: "relative", overflow: "hidden", height: "100%", display: "flex", width: "100%" }} id="drawer-container">
                                            <MapBox lng={defaultIndoCoo[1]} lat={defaultIndoCoo[0]} onLoaded={(map) => onMapLoaded(map)} height="100%" />
                                            <div style={{
                                                padding: "10px", background: "#E9F0F0", position: "absolute", display: "flex", flexDirection: "column",
                                                right: "10px",
                                                bottom: "30px",
                                                gap: "20px"
                                            }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                    <div style={{ width: "30px", display: "flex", justifyContent: "center" }}>
                                                        <div style={{ width: "30px", height: "30px", backgroundColor: colorRed, borderRadius: "50%" }}></div>
                                                    </div>
                                                    <p>{t('picked_up_location')}</p>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                    <div style={{ width: "30px", height: "20px", background: colorPrimary }}></div>
                                                    <p>{t('unliver_program_label')}</p>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                    <div style={{ width: "30px", height: "20px", background: "red" }}></div>
                                                    <p>{t('not_unliver_program_label')}</p>
                                                </div>
                                            </div>
                                            <SideBarDashBoard
                                                showModal={showModal}
                                                setShowModal={setShowModal}
                                                contracts={contractsFiltered ? contractsFiltered : []}
                                                org={selectedOrg}
                                                onDownloadSHP={generateSHP} />
                                        </div>
                                        : <DashboardList downloadSHP={generateSHP} organisations={organisations} contracts={contracts} ></DashboardList>
                                }
                            </div>
                        </div>} />
                    <Route path="report" element={
                        <div style={{ height: '100%', display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex" }}>
                                <Grid container
                                    style={{
                                        marginTop: "12px",
                                        zIndex: "10",
                                        padding: "10px 20px",
                                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                                    }}>
                                    <Grid item xs={4} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                        <DateRangeView />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                        <LocationFilter client={props.client} contracts={contracts} onLocationChange={(l) => filterReportContracts(contracts, l)} />
                                    </Grid>
                                    <Grid item xs={2} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                        <DownloadDashboardButton onDownloadCSV={downloadCSV} onDownloadSHP={downloadSHP} />
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid item flex={1}>
                                <Report sortBy={sortBy} contracts={contractsReport} setContracts={setContractsReport} client={props.client} />
                            </Grid>
                        </div>}></Route>
                </Routes>
                :
                <div style={{
                    display: "flex",
                    border: "1px solid black",
                    borderRadius: "5px",
                    position: "relative",
                    flex: "1",
                    backgroundColor: colorYellowLight,
                    minHeight: "100px",
                    margin: "30px 20px 0px 28px",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <p style={{ margin: "0px", fontSize: "2em" }}>The Dasboard is currently under maintenance</p>
                </div>
            }
            <Loader isLoading={loading} />

        </LocalizationProvider>
    )
}

export default Dashboard