import React from 'react';

type AvatarProps = {
    style?: React.CSSProperties,
    uri?: string,
    firstname: string,
    lastname: string,
}

// { width = "40px", height = "40px"}

function Avatar({ style, uri, firstname, lastname }: AvatarProps) {
    if (uri != null) {
        return <div style={{ display: "inline-block", verticalAlign: "middle" }}><img alt="avatar" style={{ ...style, borderRadius: "50%" }} src={uri}>
        </img></div>
    } else {
        return <div className='primaryDarkBgColor' style={{
            ...style,
            display: "inline-block", verticalAlign: "middle", textAlign: "center", borderRadius: "50%"
        }}>
            <div style={{ transform: "translateY(-50%)", color: "white", position: "relative", top: "50%", }}>{firstname?.charAt(0)}{lastname?.charAt(0)}</div>
        </div>
    }
}

export default Avatar