import { useEffect, useState } from "react"
import { SearchLocation } from "../../api"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import React from "react"
import { TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Location, buildLocationFromDetail } from "../../models/organisation/Location"

type SettingsProps = {
    client: ApolloClient<NormalizedCacheObject>,
    onLocationSelected: (location: Location) => void
}

export function LocationPicker(props: SettingsProps) {

    const { t } = useTranslation()

    const [locations, setLocations] = useState<Location[]>([])
    const [userInput, setUserInput] = useState("")

    useEffect(() => {
        searchLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInput])

    function searchLocation() {
        props.client.query({
            query: SearchLocation,
            variables: {
                locationText: userInput
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }
        ).then((res) => {
            setLocations(res.data.locations.map(l => buildLocationFromDetail(l)))
        })
    }

    function locationRow(location: Location) {
        return (<div key={location.id} className="py-[20px] cursor-pointer hoverGreenPale" onClick={() => props.onLocationSelected(location)}>
            {t('village_district', { village: location.village, subDistrict: location.subDistrict })}
        </div>)
    }

    return (
        <div style={{ width: "400px", overflow: "hidden" }}>
            <div className="flex flex-col gap-[10px] items-center mb-[20px]">
                <p className="text-xl">{t('choose_location')}</p>
                <p>{t('choose_location_message')}</p>
            </div>
            <TextField className="w-[100%]" placeholder={t('village_name')} value={userInput} onChange={e => { setUserInput(e.target.value) }} />
            <div className="max-h-[200px] overflow-y-auto">
                {
                    locations.map(l => locationRow(l))
                }
            </div>
        </div>
    )
}