import { Button, Dialog, DialogContent, Drawer, Grid } from "@mui/material"
import { t } from "i18next"
import React, { useState } from "react"
import { allFarmers, allFarmersDeclared, allFarmersUndeclared, averageFcrop, fruitNonUnileverPlotQuantity, fruitUnileverPlotQuantity, generatePlotInfo, percentPpunchOnGMV, totalFcrop, totalPpunch } from "../../helper/contractHelper"
import { Contract } from "../../models/contract/Contract"
import { Organisation } from "../../models/organisation/Organisation"
import { ReactComponent as CloseIcon } from "../../rsc/close.svg"
import { colorPrimary } from "../../rsc/colors/colors"
import { ReactComponent as MillIcon } from "../../rsc/ic_mill.svg"
import { ListUndeclaredPlots } from "./ListUndeclaredPlots"
import "./SideBarDashBoard.css"

type SideBarDashBoardProps = {
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    contracts: Contract[]
    org: Organisation
    onDownloadSHP: () => void
}

export function SideBarDashBoard({ showModal, setShowModal, contracts, org, onDownloadSHP }: SideBarDashBoardProps) {

    const [showNonDeclared, setShowNonDeclared] = useState(false)

    const plotsInfo = generatePlotInfo(contracts)
    const farmers = allFarmers(contracts)
    const farmersDeclared = allFarmersDeclared(contracts)
    const farmersNotDeclared = [...allFarmersUndeclared(contracts, farmersDeclared).values()]
    return (
        <Drawer
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "400px",
                    boxSizing: 'border-box',
                    position: "absolute",
                    zIndex: 5,
                    padding: "10px 20px",
                    background: "radial-gradient(50% 50% at 50% 50%, #9FB5B6 0%, #E9F0F0 100%)"
                },
            }}
            ModalProps={{
                container: document.getElementById('drawer-container'),
                style: { position: 'absolute' }
            }}
            variant="persistent"
            anchor="left"
            open={showModal}>
            <div style={{ display: "flex", alignItems: "center", margin: "10px 0px", gap: "12px" }}>
                <MillIcon width="30px" height="30px" />
                <p style={{ flex: 1, lineHeight: "normal", fontWeight: "700", paddingTop: "4px" }}>{org?.name}</p>
                <CloseIcon className="sidebar-close-button" onClick={() => { setShowModal(false) }} />
            </div>
            <Grid container style={{ marginTop: "40px" }}>
                <Grid item xs={12}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('contratcs_dashboard_column')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{contracts.length}</p>
                    </div>
                </Grid>
            </Grid>
            <Grid container columnSpacing="3px" style={{ marginTop: "3px" }}>
                <Grid item xs={6}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('landplot_dashboard_column')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{plotsInfo.filter(p => p.isUnileverLandPlot()).length}</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('non_ul_landplot_dashboard_column')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{plotsInfo.filter(p => !p.isUnileverLandPlot()).length}</p>
                    </div>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }}>
                <Grid className="sidebar-section-container" direction={"column"} container>
                    <p className="sidebar-section-title">{t("farmers")}</p>
                    <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                        <p className="sidebar-number">{[...farmers].length}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columnSpacing="3px" style={{ marginTop: "3px" }}>
                <Grid item xs={6}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t('farmer_confirmed_plot')}</p>
                        <p className="sidebar-number" style={{ marginTop: "4px" }}>{[...farmersDeclared].length}</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Button className="sidebar-section-container" onClick={() => setShowNonDeclared(true)} style={{ backgroundColor: "white", borderRadius: "0px", width:"100%", height:"100%" }}>
                        <p className="sidebar-section-title">{t('farmer_unconfirmed_plot')}</p>
                        <p className="sidebar-number primaryColor" style={{ marginTop: "4px" }}>{farmersNotDeclared.length}</p>
                    </Button>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }}>
                <Grid className="sidebar-section-container" direction={"column"} container>
                    <p className="sidebar-section-title">{t("fruit_unilever_volume")}</p>
                    <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                        <p className="sidebar-number">{fruitUnileverPlotQuantity(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Kg</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }}>
                <Grid className="sidebar-section-container" direction={"column"} container>
                    <p className="sidebar-section-title">{t("fruit_non_unilever_volume")}</p>
                    <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                        <p className="sidebar-number">{fruitNonUnileverPlotQuantity(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Kg</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }}>
                <Grid className="sidebar-section-container" container direction={"column"}>
                    <p className="sidebar-section-title">{t('fcrop_given')}</p>
                    <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                        <p className="sidebar-number">{totalFcrop(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Rp</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }}>
                <Grid className="sidebar-section-container" container direction={"column"}>
                    <p className="sidebar-section-title">{t("average_fcrop")}</p>
                    <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                        <p className="sidebar-number">{averageFcrop(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Rp</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "3px" }} columnSpacing="3px">
                <Grid item xs={6}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("ppunch_given")}</p>
                        <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                            <p className="sidebar-number">{(totalPpunch(contracts) / 1000 / 1000).IndoFormat(2)}</p>
                            <p className="sidebar-suffix">jt</p>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="sidebar-section-container">
                        <p className="sidebar-section-title">{t("ppunch_on_gmv")}</p>
                        <Grid style={{ margin: "4px" }} container direction="row" justifyContent="center" alignItems="flex-end">
                            <p className="sidebar-number">{(percentPpunchOnGMV(contracts)).IndoFormat(3)}</p>
                            <p className="sidebar-suffix">%</p>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Button variant="contained" style={{ marginTop: "20px", borderRadius: "100px", color: "white", fontWeight: "700", height: "46px", backgroundColor: colorPrimary }} onClick={onDownloadSHP}>{t("download_shapefile")}</Button>
            <div style={{ flex: "1", display: "flex", alignItems: "end", marginTop: "12px" }}>
                <p className="sidebar-warning-message">
                    {t('unilever_data_warning')}
                </p>
            </div>
            <Dialog
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: "1100px"
                    }
                }}
                open={showNonDeclared}
                onClose={() => setShowNonDeclared(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <ListUndeclaredPlots persons={farmersNotDeclared}/>
                </DialogContent>
            </Dialog>
        </Drawer>
    )
}