import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Button, Dialog, DialogContent, SxProps, TextField, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FetchProfile, UpdateProfile } from "../../api";
import { personId, userId } from "../../helper/TokenHelper";
import { useTranslation } from "react-i18next";
import { Person, buildPersonForProfile } from "../../models/person/Person";
import { LoadingButton } from "@mui/lab";
import { PasswordWizard } from "../../components/PasswordWizard";
import { ReactComponent as EditIcon } from "../../rsc/ic_edit.svg"
import Avatar from "../../components/Avatar";
import { showSuccessMessage } from "../../helper/helper";
import { firebaseAuth } from "../login/firebaseInit";
import { EmailAuthProvider } from "firebase/auth";

type ProfileProps = {
    client: ApolloClient<NormalizedCacheObject>,
}

export function Profile(props: ProfileProps) {

    const { t } = useTranslation()


    const [user, setUser] = useState<Person>()
    const [firstname, setFirstname] = useState<string>()
    const [lastname, setLastname] = useState<string>()
    const [nickname, setNickname] = useState<string>()
    const [email, setEmail] = useState({email: "", provider: ""})

    const [editMode, setEditMode] = useState<boolean>(false)

    const [saving, setSaving] = useState<boolean>(false)
    const [showPwdWizard, setShowPwdWizard] = useState<boolean>(false)

    useEffect(() => {
        props.client.query({
            query: FetchProfile,
            variables: {
                userId: userId()
            }
        }).then(data => {
            const person = buildPersonForProfile(data.data.person[0])
            setUser(person)
            setFirstname(person.firstname)
            setLastname(person.lastname)
            getUserEmail()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function updateProfile() {
        setSaving(true)
        props.client.mutate({
            mutation: UpdateProfile,
            variables: {
                personId: personId(),
                person: {
                    firstname: firstname,
                    lastname: lastname,
                    nickname: nickname
                }
            }
        }).then(() => {
            setEditMode(false)
        })
            .finally(() => {
                setSaving(false)
            })
    }

    function onChangePasswordSucceed() {
        setShowPwdWizard(false)
        showSuccessMessage(t('change_pwd_success'))
    }

    function getTextFieldEditStyle(): SxProps<Theme> {
        if (editMode) {
            return {}
        } else {
            return {
                '& .MuiInput-underline:before': {
                    border: "none"
                }
            }
        }
    }

    function getUserEmail() {
        firebaseAuth().currentUser.providerData.filter(e => e.providerId === EmailAuthProvider.PROVIDER_ID
            ).forEach(e => {
                setEmail({email: e.email, provider: e.providerId})
        })
    }

    const LabelStyle = "text-[18px] capitalize w-[100px]"

    return (<div className="px-[28px]">
        {
            user ?
                <div className="flex gap-[30px]">
                    <Avatar uri={user.user.avatar_url} firstname={user.firstname} lastname={user.lastname} style={{ width: "150px", height: "150px", marginTop: "40px", fontSize: "50px" }} />
                    <div className="flex flex-col gap-[30px] mt-[40px]">
                        <div className="flex flex-row items-center gap-[20px]">
                            <p className={LabelStyle}>{t('firstname')}</p>
                            <TextField className="w-[300px]"
                                value={firstname}
                                disabled={!editMode}
                                variant="standard"
                                InputProps={{ disableUnderline: !editMode }}
                                onChange={e => setFirstname(e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-[20px]">
                            <p className={LabelStyle}>{t('lastname')}</p>
                            <TextField
                                className="w-[300px]"
                                disabled={!editMode}
                                variant="standard"
                                value={lastname}
                                InputProps={{ disableUnderline: !editMode }}
                                onChange={e => setLastname(e.target.value)}
                                sx={getTextFieldEditStyle()} />
                        </div>
                        <div className="flex flex-row items-center gap-[20px]">
                            <p className={LabelStyle}>{t('nickname')}</p>
                            <TextField
                                className="w-[300px]"
                                disabled={!editMode}
                                variant="standard"
                                InputProps={{ disableUnderline: !editMode }}
                                value={nickname}
                                onChange={e => setNickname(e.target.value)}
                                sx={getTextFieldEditStyle()} />
                        </div>
                        <div className="flex items-end gap-[30px] mt-[30px]">
                            <div className="flex flex-row items-center gap-[20px]">
                                <p className={LabelStyle}>{t('email')}</p>
                                <TextField variant="standard" value={email.email} disabled={true} />
                            </div>
                            <Button disabled={email.provider !== EmailAuthProvider.PROVIDER_ID} onClick={() => setShowPwdWizard(true)} className="h-[40px]" variant="contained">{t('change_password')}</Button>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-end mt-[40px]">
                        <div className="flex justify-end">
                            {!editMode ?
                                <Button onClick={() => setEditMode(!editMode)} style={{ border: "solid black 2px" }} className="border-[2px] border-[black] rounded-[3px] h-[40px]">
                                    <EditIcon className="w-[20px] h-[20px] mr-[7px]" />
                                    <p style={{ color: "black" }}>{t('edit')}</p>
                                </Button>
                                : <></>}
                        </div>
                    </div>
                    {
                        editMode ?
                            <div className="flex justify-end mt-[30px] items-end" >
                                <LoadingButton className="h-[40px]" variant="contained" loading={saving} onClick={() => updateProfile()}>{t('save')}</LoadingButton>
                            </div>
                            : <></>
                    }
                </div>
                : <></>
        }
        <Dialog
            open={showPwdWizard}
            onClose={() => setShowPwdWizard(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <PasswordWizard onCancel={() => setShowPwdWizard(false)} currentEmail={email.email} onChangePasswordSucceed={() => onChangePasswordSucceed()} />
            </DialogContent>
        </Dialog>

    </div>)

}