import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import id from "./rsc/strings/id"
import en from "./rsc/strings/en-US"

const LANGUAGA_STORAGE = "language_storage"

const browserLanguage = navigator.language;

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en
  },
  id: {
    translation: id
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: browserLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    fallbackLng: "en", //when specified language translations not present 

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  i18n.on('languageChanged', (l) => {
    localStorage.setItem(LANGUAGA_STORAGE, l)
  })

  i18n.changeLanguage(localStorage.getItem(LANGUAGA_STORAGE))

  export default i18n;