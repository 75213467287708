import React, { LegacyRef, ReactElement } from "react"
import { colorYellowStrong } from "../rsc/colors/colors";

type MarketCardProps = {
    renderIcon?: () => ReactElement;
    style?: React.CSSProperties;
    turn: string
    isSelected: boolean
    parentRef?: LegacyRef<HTMLDivElement>
}

export function MarketCard({ renderIcon, style, turn, isSelected, parentRef }: MarketCardProps) {


    const selectedStyle: React.CSSProperties = {
        borderRadius: "5px",
        border: `3px solid ${colorYellowStrong}`,
    }

    const unSelectedStyle: React.CSSProperties = {
    }

    const getStyle = () => {
        const selectStyle = isSelected ? selectedStyle : unSelectedStyle
        return {
            ...selectStyle,
            ...style
        }
    }

    const getClass = (): string => {
        return isSelected ? "yellowMediumBgColor" : "yellowLightBgColor"
    }

    return (
        <div ref={parentRef} style={getStyle()} className={getClass()}>
            {renderIcon()}
            <p style={{
                display: "flex", color: "black", margin: "0px", flex: "2", justifyContent: "center", paddingTop:"10px", fontWeight: `${isSelected ? "550" : "400"}`
            }}>{turn}</p>
        </div>
    )
}