import { Transactor, buildTransactorFromDetail } from "../person/Transactor"

export class BidModel {
    id?: number
    price_per_quantity_unit?: number
    organisationId?: number
    market_day?: string
    transactor: Transactor
    createdAt: string
    updated_by: string
    updated_at: string

    constructor(id?: number,
        price_per_quantity_unit?: number,
        organisationId?: number,
        market_day?: string,
        transactor?: Transactor,
        created_at?: string,
        updated_at?: string,
        updated_by?: string
    ) {
        this.id = id
        this.price_per_quantity_unit = price_per_quantity_unit
        this.organisationId = organisationId
        this.market_day = market_day
        this.transactor = transactor
        this.createdAt = created_at
        this.updated_at = updated_at
        this.updated_by = updated_by
    }
}

export function buildBidModel(p: any): BidModel {
    return new BidModel(p.id,
        p.price_per_kg,
        p.organisation_id,
        p.market_day,
        p.transactor ? buildTransactorFromDetail(p.transactor) : undefined,
        p.created_at,
        p.updated_at,
        p.updated_by)
}

export function buildMyPriceUpdate(p: any): BidModel {
    return new BidModel(p.bid_id, p.price_per_kg, p.organisation_id, p.market_day)
}