
export class Landplot {
    id: number
    polygon: any
    name: string
    pickup_position: any
    externalId: string
    size?: number
    isPlotInProgram?: boolean

    constructor(id: number, polygon?: any, name?: string, pickup_position?: any, externalId?: string, size?: number, isPlotInProgram?: boolean) {
        this.id = id
        this.polygon = polygon
        this.name = name
        this.pickup_position = pickup_position
        this.externalId = externalId
        this.size = size
        this.isPlotInProgram = isPlotInProgram
    }
}

export function buildLandPlot(plot: any): Landplot {
    return new Landplot(plot.id, plot.polygon, plot.name, plot.pickup_position, plot.external_id, plot.size, plot.isPlotInProgram)
}