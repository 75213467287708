import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { Contract } from "../../models/contract/Contract"
import { Location } from "../../models/organisation/Location"
import { MapCount } from "./models/MapCount"

type LocationFilterProps = {
    client: ApolloClient<NormalizedCacheObject>
    onLocationChange: (location: Location) => void
    contracts: Contract[]
}

export function LocationFilter({ client, onLocationChange, contracts }: LocationFilterProps) {

    const ALL_PROVINCES = t('all_provinces')
    const ALL_DISTRICTS = t('all_districts')
    const ALL_SUBDISTRICTS = t('all_sub_districts')

    const [selectedLocation, setSelectedLocation] = useState<Location>()

    const [provinces, setProvinces] = useState<string[]>([ALL_PROVINCES])
    const [districts, setDistricts] = useState<string[]>([ALL_DISTRICTS])
    const [subDistricts, setSubDistricts] = useState<string[]>([ALL_SUBDISTRICTS])

    const [locations, setLocations] = useState<MapCount>(new MapCount())


    useEffect(() => {
        const allProvinces = new MapCount()
        contracts.forEach((c) => {
            const location = c.primary_buyer.organisation.location
            if (location) {
                const province = allProvinces.getOrCreate(c.primary_buyer.organisation.location.province)
                const district = province.getOrCreate(c.primary_buyer.organisation.location.district)
                district.getOrCreate(c.primary_buyer.organisation.location.subDistrict)    
            }
        })
        setLocations(allProvinces)
        const ps = [ALL_PROVINCES, ...allProvinces.map.keys()]
        setProvinces(ps.map(p => p.toString()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts])

    function getLocationFromFilters() {
        if (selectedLocation?.province) {
            const ds = locations.map.get(selectedLocation.province) ?? new MapCount()
            const dsFiltered = [ALL_DISTRICTS, ...ds?.map.keys()]
            setDistricts(dsFiltered.map(d => d.toString()))
            if (selectedLocation?.district) {
                const sb = ds.map.get(selectedLocation.district) ?? new MapCount()
                const sbFiltered = [ALL_SUBDISTRICTS, ...sb?.map.keys()]
                setSubDistricts(sbFiltered.map(s => s.toString()))
            }
        }
    }

    useEffect(() => {
        getLocationFromFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const location = new Location(-1)
        if (!selectedLocation?.province || selectedLocation?.province === ALL_PROVINCES) {
        } else {
            location.province = selectedLocation.province
        }
        if (!selectedLocation?.district || selectedLocation?.district === ALL_DISTRICTS) {
            setDistricts([ALL_DISTRICTS])
            location.district = null
        } else {
            location.district = selectedLocation.district
        }
        if (!selectedLocation?.subDistrict || selectedLocation?.subDistrict === ALL_PROVINCES) {
            setSubDistricts([ALL_SUBDISTRICTS])
            getLocationFromFilters()
            location.subDistrict = null
        } else {
            location.subDistrict = selectedLocation.subDistrict
        }

        onLocationChange(location)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation])

    const isDefaultProvince = (province: string): boolean => {
        return t('all_provinces') === province
    }

    const isDefaultDistrict = (province: string): boolean => {
        return t('all_districts') === province
    }

    const isDefaultSubDistrict = (province: string): boolean => {
        return t('all_sub_districts') === province
    }

    return (
        <Grid container style={{ marginLeft: "20px", gap: "16px" }}>
            <FormControl className="w-[200px]">
                <InputLabel>
                    {t('all_provinces')}
                </InputLabel>
                <Select
                    MenuProps={{ disableScrollLock: true, marginThreshold: null }}
                    value={selectedLocation?.province ?? ALL_PROVINCES}
                    label={ALL_PROVINCES}
                    onChange={e => {
                        const fp = isDefaultProvince(e.target.value) ? null : e.target.value; setSelectedLocation({
                            ...selectedLocation, district: null, subDistrict: null,
                            province: fp
                        })
                    }}>
                    {
                        provinces.map(p => {
                            return (
                                <MenuItem key={p} value={p}>{p}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl className="w-[200px]">
                <InputLabel>
                    {ALL_DISTRICTS}
                </InputLabel>
                <Select
                    MenuProps={{ disableScrollLock: true, marginThreshold: null }}
                    value={selectedLocation?.district ?? ALL_DISTRICTS}
                    label={ALL_DISTRICTS}
                    disabled={!selectedLocation?.province}
                    onChange={e => { const fd = isDefaultDistrict(e.target.value) ? null : e.target.value; setSelectedLocation({ ...selectedLocation, subDistrict: null, district: fd }) }}>
                    {
                        districts.map(p => {
                            return (
                                <MenuItem key={p} value={p}>{p}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl className="w-[200px]">
                <InputLabel>
                    {ALL_SUBDISTRICTS}
                </InputLabel>
                <Select
                    MenuProps={{ disableScrollLock: true, marginThreshold: null }}
                    value={selectedLocation?.subDistrict ?? ALL_SUBDISTRICTS}
                    label={ALL_SUBDISTRICTS}
                    disabled={!selectedLocation?.district}
                    onChange={e => { const fsd = isDefaultSubDistrict(e.target.value) ? null : e.target.value; setSelectedLocation({ ...selectedLocation, subDistrict: fsd }) }}>
                    {
                        subDistricts.map(p => {
                            return (
                                <MenuItem key={p} value={p}>{p}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Grid>
    )
}