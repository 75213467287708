import { convertToDateRaw } from "../../helper/helper"
import { SaleItem, buildSaleItem } from "./SaleItem"
import { SaleStatement, buildSaleStatement } from "./SaleStatement"

export class ContractList {
    id: number
    status: string
    market_day?: string
    invoiceId?: number
    sale_items?: SaleItem[]
    sale_id?: number
    contract_type?: string
    statement?: SaleStatement

    constructor(id: number,
        status: string,
        market_day?: string,
        sale_items?: SaleItem[],
        invoiceId?: number,
        sale_id?: number,
        contract_type?: string,
        statement?: SaleStatement) {
        this.id = id
        this.status = status
        this.market_day = market_day
        this.sale_items = sale_items
        this.invoiceId = invoiceId
        this.sale_id = sale_id
        this.contract_type = contract_type
        this.statement = statement
    }

    getMarketDay(): string {
        if (this.market_day) {
            return convertToDateRaw(this.market_day, "DD MMMM YYYY")
        } else {
            return "undefined"
        }
    }

    getTotal(): number {
        const lineItem = this.sale_items
        if (lineItem) {
            return lineItem.reduce(((value, item) => value + item.getTotal()), 0)
        }
        return null
    }

    getQuantity(): number {
        const lineItem = this.sale_items
        if (lineItem) {
            return lineItem.reduce(((value, item) => value + item.quantity), 0)
        }
        return null
    }

    getPricePerUnit(): number {
        const lineItem = this.sale_items
        if (lineItem) {
            return this.getTotal()/this.getQuantity()
        }
        return null
    }

    getDeliveryDate(): string {
        return this.sale_items[this.sale_items.length-1]?.createdAt
    }

    isNFAContract() {
        return this.contract_type === "NAM"
    }
}

export function buildContractList(s: any): ContractList {
    return new ContractList(
        s.transport_crew_contract_id,
        s.transport_crew_contract.status,
        s.transport_crew_contract.market_day,
        s.sale_statement.line_items.map(s => buildSaleItem(s)),
        undefined,
        s.sale_statement.id,
        s.transport_crew_contract.contract_type,
        s.sale_statement ? buildSaleStatement(s.sale_statement): undefined)
}