import { Button } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { ContractList } from "../../../models/contract/ContractList"

type ConfirmInvoiceDialog = {
    invoiceContracts: ContractList[],
    onAccept: (contracts: ContractList[]) => void,
    onDecline: () => void
}

export function ConfirmInvoiceCreation({ invoiceContracts, onAccept, onDecline }: ConfirmInvoiceDialog) {

    const {t} = useTranslation()

    function contractRow(c: ContractList) {
        return (<div className="flex">
            <p className="flex-1 text-center">{c.id}</p>
            <p className="flex-1 text-center">{c.getTotal().IndoFormat()}</p>
        </div>)
    }

    return (<div>
        <p>{t('create_invoice_confirmation')}</p>
        <div className="flex flex-col gap-[10px] mt-[30px]">
            <div className="flex lightGreyBgColor py-[10px] ">
                <p className="flex-1 text-center">{t("contract_id")}</p>
                <p className="flex-1 text-center">{t('total')}</p>
            </div>
            {
                invoiceContracts.map(c => contractRow(c))
            }
        </div>
        <div className="flex justify-end gap-[20px] mt-[20px]">
            <Button variant="contained" onClick={() => onAccept(invoiceContracts)}>{t('accept')}</Button>
            <Button variant="contained" onClick={() => onDecline()}>{t('decline')}</Button>
        </div>
    </div>)
}