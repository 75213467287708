import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { defaultLat, defaultLng } from '../helper/helper';

import "../components/Map.css"
import { Button, Menu, MenuItem } from '@mui/material';

interface MapBoxProps {
    lng?: number,
    lat?: number,
    width?: string,
    height?: string,
    stateToReload?: any,
    showExpand?: boolean,
    onLoaded: (map: Map) => void,
    onEditPosition?: (map: Map) => void,
    onSavePostion?: (map: Map) => void,
    onCancelEditPosition?: (map: Map) => void,
    onFlyTo?: React.Dispatch<any>
}

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */

function MapBox({ lng = defaultLng,
    lat = defaultLat,
    width = "calc(100%)",
    height = "700px",
    stateToReload = null,
    showExpand = false,
    onLoaded = () => { },
    onEditPosition = null,
    onSavePostion = () => { },
    onCancelEditPosition = () => { },
    onFlyTo = null
}: MapBoxProps) {

    const { t } = useTranslation()

    var mapContainer = useRef(null);
    var map = useRef<Map>(null);
    const [stateLng] = useState(lng);
    const [stateLat] = useState(lat);
    const [zoom] = useState(13);
    const [isMapExpand, setIsMapExpand] = useState(false)
    const [currentMapSize, setCurrentMapSize] = useState(height)
    const [editMode, setEditMode] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const mapExpandHeight = "500px"
    const mapReducedHeight = "200px"

    useEffect(() => {
        if (map.current?.loaded() === true) {
            onLoaded(map.current)
            map.current.flyTo({
                center: [lng, lat],
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateToReload])

    useEffect(() => {
        if (map.current) return; // initialize map only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [stateLng, stateLat],
            zoom: zoom
        });
        map.current.on("load", () => {
            onLoaded(map.current)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        map.current.flyTo({
            center: [stateLng, stateLat],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
        if (onFlyTo) {
            onFlyTo(() => {return (coord: number[]) => flyTo(coord)})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const flyTo = (coord: number[]) => {
        map.current.flyTo({
            center: [coord[0], coord[1]],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
    }

    const ChangeMapSize = () => {
        var mapExtand = !isMapExpand
        setCurrentMapSize(mapExtand ? mapExpandHeight : mapReducedHeight)
        setIsMapExpand(mapExtand)
        setTimeout(() => {
            map.current.resize()
        }, 100)
    }

    const editMenuOption = () => {
        return <div style={{ display: "inline-block", width: "max-content" }}>
            <p className='map-edit-button' style={{ background: "#fff", borderRadius: "3px", margin: "0px", padding: "5px", border: "2px solid black", display: "inline-block" }} onClick={() => {
                onSavePostion(map.current)
                setEditMode(false)
            }}>{t('save')}</p>
            <p className='map-edit-button' style={{ background: "#fff", borderRadius: "3px", margin: "0px 0px 0px 10px", padding: "5px", border: "2px solid black", display: "inline-block" }} onClick={() => {
                setEditMode(false)
                onCancelEditPosition(map.current)
            }}>{t('cancel')}</p>
        </div>
    }

    const editModeBox = () => {
        if (editMode) {
            return <div style={{
                padding: "10px",
                top: "5px",
                display: "inline-block",
                position: "absolute",
                width: "fit-content",
                left: "0",
                right: "0",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                {editMenuOption()}
            </div>
        } else {
            <></>
        }
    }

    function shouldShowMenu(): boolean {
        return showExpand || onEditPosition !== null
    }

    function showMenu() {
        return <div style={{ zIndex: "2", position: "absolute", right: "40px", top: "15px" }}>
            <Button style={{ background: "#fff", marginTop:"20px" }} onClick={(e) => handleClick(e)}>{t('options')}</Button>
            <Menu open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <MenuItem onClick={() => { ChangeMapSize(); handleClose() }}>{isMapExpand ? t("reduce") : t("expand")}</MenuItem>
                <MenuItem onClick={() => { setEditMode(true); onEditPosition(map.current); handleClose() }}>{t('edit_mill_position')}</MenuItem>
            </Menu>
        </div>
    }

    return (
        <div style={{ width: width, height: currentMapSize }}>
            <div id="map" style={{ width: "100%", height: "100%" }} ref={mapContainer}></div>
            {editModeBox()}
            {
                shouldShowMenu() ?
                    showMenu()
                    : <></>
            }
        </div>
    )
}

export default MapBox
