import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Grid, Tooltip } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { getUserRoles } from "../../helper/TokenHelper";
import { fruitQuantity, fruitTracability, fruitUnileverPlotQuantity, totalAmount, totalFcrop, totalPpunch } from "../../helper/contractHelper";
import { Contract } from "../../models/contract/Contract";
import { colorPrimary } from "../../rsc/colors/colors";
import { ReactComponent as InfoIcon } from "../../rsc/ic_info.svg";
import { ReactComponent as SortIcon } from "../../rsc/sort.svg";
import { ReportBarSummary } from "./ReportBarSumary";


type ReportProps = {
    contracts: Contract[],
    setContracts: React.Dispatch<React.SetStateAction<Contract[]>>,
    client: ApolloClient<NormalizedCacheObject>,
    sortBy: (column: string) => void
}

export function Report({ contracts, sortBy, client }: ReportProps) {

    const isOperator = getUserRoles().findIndex(r => r === 'operator') !== -1

    type ContractItemProps = {
        contract: Contract
    }

    const ContractItem = ({ contract }: ContractItemProps) => {
        const tracabilityTotal = fruitTracability([contract])
        var tracabilityText: string
        if (tracabilityTotal) {
            tracabilityText = `${(tracabilityTotal * 100).IndoFormat(2)}%`
        } else {
            tracabilityText = "No UL Harvest"
        }
        return (
            <div style={{ display: "flex", flex: "1", gap: "4px", marginTop: "4px" }}>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row" style={{ fontWeight: "700", justifyContent: "start" }}>{contract.id}</p>
                </div>
                {
                    isOperator ?
                        <div style={{ flex: "2" }} className="table-row-dashboard">
                            <p className="table-header-dashboard-row">{contract.contract_type}</p>
                        </div>
                        : <></>
                }
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{contract.getMarketDay()}</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{fruitQuantity([contract]).IndoFormat(0)} kg</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{fruitUnileverPlotQuantity([contract]).IndoFormat(0)} Kg</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{totalAmount([contract]).IndoFormat()} rp</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{totalPpunch([contract]).IndoFormat()} rp</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{totalFcrop([contract]).IndoFormat()} rp</p>
                </div>
                <div style={{ flex: "2" }} className="table-row-dashboard">
                    <p className="table-header-dashboard-row">{`${tracabilityText}`}</p>
                </div>
            </div>
        )
    }

    type ContractTableProps = {
        contracts: Contract[]
    }

    const ContractTable = ({ contracts }: ContractTableProps) => {

        return (
            <div style={{ display: "flex", flex: "1", flexDirection: "column", marginTop: "20px" }}>
                <div style={{ display: "flex", flex: "1", gap: "4px" }}>
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <div style={{ display: "flex", flexDirection: "row" }} className="table-header-dashboard-title">
                            <p style={{ flex: "1" }}>{t('contract_number')}</p>
                            <SortIcon style={{ fill: colorPrimary, cursor: "pointer" }}
                                onClick={() => sortBy("id")} />
                        </div>
                    </div>
                    {
                        isOperator ?
                            <div style={{ flex: "2" }} className="table-header-dashboard">
                                <p className="table-header-dashboard-title" >{t('contract_type')}</p>
                            </div>
                            : <></>
                    }
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <p className="table-header-dashboard-title">{t('market_day')}</p>
                    </div>
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <p className="table-header-dashboard-title">{t('quantity')}</p>
                    </div>
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <p className="table-header-dashboard-title">{t('unilever_quantity')}</p>
                    </div>
                    <div style={{ flex: "2", position: "relative" }} className="table-header-dashboard">
                        <Tooltip title={t('info_report_amount_paid')} style={{ position: "absolute", right: "10px", top: "10px" }}>
                            <InfoIcon fill={colorPrimary} width={"15px"} height={"15px"} />
                        </Tooltip>
                        <p className="table-header-dashboard-title">{t('price_paid')}</p>
                    </div>
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <p className="table-header-dashboard-title">{t('ppunch_paid')}</p>
                    </div>
                    <div style={{ flex: "2" }} className="table-header-dashboard">
                        <p className="table-header-dashboard-title" >{t('fcrop_awarded')}</p>
                    </div>
                    <div style={{ flex: "2", position: "relative" }} className="table-header-dashboard">
                        <Tooltip title={t('info_report_traceable')} style={{ position: "absolute", right: "10px", top: "10px" }}>
                            <InfoIcon fill={colorPrimary} width={"15px"} height={"15px"} />
                        </Tooltip>
                        <p className="table-header-dashboard-title">{t('report_traceable_column')}</p>
                    </div>
                </div>
                {contracts.map((c, i) => <ContractItem key={i} contract={c} />)}
            </div>
        )
    }

    return (
        <div className="greenPaleBgColor" style={{ height: "100%", padding: "30px 10px", flex:1 }}>
            <Grid container>
                <Grid item xs={12}>
                    <ReportBarSummary contracts={contracts} />
                </Grid>
                <Grid item xs={12}>
                    <p className="table-label-indicator">{`${contracts.length} ${t('contracts')}`}</p>
                </Grid>
                <ContractTable contracts={contracts} />
            </Grid>
        </div>
    )
}