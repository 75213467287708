import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { MenuState, NFMStore, setMenuState } from "../stores/store";
import { useSelector } from "react-redux";
import { ReactComponent as PempemLogo } from "../rsc/logo_pempem.svg";
import { t } from "i18next";


export function BurgerMenu() {

    const menuState: MenuState = useSelector((state: any) => state.menu.value)

    return (
        !menuState.isMobile ?
            <div className="flex-1 items-center gap-[8px] flex">
                <PempemLogo />
                <p style={{ fontWeight: "1000", fontSize: "20px" }}>Pempem</p>
                <p style={{ fontWeight: "1000", fontSize: "20px" }}>{t('pempem_header_title')}</p>
            </div>
            :
            <div onClick={() => NFMStore.dispatch(setMenuState({ isMenuOpen: !menuState.isMenuOpen, isMobile: menuState.isMobile }))}>
                <MenuIcon />
            </div>
    )
}