import React from "react";
// import { ReactComponent as LogoMarket } from "../../rsc/market.svg"
import Companies from "../companies/Companies";
import CompanyDetail from "../companies/CompanyDetail";
// import Market from "../market/Market"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import '../../global.scss';
import { AdminInfo } from "../../stores/store";
import OrganisationProfile from "../companies/OrganisationProfile";
import CompletedContracts from "../contracts/CompletedContracts";
import Admin from "../dashboard/Admin";
import Dashboard from "../dashboard/Dashboard";
import { InvoiceDetail } from "../invoice/InvoiceDetail";
import { PempemInvoiceList } from "../invoice/PempemInvoiceList";
import { MyPrices } from "../my_prices/MyPrices";
import { Profile } from "../profil/Profile";

type ExplorerType = {
    client: ApolloClient<NormalizedCacheObject>,
    isAdmin: AdminInfo,
    logOut: () => void,
    menuOpen: boolean
}

function Explorer({ client, isAdmin }: ExplorerType) {

    const navigate = useNavigate()

    // Footer height
    return <div className="flex " style={{ height: "100%" }}>
        <div style={{ flexGrow: "1", height: "100%" }}>
            <Routes>
                <Route path="companies" element={<Companies client={client} onViewMoreClick={(org) => {
                    navigate(`companies/company_detail/${org.id}`)
                }} />}></Route>
                {/* <Route path="market" element={<Market client={client} />}></Route> */}
                <Route path="contracts/*" element={<CompletedContracts client={client} />}></Route>
                <Route path="my_prices" element={<MyPrices client={client} />}></Route>
                <Route path="profile" element={<Profile client={client} />}></Route>
                <Route path="pempem_invoices/*" element={<PempemInvoiceList />}></Route>
                {/* <Route path="price_vision" element={<PriceVisionPage client={client} />}></Route> */}
                <Route path="companies/company_detail/:id" element={<CompanyDetail client={client} />}></Route>
                <Route path="company_profil/:id" element={<OrganisationProfile client={client} />}></Route>
                <Route path="invoice_detail/*" element={<InvoiceDetail client={client} />}></Route>
                {
                    isAdmin.dashboardEnable ?
                        <Route path="dashboard/*" element={<Dashboard client={client} />}></Route>
                        : <></>
                }
                <Route path="admin/" element={<Admin client={client} onViewMoreClick={(org) => {
                    navigate(`companies/company_detail/${org.id}`)
                }} />}></Route>
            </Routes>
        </div>
    </div>
}

export default Explorer