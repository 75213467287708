export const colorPrimary = "#0097A7";
export const colorPrimaryDark = "#006166";
export const colorLightGreen = "#129296";
export const colorPrimaryDarkAlpha = "#00616682";
export const colorGreenPale = "#E9F0F0";
export const colorLightGrey = "#F5F5F5";
export const colorDark = "#000000";
export const colorLight = "#FFFFFF";
export const colorGrey = "#808080";
export const colorYellowStrong = "#FEBD2B";
export const colorYellowMedium = "#FFD67B";
export const colorYellowLight = "#FFF5DF";
export const colorRed = "#ff0000";
export const colorPurpleDark ="#A72010";
export const colorDarkBlue = "#0F293C"
export const colorPrimaryPale = "#EEF9F8"