import { Buffer } from "buffer";

export type UserTokenModel = {
    fullName: string
    firstName: string
    lastName: string
    avatarUrl: string
}

export function extractUserInfo(token: string): UserTokenModel {
    if (token) {
        var tokenInfos = token.split(".")

        var buff = Buffer.from(tokenInfos[1], 'base64')
    
        var jsonParsed = JSON.parse(buff.toString())
        const fullName = jsonParsed["name"]
        var firstName = ""
        var lastName = ""
        if (fullName) {
            const fullNameSplit = fullName.split(" ")
            if (fullNameSplit.length > 0) {
                firstName = fullNameSplit[0]
            }
            if (fullNameSplit.length > 1) {
                lastName = fullNameSplit[1]
            }
        }
    
    
        var userInfo: UserTokenModel = {
            fullName: fullName,
            firstName: firstName,
            lastName: lastName,
            avatarUrl: jsonParsed["picture"],
        }
        return userInfo    
    } else {
        return null
    }
}

export function getUserFromApi(apiUser): UserTokenModel {
    var userInfo: UserTokenModel = {
        fullName: apiUser.firstname + apiUser.lastname,
        firstName: apiUser.firstname,
        lastName: apiUser.lastname,
        avatarUrl: apiUser.user?.avatar_url
    }
    return userInfo
}