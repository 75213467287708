import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { colorGrey } from "../rsc/colors/colors";

export type SelectorType = {
    id: any
    value: string
}

type SelectorProp = {
    list: SelectorType[]
    onSelectionChange: any
    label?: string
    currentValue: SelectorType
}

export function Selector({ list, onSelectionChange, label, currentValue }: SelectorProp) {

    const updateState = (value: SelectorType) => {
        onSelectionChange(value)
    }

    return (
        <div>
            <div style={{ textTransform: "uppercase", color: colorGrey, fontSize: "13px", fontWeight: "500", display: label ? 'block' : 'none'}}>{label}</div>
            <FormControl style={{ marginTop: "4px" }} className="w-[200px]">
                <Select
                    sx={{
                        '& .MuiSelect-select': {
                            paddingRight: 4,
                            paddingLeft: 2,
                            paddingTop: 1,
                            paddingBottom: 1,
                        }
                    }}
                    displayEmpty={true}
                    defaultValue={currentValue.id}
                    onChange={e => updateState(list.find(l => l.id === (e.target.value as number)))}>
                    {
                        list.map(o => {
                            return (
                                <MenuItem key={o.id} value={o.id}>{o.value}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>
    )
}