import { t } from "i18next";
import React, { CSSProperties } from "react";
import { averageFcrop, fruitQuantity, fruitUnileverPlotQuantity, percentPpunchOnGMV, totalFcrop, totalPpunch } from "../../helper/contractHelper";
import { Contract } from "../../models/contract/Contract";
import { ReactComponent as InfoIcon } from "../../rsc/ic_info.svg"
import { Tooltip } from "@mui/material";
import { colorPrimary } from "../../rsc/colors/colors";

type ReportBarSummaryProps = {
    contracts: Contract[]
}

export function ReportBarSummary({ contracts }: ReportBarSummaryProps) {

    const boxStyle2: CSSProperties = {
        flex: "2",
        display: "flex"
    }

    return (
        <div style={{ display: "flex", gap: "3px", margin: "10px 0px", alignItems: "stretch" }}>
            <div style={boxStyle2}>
                <div className="sidebar-section-container">
                    <p className="sidebar-section-title">{t("fruit_volume")}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{fruitQuantity(contracts).IndoFormat(0)}</p>
                        <p className="sidebar-suffix">Kg</p>
                    </div>
                </div>
            </div>
            <div style={boxStyle2}>
                <div className="sidebar-section-container">
                    <p className="sidebar-section-title">{t("fruit_unilever_volume")}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{fruitUnileverPlotQuantity(contracts).IndoFormat(0)}</p>
                        <p className="sidebar-suffix">Kg</p>
                    </div>
                </div>
            </div>
            <div style={boxStyle2}>
                <div className="sidebar-section-container">
                    <p className="sidebar-section-title">{t('fcrop_given')}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{totalFcrop(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Rp</p>
                    </div>
                </div>
            </div>
            <div style={boxStyle2}>
                <div className="sidebar-section-container" >
                    <p className="sidebar-section-title">{t("average_fcrop")}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{averageFcrop(contracts).IndoFormat()}</p>
                        <p className="sidebar-suffix">Rp</p>
                    </div>
                </div>
            </div>
            <div style={boxStyle2}>
                <div className="sidebar-section-container">
                    <p className="sidebar-section-title">{t("ppunch_given")}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{(totalPpunch(contracts) / 1000 / 1000).IndoFormat(2)}</p>
                        <p className="sidebar-suffix">jt</p>
                    </div>
                </div>
            </div>
            <div style={boxStyle2}>
                <div className="sidebar-section-container" style={{ position: "relative" }}>
                    <Tooltip title={t('info_report_ppunch_gmv')} style={{ position: "absolute", right: "10px", top:"10px" }}>
                        <InfoIcon fill={colorPrimary} width={"15px"} height={"15px"} />
                    </Tooltip>
                    <p className="sidebar-section-title">{t("ppunch_on_gmv")}</p>
                    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                        <p className="sidebar-number">{(percentPpunchOnGMV(contracts)).IndoFormat(3)}</p>
                        <p className="sidebar-suffix">%</p>
                    </div>
                </div>
            </div>
        </div>
    )
}