import dayjs from "dayjs"
import { convertToDateRaw } from "../../helper/helper"
import { Landplot } from "../organisation/Landplot"
import { TransactorOrganisation, buildTransactorOrganisationDetail } from "../person/TransactorOrganisation"
import { ContractHarvest, buildContractHarvestForDashBoard, buildContractHarvestForDetail, buildContractHarvestForExport } from "./ContractHarvest"
import { ContractSaleStatement, buildContractSaleStatementForDetail, buildContractSaleStatementFromSale } from "./ContractSaleStatement"
import { Harvest } from "./Harvest"
import { SaleStatement } from "./SaleStatement"
import { Person } from "../person/Person"
import { ContractProcessedValues } from "./ContractProcessedValues"

export class Contract {
    id: number
    status: string
    market_day?: string
    invoiceId?: number
    sale_statements?: ContractSaleStatement[]
    harvests?: ContractHarvest[]
    transporter?: TransactorOrganisation
    primary_buyer_id?: number
    primary_buyer?: TransactorOrganisation
    agent?: TransactorOrganisation
    sellers?: TransactorOrganisation[]
    quantityGross?: number
    percentageFruitInOut?: number
    percentagePPPunchQuantityInProgram?: number
    nbHarvestBoosted?: number
    grading?: number
    netQuantity?: number
    contract_type?: string
    processData?: ContractProcessedValues

    constructor(id: number,
        status: string,
        market_day?: string,
        sale_statements?: ContractSaleStatement[],
        harvests?: ContractHarvest[],
        transporter?: TransactorOrganisation,
        primary_buyer_id?: number,
        primary_buyer?: TransactorOrganisation,
        invoice_id?: number,
        quantityGross?: number,
        percentageFruitInOut?: number,
        percentagePPPunchQuantityInProgram?: number,
        nbHarvestBoosted?: number,
        grading?: number,
        agent?: TransactorOrganisation,
        sellers?: TransactorOrganisation[],
        netQuantity?: number,
        contract_type?: string,
        isDashBoardContract: boolean = false) {
        this.id = id
        this.status = status
        this.market_day = market_day
        this.sale_statements = sale_statements
        this.harvests = harvests
        this.transporter = transporter
        this.primary_buyer_id = primary_buyer_id
        this.primary_buyer = primary_buyer
        this.invoiceId = invoice_id
        this.quantityGross = quantityGross
        this.percentageFruitInOut = percentageFruitInOut
        this.percentagePPPunchQuantityInProgram = percentagePPPunchQuantityInProgram
        this.nbHarvestBoosted = nbHarvestBoosted
        this.grading = grading
        this.agent = agent
        this.sellers = sellers
        this.netQuantity = netQuantity
        this.contract_type = contract_type
        if (isDashBoardContract) {
            this.processData = new ContractProcessedValues(this)
        }
    }

    isAgentContract(): boolean {
        return this.agent !== undefined
    }

    getSaleStatement(): SaleStatement {
        return this.sale_statements[0].sale_statement
    }

    getReceiptPhoto(): string[] {
        return this.getSaleStatement().photos.filter(p => p.isWeightReceipt())?.map(p => p.uri)
    }

    getNAMHarvestPhoto(): string[] {
        return this.getSaleStatement().photos.filter(p => p.isFruit())?.map(p => p.uri)
    }

    getSaleStatementHandler(): SaleStatement {
        return this.sale_statements?.find((s) => s?.party === "handler")?.sale_statement
    }

    getSaleStatementAgent(): SaleStatement {
        return this.sale_statements?.find((s) => s?.party === "agent")?.sale_statement
    }

    getSaleStatementSeller(): SaleStatement {
        return this.sale_statements?.find((s) => s?.party === "seller")?.sale_statement
    }

    getSaleStatementBuyer(): SaleStatement {
        return this.sale_statements?.find((s) => s?.party === "buyer")?.sale_statement
    }

    getMarketDay(): string {
        if (this.market_day) {
            return convertToDateRaw(this.market_day, "DD MMMM YYYY")
        } else {
            return "undefined"
        }
    }

    getTransporter() {
        return this.isAgentContract() ? this.agent : this.transporter
    }

    getMarketDayUtc(): string {
        return dayjs(this.market_day).utc().format("DD MMMM YYYY")
    }

    getDeliveryDate(): string | undefined {
        const photos = this.getSaleStatement().photos
        if (photos.at(0)?.created_at) {
            return photos.at(0).created_at
        }
    }

    isNamContract(): boolean {
        return this.contract_type.toLowerCase() === "nam"
    }

    getFruitQuantity(): number {
        return this.processData.fruitQuantity
    }

    getUnileverFruitQuantity(): number {
        return this.processData.ulFruitQuantity
    }

    getNonUnileverFruitQuantity(): number {
        return this.processData.nonUlFruitQuantity
    }

    isUnileverContract(): boolean {
        return this.nbHarvestBoosted > 0
    }

    getFarmerPersonIds(): number[] {
        return this.processData.farmerIds
    }

    getFarmersDeclared(): Person[] {
        return this.processData.farmersDeclared
    }

    getFarmersUndeclared(): Person[] {
        return this.processData.farmersUndeclared
    }

    getUnileverFarmerPersonIds(): number[] {
        return this.processData.ulFarmerIds
    }

    getNonUnileverFarmerPersonIds(): number[] {
        return this.processData.nonUlFarmerIds
    }

    getLandPlotsId(): number[] {
        return this.harvests.map(h => h.harvest.land_plot.id)
    }

    getUnileverLandPlotsId(): number[] {
        return this.harvests.filter(h => h.harvest.isUnileverLandPlot()).map(h => h.harvest.land_plot.id)
    }

    getNonUnileverLandPlotsId(): number[] {
        return this.harvests.filter(h => !h.harvest.isUnileverLandPlot()).map(h => { return h.harvest.land_plot.id })
    }

    getLandPlots(): Landplot[] {
        return this.processData.landplots
    }

    getUnileverLandPlots(): Landplot[] {
        return this.processData.ulLandplots
    }

    getUnileverHarvest(): Harvest[] {
        return this.processData.ulHarvest
    }

    getNonUnileverHarvest(): Harvest[] {
        return this.processData.nonUlHarvest
    }

    getHarvests(): Harvest[] {
        return this.processData.harvests
    }

    getFcrop(): number {
        return this.processData.fcrop
    }

    getPpunch(): number {
        return this.processData.ppunch
    }

    isContractPpunched(): boolean {
        return this.harvests.find(h => h.harvest.isPpunched()) !== undefined
    }

    getTotalNetRp(): number {
        return this.processData.totalNetRp
    }
}

export function buildContractForDashBoard(c: any): Contract {
    var harvests: ContractHarvest[]
    if (c.type.toLowerCase() === "nam") {
        const qt = c.quantityGross
        const qtHarvests = c.harvests.reduce((v, h) => v + (h.harvest.quantityFarmerAfterGrading ?? 0), 0)
        c.harvests.forEach(h => {
            const qtH = h.harvest.quantityFarmerAfterGrading
            h.harvest.quantityFarmerAfterGrading = (qtH / qtHarvests) * qt
        })
    }

    harvests = c.harvests.map((ch) => buildContractHarvestForDashBoard({ ...ch, contractId: c.id, grading: c.grading }))
    return new Contract(c.id,
        c.status,
        c.marketDate,
        null,
        harvests,
        null,
        c.primary_buyer?.organisation?.id,
        buildTransactorOrganisationDetail({ transactor: c.primary_buyer.transactor, organisation: { ...c.primary_buyer.organisation, isBuyerInProgram: c.isBuyerInProgram } }),
        c.invoice_item?.invoice_id,
        c.quantityGross,
        c.percentageQuantityInProgram,
        c.percentagePPPunchQuantityInProgram,
        c.nbHarvestBoosted,
        c.grading,
        undefined,
        undefined,
        undefined,
        c.type,
        true)
}

export function buildContractForList(c: any): Contract {
    return new Contract(c.id,
        c.status,
        c.market_day,
        c.sale_statements.map((css) => buildContractSaleStatementForDetail(css)),
        c.harvests?.map((ch) => buildContractHarvestForDetail(ch)),
        null,
        c.primary_buyer_id,
        buildTransactorOrganisationDetail(c.primary_buyer),
        c.invoice_item?.invoice_id)
}

export function buildContractForDetail(c: any): Contract {
    return new Contract(
        c.contract_id,
        c.status,
        c.market_day,
        [buildContractSaleStatementFromSale({ ...c.statement.statement, party: "buyer" })],
        c.harvests?.map((ch) => buildContractHarvestForDetail({ harvest: ch })),
        c.transporter ? buildTransactorOrganisationDetail(c.transporter.party_context) : undefined,
        c.primary_buyer_id,
        buildTransactorOrganisationDetail(c.primary_buyer.party_context),
        c.invoice_item?.invoice_id,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        c.agent ? buildTransactorOrganisationDetail(c.agent.party_context) : undefined,
        c.sellers?.map(s => buildTransactorOrganisationDetail(s.party_context))
    )
}

export function buildContractForExport(c: any): Contract {
    return new Contract(
        c.contract_id,
        undefined,
        c.market_day,
        undefined,
        c.harvests?.map((ch) => buildContractHarvestForExport({ harvest: ch })),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        c.net_quantity
    )

}