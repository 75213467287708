import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { DATE_PATTERN_DD_MMMM_YYYY, convertToDateWithUtcJakartaTime, toIndoFormat } from "../../helper/helper"
import { ContractList } from "../../models/contract/ContractList"

export function ListItemContract({ contractList, onContractClick, onClick, contractError, isNotSelectable, isDisable }) {

    const isSelected = contractList.isSelected
    const contract: ContractList = contractList.contract

    const navigate = useNavigate()

    const { t } = useTranslation()

    const getRowStyle = (): string => {
        var style = ""
        if (contractError(contractList)) {
            style = ''
        } else if (isSelected) {
            style = `cursor-pointer`
        } else {
            style = "hoverGreenPale cursor-pointer"
        }
        return style
    }

    return (
        <tr className={`${getRowStyle()} ${isSelected ? 'primaryDarkAlphaBgColor' : 'lightBgColor' }`} data-rowid={contract.id} onClick={(e) => {
                onClick(contractList)
                e.stopPropagation()
            }} key={contract.id}>
            <td>
                <input style={{ marginRight: "5px", width: "18px", height: "18px", display: isNotSelectable ? "none" : "inline-block" }}
                    checked={isSelected} onClick={e => e.stopPropagation()}
                    disabled={isDisable}
                    onChange={(e) => {
                        onClick(contractList)
                    }} type="checkbox" />
            </td>
            <td>{contract.id}</td>
            <td>{contract.getMarketDay()}</td>
            <td>{convertToDateWithUtcJakartaTime(contract.getDeliveryDate(), DATE_PATTERN_DD_MMMM_YYYY)}</td>            
            <td>{contract.status}</td>
            <td>{contract.getQuantity()}</td>
            <td>{toIndoFormat(contract.getPricePerUnit())}</td>
            <td className={contract.invoiceId ? 'text-[blue] hover:cursor-pointer': ``} onClick={(e) => {
                if (contract.invoiceId) {
                    navigate(`invoice_detail/${contract.invoiceId}`)
                    e.stopPropagation()
                }
            }}>{contract.invoiceId ?? '-'}</td>
            <td><button onClick={(e) => { onContractClick(contract); e.stopPropagation() }} style={{ fontSize: "14px" }}>{t('more_detail')}</button></td>
        </tr>
    )
}