import { BidModel, buildBidModel } from "./BidModel"

export class OrgBidModel {
    organisationId?: number
    bids: BidModel[]

    constructor(organisationId?: number, bids?: BidModel[]) {
        this.organisationId = organisationId
        this.bids = bids
    }
}

export function buildOrgBid(p: any): OrgBidModel {
    return new OrgBidModel(p.organisation_id,
        p.bids.map(b => buildBidModel(b)))
}