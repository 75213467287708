import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import React from "react"
import { Route, Routes } from "react-router-dom"
import ContractDetail from "../contracts/ContractDetail"

type InvoiceProps = {
    client: ApolloClient<NormalizedCacheObject>
}

export function InvoiceDetail({ client }: InvoiceProps) {

    return (
        <Routes>
            <Route path="contract_detail" element={<ContractDetail client={client} />}></Route>
            {/* <Route path="/" element={<InvoiceComponent />}></Route> */}
            {/* <Route path="/invoice_pempem/*" element={<PempemInvoiceDetail invoice={invoice} />}></Route> */}
        </Routes>
    )
}