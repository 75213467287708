import { Landplot } from "../organisation/Landplot";
import { TransactorOrganisation, buildTransactorOrganisationDetail } from "../person/TransactorOrganisation";
import { ContractPhoto, buildContractPhoto } from "./ContractPhoto";

export class Harvest {
    farmer: TransactorOrganisation
    land_plot: Landplot
    quantity: number
    photos: ContractPhoto[]
    quantityFarmerAfterGrading: number
    ppunchTotal: number
    fcropTotal: number
    priceBuyer: number
    fruit_pile_uri?: string
    fruit_bunch_uri?: string
    pickedUpPosition?: number[]
    contractId?: number
    isPlotInProgram?: boolean
    isDeclared?: boolean
    grading?: number

    constructor(land_plot: Landplot,
        quantity: number,
        photos: ContractPhoto[],
        farmer: TransactorOrganisation,
        quantityFarmerAfterGrading: number,
        ppunchTotal?: number,
        fcropTotal?: number,
        priceBuyer?: number,
        fruit_pile_uri?: string,
        fruit_bunch_uri?: string,
        pickedUp?: number[],
        contractId?: number,
        isPlotInProgram?: boolean,
        isDeclared?: boolean,
        grading?: number) {
        this.land_plot = land_plot
        this.quantity = quantity
        this.photos = photos
        this.farmer = farmer
        this.quantityFarmerAfterGrading = quantityFarmerAfterGrading
        this.ppunchTotal = ppunchTotal
        this.fcropTotal = fcropTotal
        this.priceBuyer = priceBuyer
        this.fruit_pile_uri = fruit_pile_uri
        this.fruit_bunch_uri = fruit_bunch_uri
        this.pickedUpPosition = pickedUp
        this.contractId = contractId
        this.isPlotInProgram = isPlotInProgram
        this.isDeclared = isDeclared
        this.grading = grading
    }

    isUnileverLandPlot(): boolean {
        return this.isPlotInProgram
    }

    isPpunched(): boolean {
        return this.ppunchTotal > 0
    }

    isFcroped() {
        return this.fcropTotal > 0
    }

    getFruitPhoto(): string {
        return this.fruit_bunch_uri.length > 0 ? this.fruit_bunch_uri : this.fruit_pile_uri
    }

    hasFruitPhoto(): boolean {
        return this.fruit_bunch_uri.length > 0 || this.fruit_pile_uri.length > 0
    }

    fruitPhotos(): ContractPhoto[] {
        return this.photos.filter(p => p.isFruit())
    }

    getQuantityGradingDeducted() {
        return this.quantityFarmerAfterGrading * (1-this.grading ?? 0)
    }
}

export function buildHarvestForContractDetail(h: any): Harvest {
    return new Harvest(
        new Landplot(h.land_plot.id, h.land_plot.polygon, h.land_plot.name, h.land_plot.pickup_position, undefined, h.land_plot.size),
        h.quantity,
        h.photos?.map(p => buildContractPhoto(p.photo)),
        h?.farmer ? buildTransactorOrganisationDetail(h.farmer) : undefined,
        null,
        null,
        undefined,
        undefined,
        h.fruit_pile_uri,
        h.fruit_bunch_uri,
        h.location?.coordinates)
}

export function buildHarvestForExport(h: any): Harvest {
    return new Harvest(
        new Landplot(h.land_plot.id, h.land_plot.polygon, h.land_plot.name, h.land_plot.pickup_position, undefined, h.land_plot.size),
        h.loaded_quantity,
        h.photos?.map(p => buildContractPhoto(p.photo)),
        h?.farmer ? buildTransactorOrganisationDetail(h.farmer) : undefined,
        null,
        null,
        undefined,
        undefined,
        h.fruit_pile_uri,
        h.fruit_bunch_uri,
        h.fruit_pile_position?.coordinates)
}

export function buildHarvestForDashboard(h: any): Harvest {
    return new Harvest(
        new Landplot(h.land_plot.id, h.land_plot.polygon, h.land_plot.name, h.land_plot.pickup_position, h.land_plot.externalId, undefined, h.isPlotInProgram),
        h.quantityFarmerAtFarm,
        h.photos?.map(p => buildContractPhoto(p.photo)),
        buildTransactorOrganisationDetail({
            transactor: h.farmer
        }),
        h.quantityFarmerAfterGrading,
        h.ppunchTotal,
        h.fcropTotal,
        h.priceBuyer,
        undefined,
        undefined,
        h.location?.coordinates,
        h.contractId,
        h.isPlotInProgram,
        h.status === "confirmed",
        h.grading)
}