import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { List } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Observable, Observer, zip } from "rxjs";
import { GetBids, UpsertBid, myCompanies } from "../../api";
import { Loader } from "../../components/Loader";
import MapBox from "../../components/MapBox";
import { transactorId } from "../../helper/TokenHelper";
import { defaultLat, defaultLng, showErrorMessage } from "../../helper/helper";
import { buildDataForPricePage } from "../../models/MapBoxDataModel";
import { BidModel } from '../../models/bid/BidModel';
import { OrgBidModel, buildOrgBid } from '../../models/bid/OrgBidModel';
import { NiagaMarket, buildNiagaMarket } from "../../models/niaga-market/NiagaMarket";
import { Organisation, buildOrganisationFromDetail } from "../../models/organisation/Organisation";
import { firebaseApp } from "../login/firebaseInit";
import './MyPrices.scss';
import { ExpandablePrice } from './component/ExpandablePrice';
import { MarketTimeLine } from './component/MarketTimeLine';

export const PRICE_CODE_CAN_PUBLISH_PRICE_DEMAND = 0
export const PRICE_CODE_CAN_PUBLISH_PRICE_NOT_DEMAND = 1
export const PRICE_CODE_MARKET_CLOSE = 2
export const PRICE_CODE_NO_ORG_LOCATION = 3

type MyPricesProps = {
    client: ApolloClient<NormalizedCacheObject>,
}

export type PriceForOrganisation = {
    org: Organisation
    price: OrgBidModel
}

export function MyPrices(props: MyPricesProps) {

    const [prices, setPrices] = useState<PriceForOrganisation[]>(null)
    const [currentTurn, setCurrentTurn] = useState<NiagaMarket>(null)
    const [loading, setLoading] = React.useState(false)
    const [flyToPosition, setFlyToPosition] = useState(null)
    const popups = useRef([])
    const pricesRef = useRef(prices)

    const mapRef = useRef(null)

    const { t, i18n } = useTranslation()

    useEffect(() => {
        pricesRef.current = prices
    }, [prices])

    useEffect(() => {
        const changeLanguageHandler = (l) => {
            if (mapRef.current) {
                buildDataForPricePage(mapRef.current, pricesRef.current, popups.current, t)
            }
        };

        i18n.on('languageChanged', changeLanguageHandler);

        return () => {
            i18n.off('languageChanged', changeLanguageHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n])

    useEffect(() => {
        const collection = firebaseApp.firestore().collection('auction')
        const unsubscribe = collection.onSnapshot((changes) => {
            var marketDay = changes.docs.find(d => d.id === "current").data().marketDay
            var turns = changes.docs.find(d => d.id === "turn").data()
            var turn = buildNiagaMarket(turns, marketDay)
            setCurrentTurn(turn)
            fetchPrices(turn)
        })

        setLoading(true)
        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function fetchPrices(turn: NiagaMarket) {
        const companyCall = new Observable<Organisation[]>((sub) => {
            props.client.query({
                query: myCompanies,
                variables: {
                    transactorId: transactorId()
                }
            }).then(result => {
                const orgs = result.data.organisations.map((o) => buildOrganisationFromDetail(o.organisation))
                sub.next(orgs)
            }).catch(e => sub.error(e))
        })
        const priceCall = new Observable<OrgBidModel[]>((sub) => {
            props.client.query({
                query: GetBids,
                context: {
                    headers: {
                        "role": "usermill"
                    }
                }
            }).then(result => {
                const priceFiltered = (result.data.bids.map((p) => buildOrgBid(p)))
                sub.next(priceFiltered)
            }).catch(e => sub.error(e))
        })

        const observer: Partial<Observer<[Organisation[], OrgBidModel[]]>> = {
            next: (res) => {
                const orgAndPrice: PriceForOrganisation[] = res[1].filter(bid => res[0].find(o => o.id === bid.organisationId) !== undefined).map(bid => {
                    const org = res[0].find(o => o.id === bid.organisationId)
                    return {
                        org: org,
                        price: bid
                    }
                })
                setPrices(orgAndPrice)
                setLoading(false)
            },
            error: (error: TypeError) => {
                showError(error.message)
            },
            complete: () => {
                setLoading(false)
            }
        }

        zip(companyCall, priceCall)
            .subscribe(observer)
    }

    function showError(message: string) {
        showErrorMessage(message)
        setLoading(false)
    }

    const getPriceForCompany = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <List style={{ flex: "1" }}>
                    {prices.map((org) => {
                        return <ExpandablePrice onPriceClick={onPriceClick}
                            key={org.org.id}
                            info={org}
                            isEditable={canEditPrice}
                            isMarketClose={isMarketClose}
                            getErrorReason={errorReason}
                            onValidatePrice={updatePrice}
                            getPriceStatus={priceStatusCode}
                            onDeletePrice={(bid, org) => updatePrice(bid, org, null)} />
                    }
                    )}
                </List>
            </div>
        )
    }

    function isMarketClose(): boolean {
        return currentTurn?.closingId !== null && currentTurn?.closingId !== undefined
    }

    const onPriceClick = (org: Organisation) => {
        const coord = org?.positionepsg4326?.coordinates
        if (coord && flyToPosition) {
            flyToPosition(coord)
            // addPriceCirclRadius(mapRef.current, org)
        }
    }

    const canEditPrice = (org: Organisation) => {
        if (!org.hasPosition() || isMarketClose()) {
            return false
        }
        return true
    }

    function priceStatusCode(info: PriceForOrganisation): number {
        if (isMarketClose()) {
            return PRICE_CODE_MARKET_CLOSE
        } else if (!info.org.hasPosition()) {
            return PRICE_CODE_NO_ORG_LOCATION
        } else if (!currentTurn.demand.open) {
            return PRICE_CODE_CAN_PUBLISH_PRICE_NOT_DEMAND
        } else {
            return PRICE_CODE_CAN_PUBLISH_PRICE_DEMAND
        }
    }

    const errorReason = (org: Organisation) => {
        if (!currentTurn?.isDemandTurn()) {
            return t('cannot_publish_price')
        } else if (!org.positionepsg4326) {
            return t('need_mill_position')
        } else if (currentTurn?.closingId) {
            return t('market_close')
        }
        return null
    }

    const updatePrice = (price: BidModel, org: Organisation, value: number): Promise<BidModel> => {
        return new Promise((resolve) => {
            props.client.mutate({
                mutation: UpsertBid,
                variables: {
                    input: {
                        organisation_id: org.id,
                        bids: [
                            {
                                price_per_kg: value,
                                market_day: price.market_day
                            }
                        ]
                    }
                },
                context: {
                    headers: {
                        "role": "usermill"
                    }
                }
            }).then((data) => {
                const orgBid = buildOrgBid(data.data.bid)
                const bid = orgBid.bids.find(b => b.market_day === price.market_day)
                resolve(bid)
                replacePrice(bid, org)
            }, (e: TypeError) => {
                resolve(price)
                showError(e.message)
            })
        })
    }

    function replacePrice(price: BidModel, org: Organisation) {
        const orgToFind = prices.find(p => p.org.id === org.id)
        if (orgToFind) {
            const priceIndex = orgToFind.price.bids.findIndex(p => p.market_day === price.market_day)
            orgToFind.price.bids[priceIndex] = price
        }
        setPrices([...prices])
        buildDataForPricePage(mapRef.current, prices, popups.current, t)
    }

    const showEmptyView = (text) => {
        return (
            <div style={{ textAlign: "center", marginTop: "60px" }}>
                <p style={{ padding: "20px", border: "2px dashed black", display: "inline-block", margin: "0px" }}>{text}</p>
            </div>
        )
    }

    function getInitCoord() {
        const org = prices.find(o => o.org.hasPosition())
        const coord = org?.org?.positionepsg4326?.coordinates
        if (coord) {
            return coord
        }
        return [defaultLng, defaultLat]
    }

    return (
        <div style={{ flex: "1", paddingBottom: "30px" }}>
            <h1 style={{ marginLeft: "28px" }}>{t("my_prices")}</h1>
            <MarketTimeLine currentTurn={currentTurn} />
            <div className="prices-map-container">
                <div style={{ marginLeft: "28px", marginRight: "20px", flex: "4" }}>
                    {prices ?
                        getPriceForCompany()
                        : !loading ? showEmptyView(t('no_price_to_show')) : <></>
                    }
                    <Loader isLoading={loading} />
                </div>
                <div className="map-container">
                    <div className="map-container-ar">
                        {
                            prices ? <MapBox onFlyTo={setFlyToPosition} lng={getInitCoord()[0]} lat={getInitCoord()[1]} width="100%" height="100%" onLoaded={(map) => { mapRef.current = map; buildDataForPricePage(map, prices, popups.current, t) }} /> : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}