import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { LoadingButton, TabContext, TabPanel } from "@mui/lab"
import { Box, Button, Dialog, DialogContent, FormControl, MenuItem, Select, Snackbar, Tab, Tabs, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useParams } from "react-router"
import { UpdateOrganisation, getTransactorOrganisationDetail } from "../../api"
import { Loader } from "../../components/Loader"
import { checkEmail, isNullOrEmpty } from "../../helper/helper"
import { transactorId } from "../../helper/TokenHelper"
import { TaxInformationModel } from "../../models/organisation/TaxInformationModel"
import { buildTransactorOrganisationDetail } from "../../models/person/TransactorOrganisation"
import { ReactComponent as Warning } from "../../rsc/ic_warning.svg"
import { LocationPicker } from "../profil/LocationPicker"

type OrganisationProfileProps = {
    client: ApolloClient<NormalizedCacheObject>,
}

const TransactionType = [
    "03",
    "05"
]

export default function OrganisationProfile(props: OrganisationProfileProps) {

    const { id } = useParams()

    const { t } = useTranslation()


    const [taxInfo, setTaxInfo] = useState<TaxInformationModel | null>(null)
    const [orgName, setOrgName] = useState<string>(null)
    const [showPickUpLocation, setShowPickupLocation] = useState(false)
    const [saving, setSaving] = useState(false)
    const [onError, setOnError] = useState(null)
    const [tabIndex, setTabIndex] = useState<string>("1")

    const pattern = "xx.xxx.xxx.x-xxx.xxx"

    useEffect(() => {
        props.client.query({
            query: getTransactorOrganisationDetail,
            variables: {
                organisationId: parseInt(id),
                transactorId: transactorId()
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then(result => {
            if (result.data.organisation.length > 0) {
                let org = buildTransactorOrganisationDetail(result.data.organisation[0])
                setOrgName(org.organisation.name)
                setTaxInfo(org.organisation.taxInformations)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function convertTransactionTypeToStringType(type: string) {
        return type === "05" ? "TAXPAYER" : "NON_TAXPAYERSTATEMILL"
    }

    function updateOrganisationTaxInformation() {
        setSaving(true)
        props.client.mutate({
            mutation: UpdateOrganisation,
            variables: {
                object: {
                    legal_name: taxInfo?.legal_name,
                    apply_vat: true,
                    vat_transaction_type: convertTransactionTypeToStringType(taxInfo?.vat_transaction_type ?? TransactionType[0]),
                    is_withholding_tax: true,
                    tax_identification_number: taxInfo?.taxNumber,
                    address: taxInfo?.address,
                    rt: taxInfo?.rt,
                    rw: taxInfo?.rw,
                    postal_code: taxInfo?.postalCode,
                    legal_location_id: taxInfo?.location?.id,
                    phonenumber: taxInfo?.phoneNumber,
                    email: taxInfo?.email
                },
                organisationId: parseInt(id),
            },
            context: {
                headers: {
                    "role": "usermill"
                }
            }
        }).then(data => {

        }).catch((e: TypeError) => {
            setOnError(e.message)
        })
            .finally(() => {
                setSaving(false)
            })
    }

    function updateOrganisationInformation() {
        setSaving(true)
        props.client.mutate({
            mutation: UpdateOrganisation,
            variables: {
                object: {
                    name: orgName
                },
                organisationId: parseInt(id),
            }
        }).then(data => {

        }).catch((e: TypeError) => {
            setOnError(e.message)
        })
            .finally(() => {
                setSaving(false)
            })
    }


    function isDigit(char: string) {
        return /^\d$/.test(char);
    }

    function formatVatString(value?: string): string {
        if (value?.length >= 20) {
            return value
        }
        if (value) {
            var digitString = ""
            for (let index = 0; index < value.length; index++) {
                const element = value[index];
                if (isDigit(element)) {
                    digitString += element
                }
            }
            var res = ""
            var pi = 0
            for (let index = 0; index < digitString.length; index++) {
                if (pattern[pi] === 'x') {
                    res += digitString[index].toString()
                    pi += 1
                } else {
                    res += pattern[pi] + digitString[index].toString()
                    pi += 2
                }
            }
            return res
        }
        return ""
    }

    function areFieldsValid(): boolean {
        if (taxInfo.taxNumber?.length < 20) {
            return false
        }
        if (isNullOrEmpty(taxInfo?.legal_name)) {
            return false
        }

        if (isNullOrEmpty(taxInfo?.email) || checkEmail(taxInfo?.email) === null) {
            return false
        }

        if (isNullOrEmpty(taxInfo?.address)) {
            return false
        }
        if (isNullOrEmpty(taxInfo?.rt)) {
            return false
        }
        if (isNullOrEmpty(taxInfo?.rw)) {
            return false
        }
        if (isNullOrEmpty(taxInfo?.phoneNumber)) {
            return false
        }
        if (isNullOrEmpty(taxInfo?.postalCode)) {
            return false
        }

        if (taxInfo?.location === null || taxInfo?.location === undefined) {
            return false
        }
        return true
    }

    const fieldLayoutStyles = "flex flex-row items-center gap-[10px] max-md:flex-col max-md:items-start"
    const labelStyle = "flex items-center shrink-0 w-[200px]"
    const fieldStyle = "w-[300px]"

    return (
        <div className="pl-[28px] pr-[28px] pb-[20px]">
            <TabContext value={tabIndex}>
                <Box marginTop={"20px"} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={(e, v) => setTabIndex(v.toString())} aria-label="basic tabs example">
                        <Tab label={t('profile')} value={"1"} />
                        <Tab label={t('tax')} value={"2"} />
                    </Tabs>
                </Box>
                <TabPanel value={"1"}>
                    {
                        orgName ?
                            <div>
                                <div className="flex flex-col gap-[30px] mt-[40px] w-[40%]">
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('name')}</p>
                                        <TextField className={fieldStyle} value={orgName} onChange={e => setOrgName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="flex justify-end mt-[30px]" >
                                    <LoadingButton variant="contained" loading={saving} onClick={() => updateOrganisationInformation()}>{t('save')}</LoadingButton>
                                </div>
                            </div>
                            : <></>
                    }
                </TabPanel>
                <TabPanel value={"2"}>
                    {
                        taxInfo ?
                            <div>
                                <div className='yellowLightBgColor mt-[40px] mb-[40px] p-[20px]' style={{ color: "black", textAlign: "center", display: `${areFieldsValid() ? "none" : "block"}` }}>
                                    <Warning className='inline-block h-[30px] w-[auto]' />
                                    <p className='inline-block ml-[15px] align-middle'>{t('all_fields_requiered')}</p>
                                </div>
                                <div className="flex flex-col gap-[10px] mt-[40px]">
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('legal_company_name')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.legal_name ?? ''} onChange={e => setTaxInfo({ ...taxInfo, legal_name: e.target.value })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('vat_number')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.taxNumber ?? ''}
                                            inputProps={{ maxLength: 20 }}
                                            onChange={e => setTaxInfo({ ...taxInfo, taxNumber: formatVatString(e.target.value) })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('transaction_type')}</p>
                                        <FormControl style={{ marginTop: "4px" }} className="w-[200px]">
                                            <Select
                                                sx={{
                                                    '& .MuiSelect-select': {
                                                        paddingRight: 4,
                                                        paddingLeft: 2,
                                                        paddingTop: 1,
                                                        paddingBottom: 1,
                                                    }
                                                }}
                                                displayEmpty={true}
                                                defaultValue={taxInfo?.vat_transaction_type ?? TransactionType[0]}
                                                onChange={e => setTaxInfo({ ...taxInfo, vat_transaction_type: e.target.value })}>
                                                {
                                                    TransactionType.map(o => {
                                                        return (
                                                            <MenuItem key={o} value={o}>{o}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('company_address')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.address ?? ''} onChange={e => setTaxInfo({ ...taxInfo, address: e.target.value })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('rt')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.rt ?? ''} onChange={e => setTaxInfo({ ...taxInfo, rt: e.target.value })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('rw')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.rw ?? ''} onChange={e => setTaxInfo({ ...taxInfo, rw: e.target.value })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('billing_email')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.email ?? ''} onChange={e => setTaxInfo({ ...taxInfo, email: e.target.value })} />
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('phone_number')}</p>
                                        <div className={fieldStyle}>
                                            <PhoneInput country="US" value={taxInfo?.phoneNumber ?? ''} onChange={e => setTaxInfo({ ...taxInfo, phoneNumber: e })} />
                                        </div>
                                    </div>
                                    <div className={fieldLayoutStyles}>
                                        <p className={labelStyle}>{t('postal_code')}</p>
                                        <TextField className={fieldStyle} value={taxInfo?.postalCode ?? ''} onChange={e => setTaxInfo({ ...taxInfo, postalCode: e.target.value })} />
                                    </div>
                                </div>
                                <div className="flex max-w-[600px]">
                                    <Box sx={{ boxShadow: 3, marginTop: "20px", padding: "30px" }}>
                                        <div className="flex flex-row gap-[30px] flex-wrap">
                                            <div className="flex flex-col w-[40%]">
                                                <p className="mb-[10px]">{t('village')}</p>
                                                <TextField placeholder={t('village')} disabled={true} value={taxInfo?.location?.village ?? ''}
                                                    onChange={e => setTaxInfo({ ...taxInfo, location: { ...taxInfo.location, village: e.target.value } })} />
                                            </div>
                                            <div className="flex flex-col w-[40%]">
                                                <p className="mb-[10px]">{t('district')}</p>
                                                <TextField placeholder={t('district')} disabled={true} value={taxInfo?.location?.district ?? ''}
                                                    onChange={e => setTaxInfo({ ...taxInfo, location: { ...taxInfo.location, district: e.target.value } })} />
                                            </div>
                                            <div className="flex flex-col w-[40%]">
                                                <p className="mb-[10px]">{t('sub_district')}</p>
                                                <TextField placeholder={t('sub_district')} disabled={true} value={taxInfo?.location?.subDistrict ?? ''}
                                                    onChange={e => setTaxInfo({ ...taxInfo, location: { ...taxInfo.location, subDistrict: e.target.value } })} />
                                            </div>
                                            <div className="flex flex-col w-[40%]">
                                                <p className="mb-[10px]">{t('province')}</p>
                                                <TextField placeholder={t('province')} disabled={true} value={taxInfo?.location?.province ?? ''}
                                                    onChange={e => setTaxInfo({ ...taxInfo, location: { ...taxInfo.location, province: e.target.value } })} />
                                            </div>
                                            <Button onClick={() => setShowPickupLocation(true)} variant="contained">{t('select_village_location')}</Button>
                                        </div>
                                    </Box>
                                </div>
                                <div className="flex justify-end mt-[30px]" >
                                    <LoadingButton disabled={!areFieldsValid()} variant="contained" loading={saving} onClick={() => updateOrganisationTaxInformation()}>{t('save')}</LoadingButton>
                                </div>
                                <Dialog
                                    open={showPickUpLocation}
                                    onClose={() => setShowPickupLocation(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogContent>
                                        <LocationPicker client={props.client} onLocationSelected={(l) => { setTaxInfo({ ...taxInfo, location: l }); setShowPickupLocation(false) }} />
                                    </DialogContent>
                                </Dialog>
                                {
                                    onError ?
                                        <Snackbar
                                            style={{ backgroundColor: "red" }}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center"
                                            }}
                                            open={onError !== null}
                                            autoHideDuration={6000}
                                            onClose={() => { setOnError(null) }}
                                            message={onError}
                                            sx={{
                                                '& .MuiSnackbarContent-root': {
                                                    backgroundColor: "red",
                                                    fontWeight: "600"
                                                }
                                            }}
                                        /> : <></>
                                }
                            </div>
                            : <></>
                    }
                </TabPanel>
            </TabContext>
            <Loader isLoading={saving} />
        </div>
    )
}