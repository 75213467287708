import { FormControl, MenuItem, Select } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { getLocal } from "../helper/helper";

export function SwitchLang() {

    const { i18n } = useTranslation();

    const changeLanguage = (value) => {
        localStorage.setItem('lang', value)
        dayjs.locale(value)
        i18n.changeLanguage(value)
    }

    return <FormControl style={{ marginTop: "4px" }}>
        <Select
            style={{ color: "white" }}
            sx={{
                '& .MuiSelect-select': {
                    paddingRight: 4,
                    paddingLeft: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                },
                '& .MuiSvgIcon-root': {
                    fill: "white"
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red', // Change to your desired color
                },
                "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "#00000000"
                    },
                    "&:hover fieldset": {
                        borderColor: "#00000000"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#00000000"
                    }
                }
            }}
            displayEmpty={true}
            defaultValue={getLocal()}
            onChange={e => changeLanguage(e.target.value)}>
            <MenuItem key={"en-US"} value={"en-US"}>English</MenuItem>
            <MenuItem key={"id"} value={"id"}>Indonesian</MenuItem>
        </Select>
    </FormControl>

}