import { GeoJSONSource, Map } from "mapbox-gl";

export class MapEvent {

    initialCoords?: number[]
    coords:number[]
    source:string
    map: Map
    onMapClick = (ev:any) => {
        const coords = ev.lngLat
    
        this.geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
        (this.map.getSource(this.source) as GeoJSONSource).setData(this.geojson)
        this.coords = [coords.lng, coords.lat]
    }
    onMove = (ev:any) => {
        const coords = ev.lngLat
    
        let canvas = this.map.getCanvasContainer()
        canvas.style.cursor = 'grabbing'
    
        this.geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
        (this.map.getSource(this.source) as GeoJSONSource).setData(this.geojson)
        this.coords = [coords.lng, coords.lat]
    }
    onUp = (e) => {
        // Print the coordinates of where the point had
        // finished being dragged to on the map.
        let canvas = this.map.getCanvasContainer()
        canvas.style.cursor = '';

        // Unbind mouse/touch events
        this.map.off('mousemove', this.onMove);
        this.map.off('touchmove', this.onUp);
    }
    onDown = (e) => {
        // Prevent the default map drag behavior.
        e.preventDefault()

        let canvas = this.map.getCanvasContainer()

        canvas.style.cursor = 'grab'

        this.map.on('mousemove', this.onMove)
        this.map.once('mouseup', this.onUp)
    }
    geojson: any
    onCancel = () => {
        if (this.initialCoords) {
            this.geojson.features[0].geometry.coordinates = this.initialCoords;
            (this.map.getSource(this.source) as GeoJSONSource).setData(this.geojson)    
        }
    }

    constructor(map: Map, geojson: any, source:string, initialCoords: number[]) {
        this.map = map
        this.geojson = geojson
        this.source = source
        this.initialCoords = initialCoords
    }
}