import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { Drawer } from "@mui/material"
import React from "react"
import { DashboardFarmerOnboardGraph } from "./DashboardFarmerOnboardGraph"
import "./SideBarDashBoard.css"

type SideBarOnboardingProps = {
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    client: ApolloClient<NormalizedCacheObject>
}

export function SideBarOnboarding({ showModal, setShowModal, client }: SideBarOnboardingProps) {

    const handleCloseDrawer = () => {
        setShowModal(false); // Closes the drawer
    };

    return (
        <Drawer
            onClose={handleCloseDrawer}
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "540px",
                    boxSizing: 'border-box',
                    zIndex: 5,
                    padding: "10px 20px",
                    background: "radial-gradient(50% 50% at 50% 50%, #9FB5B6 0%, #E9F0F0 100%)"
                },
            }}
            ModalProps={{
                container: document.getElementById('drawer-container'),
                style: { position: 'absolute' }
            }}
            anchor="right"
            open={showModal}>
            <DashboardFarmerOnboardGraph client={client} />
        </Drawer>
    )
}