import dayjs from "dayjs"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import { MarketCard } from "../../../components/MarketCard"
import { convertToDateWithUtc } from "../../../helper/helper"
import { MarketTurn } from "../../../models/niaga-market/MarketTurn"
import { NiagaMarket } from "../../../models/niaga-market/NiagaMarket"
import { colorYellowLight } from "../../../rsc/colors/colors"
import { ReactComponent as Generator } from "../../../rsc/ic_cog.svg"
import { ReactComponent as Demand } from "../../../rsc/ic_do.svg"
import { ReactComponent as Fulfillment } from "../../../rsc/ic_fulfillment.svg"
import { ReactComponent as Supply } from "../../../rsc/ic_supply.svg"
import { ReactComponent as Worker } from "../../../rsc/ic_worker.svg"
import { ReactComponent as Indicator } from "../../../rsc/timeline_indicator.svg"

type MarketTimeLineProps = {
    currentTurn: NiagaMarket
}

export function MarketTimeLine({ currentTurn }: MarketTimeLineProps) {

    const [currentTurnView, setCurrentTurnView] = useState({ width: 0, offset: 0 })
    const [cursorOffset, setCursorOffset] = useState<string>("0%")
    const containerRef = useRef<HTMLDivElement>(null)
    const demandRef = useRef(null)
    const supplyRef = useRef(null)
    const workerRef = useRef(null)
    const fulRef = useRef(null)

    function getCursorPositionTimeline(current: MarketTurn) {
        if (currentTurn.isGeneratorTurn()) {
            return
        } else if (current) {
            const today = dayjs()
            const turnDiff = dayjs(dayjs(current.endAt)).diff(current.startAt, 'minute')
            const dayDif = dayjs(current.endAt).diff(today, 'minute')

            if (currentTurn.isDemandTurn()) {
                setCurrentTurnView({ width: demandRef.current?.offsetWidth, offset: demandRef.current?.offsetLeft })
            } else if (currentTurn.isSupplyTurn()) {
                setCurrentTurnView({ width: supplyRef.current?.offsetWidth, offset: supplyRef.current?.offsetLeft })
            } else if (currentTurn.isWorkerTurn()) {
                setCurrentTurnView({ width: workerRef.current?.offsetWidth, offset: workerRef.current?.offsetLeft })
            } else if (currentTurn.isFulfillmentTurn()) {
                setCurrentTurnView({ width: fulRef.current?.offsetWidth, offset: fulRef.current?.offsetLeft })
            }

            let res = 100 - (dayDif / turnDiff * 100)

            if (res < 0 || res > 100) {
                res = 0
            }
            setCursorOffset(`${res.toString()}%`)
        }
    }

    useEffect(() => {
        if (currentTurn) {
            getCursorPositionTimeline(currentTurn?.getCurrentTurn())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                entries.forEach(() => {
                    if (currentTurn) {
                        getCursorPositionTimeline(currentTurn?.getCurrentTurn())
                    }
                })
            });
            resizeObserver.observe(containerRef.current);

            return () => {
                resizeObserver.unobserve(containerRef.current);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef]);

    const displayStyle: React.CSSProperties = {
        display: "flex",
        height: "100px",
        flexDirection: "column",
        alignItems: "center",
        flex: "1"
    }

    const svgStyle: React.CSSProperties = {
        flex: "3",
        width: "auto",
        padding: "13px 0 0 0",
        maxWidth: "100%"
    }
    const hourStyle: React.CSSProperties = {
        flex: "1",
        fontWeight: "550"
    }
    return (
        currentTurn?.closingId ?
            <div style={{
                display: "flex",
                border: "1px solid black",
                borderRadius: "5px",
                position: "relative",
                flex: "1",
                backgroundColor: colorYellowLight,
                minHeight: "100px",
                margin: "0px 20px 0px 28px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <p style={{ margin: "0px", fontSize: "2em" }}>{t('market_close')}</p>
            </div>
            :
            <div ref={(e) => { if (e) { containerRef.current = e } }} style={{ padding: "10px 0px 10px 0px", margin: "0px 20px 0px 28px", position: "relative" }}>
                {
                    currentTurn ?
                        <div style={{ position: "relative", display: "flex", gap: "10px", marginBottom: "5px" }}>
                            <p style={hourStyle}>{convertToDateWithUtc(currentTurn.demand.startAt, "HH:mm")}</p>
                            <p style={hourStyle}>{convertToDateWithUtc(currentTurn.supply.startAt, "HH:mm")}</p>
                            <p style={hourStyle}>{convertToDateWithUtc(currentTurn.worker.startAt, "HH:mm")}</p>
                            <p style={hourStyle}>{convertToDateWithUtc(currentTurn.fulfillment.startAt, "HH:mm")}</p>
                        </div>
                        : <></>
                }
                <div style={{ position: "relative" }}>
                    <div style={{ height: "2px", width: "100%", background: "#000" }}></div>
                    {
                        !currentTurn?.isGeneratorTurn() ?
                            <div style={{ position: "relative", display: "inline-block", height: "15px", width: currentTurnView?.width, left: currentTurnView?.offset }}>
                                <Indicator style={{ position: "absolute", height: "15px", width: "auto", top: "0", left: cursorOffset }} />
                            </div> : <div style={{ height: "15px" }}></div>
                    }
                </div>
                <div style={{ display: "flex", position: "relative", flex: "1", minHeight: "100px", justifyContent: "space-evenly", gap: "10px" }}>
                    {
                        currentTurn?.isGeneratorTurn() ?
                            <div style={{ position: "absolute", top: "calc(55% - 20px)", left: "calc(50% - 20px)" }}>
                                <div style={{ display: "flex", justifyContent: "center", width: "40px", height: "40px" }}>
                                    <Generator style={{ animation: `spin 1.5s linear infinite` }} fill="black" />
                                </div>
                            </div> : <></>
                    }

                    <MarketCard parentRef={demandRef} style={displayStyle}
                        turn={t('demand')} isSelected={currentTurn?.isDemandTurn()} renderIcon={() => { return <Demand style={svgStyle} /> }} />
                    <MarketCard parentRef={supplyRef} style={displayStyle}
                        turn={t('supply')} isSelected={currentTurn?.isSupplyTurn()} renderIcon={() => { return <Supply style={svgStyle} /> }} />

                    <MarketCard parentRef={workerRef} style={displayStyle}
                        turn={t('worker')} isSelected={currentTurn?.isWorkerTurn()} renderIcon={() => { return <Worker style={svgStyle} /> }} />
                    <MarketCard parentRef={fulRef} style={displayStyle}
                        turn={t('fulfillment')} isSelected={currentTurn?.isFulfillmentTurn()} renderIcon={() => { return <Fulfillment style={svgStyle} /> }} />
                </div>
            </div>)
}