import React, { useState } from "react"
import { Provider } from "../../models/provider/Provider"
import { Button, ListItemButton, TextField } from "@mui/material"
// import { tokenStore } from "../../stores/store"
// import axios from "axios"
import { ProviderState } from "./ActiveContract"
import { ReactComponent as Check } from "../../rsc/ic_round_check.svg"

type ProviderModalProps = {
    onClose: () => void
    providers: ProviderState
}

function DisplayProviders(providers: ProviderState, onProviderSelected: (p: Provider) => void) {

    const ProviderDetail = (p: Provider) => {
        return (
            <div key={p.id} style={{
                marginBottom: "15px",
                marginTop: "15px",
                boxShadow: "0px 3px 3px -3px rgb(0 0 0 / 20%), 0px 3px 3px 3px rgb(0 0 0 / 14%), 0px 3px 3px 3px rgb(0 0 0 / 12%)"
            }}>
                <ListItemButton onClick={() => onProviderSelected(p)}>
                    <img alt="" style={{ objectFit: "contain", maxHeight: "75px", minHeight: "75px", maxWidth: "75px", width: "auto", height: "auto" }} src={p.iconUrl}></img>
                    <div style={{ display: "flex", marginLeft: "20px", flex: "1", flexDirection: "column" }}>
                        {
                            providers.userProvider && providers.userProvider.provider_id === p.id ?
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginTop: "5px", flex: "10" }}>
                                        <p style={{ margin: "0px" }}>{p.displayName}</p>
                                        <div style={{ display: "flex", marginTop: "5px", alignItems: "center" }}>
                                            <p style={{ margin: "0px", display: "inline-block" }}>Name: </p>
                                            <p style={{ margin: "0px 0px 0px 5px", display: "inline-block" }}>{providers.userProvider.account_name}</p>
                                        </div>
                                        <p style={{ margin: "5px 0px 0px 0px" }}>Account: {providers.userProvider.bank_account_number}</p>
                                    </div>
                                    <Check style={{ flex: "1.5" }} />
                                </div>
                                : <p style={{ margin: "0px" }}>{p.displayName}</p>
                        }
                    </div>
                </ListItemButton>
            </div>
        )
    }

    return (
        <>
            {providers.list.map((p) => ProviderDetail(p))}
        </>
    )
}

export function ProviderModal(props: ProviderModalProps) {

    const [step, setStep] = useState({ id: 0, info: null })
    const [numberVerification, setNumberVerification] = useState("")

    function AccountVerificationView(provider: Provider) {
        return (
            <div>
                <p>{provider.displayName}</p>
                <TextField value={numberVerification} onChange={(e) => setNumberVerification(e.target.value)} id="outlined-basic" label={provider.isPhoneTargetType() ? "Enter your phone number" : "Enter your Bank account"} variant="outlined" />
                <Button variant="contained" style={{ display: "block", marginTop: "10px" }} onClick={() => verifyAccount(numberVerification, provider.id, "")}>Verify Account</Button>
            </div>
        )
    }

    function verifyAccount(bankNumber: string, providerId: string, requestId: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            // const token = tokenStore.getState().token.value
            // const response = await axios.post('https://backend.services-test.pempem.org/paymentBroker-getAccountInformation', {
            //     requestId: requestId,
            //     providerId: providerId,
            //     bankAccountNumber: bankNumber
            // }, {
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //         'Content-Type': 'application/json'
            //     },
            // })
            // resolve(true)
        })
    }

    function getProviderStepDisplay() {
        return (
            <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <div onClick={(e) => { e.stopPropagation() }} style={{
                    background: "white", borderRadius: "5px", display: "inline-block", padding: "20px", minWidth: "500px",
                    boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)"
                }}>
                    {step.id === 0 ? DisplayProviders(props.providers, (p: Provider) => { setStep({ id: 1, info: p }) })
                        : step.id === 1 ? AccountVerificationView(step.info as Provider)
                            : <></>}
                </div>
            </div>
        )
    }

    return (
        <div onClick={() => props.onClose()}
        style={{
            color: "black",
            fontWeight: "initial",
            fontSize: "initial",
            cursor: "default",
            position: "fixed",
            zIndex: "20",
            top: "0",
            right: "0",
            left: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, .5)"
        }}>
            {
                getProviderStepDisplay()
            }
        </div>
    )
}