import { Contract } from "../../../models/contract/Contract";
import { Harvest } from "../../../models/contract/Harvest";
import { Landplot } from "../../../models/organisation/Landplot";

export class LandPlotDashBoardModel {
    landPlot: Landplot
    contracts: Contract[] = []
    harvests: Harvest[]
    quantity: number = 0
    totalAmount: number = 0
    totalPpunch: number = 0
    totalFcrop: number = 0

    addInfo(harvest: Harvest, contract?: Contract) {
        if (contract) {
            this.contracts.push(contract)
        }
        this.quantity += (harvest.quantityFarmerAfterGrading * (1-harvest?.grading ?? 0))
        this.totalFcrop += harvest.fcropTotal
        this.totalPpunch += harvest.ppunchTotal
        this.harvests.push(harvest)
    }

    addInfoExport(harvest: Harvest) {
        this.quantity += harvest.quantity
    }

    isUnileverLandPlot(): boolean {
        return this.landPlot.isPlotInProgram
    }

    constructor(landplot: Landplot) {
        this.landPlot = landplot
        this.contracts = []
        this.harvests = []
    }
}