import { firebaseAuth } from './firebaseInit';
import React, { useEffect, useState } from "react"
import { GoogleAuthProvider, User, signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css"
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { isValidToken } from "./Auth";
import { checkEmail, checkPassword, showSuccessMessage } from "../../helper/helper";
import { ReactComponent as GoogleLogo } from "../../rsc/google_logo.svg"
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import { Loader } from "../../components/Loader";
import { useSearchParams } from "react-router-dom";
import logo from "../../rsc/ic_pempem_login.png"
import { colorLightGreen, colorRed } from "../../rsc/colors/colors";

type MessageType = {
    message: any,
    color: string
}

function Login(props) {

    const { t } = useTranslation()

    const [pwdreset] = useSearchParams()
    const isComingFromEmailResetPwd = pwdreset.get("pwdreset") !== null

    const [showForgotPwd, setShowForgotPwd] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState<MessageType>(null)
    const [loading, setLoading] = useState(false)

    var userGoogleAccessToken = null

    useEffect(() => {
        if (isComingFromEmailResetPwd) {
            showSuccessMessage(t('pwd_reset'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoginClick = () => {
        if (showForgotPwd) {
            onResetPassword(() => {
                showSuccessMessage(t('email_reset_sent'))
                setShowForgotPwd(false)
            })
        } else {
            signInWithEmail(email, password, (user) => {
                props.setAuthState({ status: 'in', user })
            }, (error) => {
                updateMessage({ message: error, color: colorRed })
            })
        }
    }

    const onChangePassword = (pwd: string) => {
        setPassword(pwd)
    }

    const signInWithEmail = (email: string, password: string, success: (user: User) => void, failure: (error: any) => void, onEmailNotVerified: (() => void) = null) => {
        const isEmailValid = checkEmail(email) !== null
        const isPwdValid = checkPassword(password)
        if (onEmailNotVerified && (!isEmailValid || !isPwdValid)) {
            updateMessage({ message: 'Need valid credential to get email', color: colorRed })
        } else if (!isEmailValid) {
            updateMessage({ message: t('email_pattern'), color: colorRed })
        } else if (!isPwdValid) {
            updateMessage({ message: t('password_pattern'), color: colorRed })
        } else {
            setLoading(true)
            signInWithEmailAndPassword(firebaseAuth(), email, password)
                .then(async (userCredential) => {
                    await userCredential.user.getIdToken(true)
                    const user = userCredential.user;
                    isValidToken(user).then((isValid) => {
                        if (isValid) {
                            if (user.emailVerified) {
                                success(user)
                            } else {
                                if (onEmailNotVerified) {
                                    onEmailNotVerified()
                                } else {
                                    updateMessage({ message: t('email_verify_needed'), color: colorRed })
                                }
                            }
                        } else {
                            updateMessage({ message: t('user_unauthorised'), color: colorRed })
                        }
                    })
                }, (reason) => {
                    failure(reason)
                })
                .catch(async (error) => {
                    updateMessage({ message: error, color: colorRed })
                });
        }
    }

    function updateMessage(message: MessageType) {
        setMessage(message)
        setLoading(false)
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            userGoogleAccessToken = tokenResponse.access_token
            // fetching userinfo can be done on the client or the server
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);

            signInWithGoogle(userInfo)
        },
    })

    const onResetPassword = (callback: () => void) => {
        var actionCodeSettings = {
            // After password reset, the user will be give the ability to go back
            // to this page.
            url: `${window.location.href}?pwdreset=true`,
            handleCodeInApp: false
        };
        if (checkEmail(email)) {
            firebaseAuth().sendPasswordResetEmail(email, actionCodeSettings).then(() => {
                callback()
            }, (reason) => {
                updateMessage({ message: reason, color: colorRed })
            })
        } else {
            updateMessage({ message: t('email_pattern'), color: colorRed })
        }
    }

    const signInWithGoogle = async (userInfo) => {
        setLoading(true)
        firebaseAuth().fetchSignInMethodsForEmail(userInfo.email)
            .then((providers) => {
                if (providers.length > 0) {
                    firebaseAuth().signInWithCredential(GoogleAuthProvider.credential(null, userGoogleAccessToken))
                        .then(async (userCredential) => {
                            await userCredential.user.getIdToken(true)
                            setLoading(false)
                        }, e => updateMessage({ message: e, color: colorRed }))
                } else {
                    updateMessage({ message: t('user_unauthorised'), color: colorRed })
                }
            })
    }

    return <div style={{ display: "flex", alignItems: "center", marginTop: "5%", flexDirection: "column", flex: "1", padding: "20px" }}>
        <img style={{ width: "60%", height: "auto", minWidth: "300px", marginBottom: "100px" }} src={logo} alt="logo" />
        <div onKeyDown={(e) => {
            if (e.key === "Enter") {
                onLoginClick()
            }
            e.stopPropagation()
        }} className="w-[50%] flex flex-col gap-[10px] min-w-[300px]">
            <div>
                <p className='capitalize mb-[6px] darkBlueColor'>{t('email')}</p>
                <TextField style={{ width: "100%" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="outlined-basic"
                    placeholder={t("enter_email")}
                    variant="outlined" />
            </div>
            {!showForgotPwd ?
                <div className='mt-[25px]'>
                    <p className='capitalize mb-[6px] darkBlueColor'>{t('password')}</p>
                    <TextField style={{ width: "100%" }}
                        type="password" value={password}
                        onChange={(e) => onChangePassword(e.target.value)}
                        id="outlined-basic"
                        placeholder={t("enter_password")}
                        variant="outlined" />
                </div>
                : <></>}
            {message ?
                <div>
                    <p style={{ color: message.color }}>{String(message.message)}</p>
                </div>
                : <></>}
            <div className='mt-[30px]'>
                <Button style={{ width: "100%", padding: "13px", background: colorLightGreen }} variant="contained" onClick={() => onLoginClick()} id="login">{t(showForgotPwd ? "reset_password" : "login")}</Button>
            </div>
            {!showForgotPwd ?
                <div className='mt-[10px]'>
                    <div className="google-button" onClick={() => googleLogin()}>
                        <GoogleLogo style={{ display: "inline-block", marginRight: "10px", width: "32px", height: "32px" }}></GoogleLogo>
                        <p style={{ marginLeft: "10px", margin: "0px", padding: "0px", display: "inline-block", fontWeight: "500" }}>{t('sign_in_with_google')}</p>
                    </div>
                </div>
                : <></>}
            <div>
                <p onClick={() => setShowForgotPwd(!showForgotPwd)} className='primaryColor' style={{ textAlign: "end", cursor: "pointer" }}>{t(showForgotPwd ? "cancel" : "reset_password")}</p>
            </div>
        </div>
        <Loader isLoading={loading} />
    </div>
}

export default Login