import { Menu, MenuItem } from '@mui/material';
import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserTokenModel } from "../models/UserTokenModel";
import { ProviderState } from '../pages/contracts/ActiveContract';
import { ProviderModal } from '../pages/contracts/ProviderModal';
import { ReactComponent as ChevronIcon } from "../rsc/chevron.svg";
import { colorPrimary } from '../rsc/colors/colors';
import { ReactComponent as AdminIcon } from "../rsc/ic_admin.svg";
import { ReactComponent as LogoutIcon } from "../rsc/ic_logout.svg";
import { ReactComponent as ProfileIcon } from "../rsc/ic_profile.svg";
import { AdminInfo } from '../stores/store';
import Avatar from "./Avatar";

function ProfileActions({ style = {}, logOut, goToProfile, goToAdminPage, isAdmin }: ProfileActionProps) {

    const user: UserTokenModel | null = useSelector((state: any) => state.user.value)
    const [providers, setProviders] = useState<ProviderState>(null)

    const displayProvider = () => {
        return (
            <ProviderModal onClose={() => setProviders(null)} providers={providers} />
        )
    }

    const { t } = useTranslation();

    const cardName = useRef<HTMLDivElement>(null)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={style}>
            {
                providers ? displayProvider() : <></>
            }
            <div onClick={(e) => handleClick(e)} style={{ minWidth: "150px", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} ref={cardName}>
                <Avatar style={{ width: "40px", height: "40px" }} uri={user.avatarUrl} firstname={user.firstName} lastname={user.lastName} />
                <p style={{ display: "inline-block", marginLeft: "10px", fontSize:"16px" }}>{user.firstName} {user.lastName}</p>
                <ChevronIcon style={{ marginLeft: "10px", verticalAlign: "middle" }} />
            </div>
            {
                cardName?.current?.offsetWidth ?
                    <Menu
                        disableScrollLock={true}
                        marginThreshold={null}
                        MenuListProps={{ sx: { py: 0 } }} //Used to remove some padding
                        style={{ margin: "0px", padding: "0px" }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}>
                        <MenuItem style={{
                            height: "50px",
                            display: "flex", justifyContent: "center", alignItems: "center",
                            width: cardName.current.offsetWidth,
                            maxWidth: cardName.current.offsetWidth,
                            margin: "0px", padding: "0px"
                        }} onClick={() => { handleClose(); goToProfile() }}>
                            <div className='flex flex-1 justify-end'>
                                <ProfileIcon style={{ width: "20px", height: "auto", marginRight: "10px" }} />
                            </div>
                            <p style={{ marginLeft: "5px", marginTop: "0px", marginBottom: "0px", flex: "2" }}>{t('my_profile')}</p>
                        </MenuItem>
                        <MenuItem style={{
                            height: "50px",
                            display: isAdmin.dashboardEnable ? "flex" : "none", justifyContent: "center", alignItems: "center",
                            width: cardName.current.offsetWidth,
                            maxWidth: cardName.current.offsetWidth,
                            margin: "0px", padding: "0px"
                        }} onClick={() => { handleClose(); goToAdminPage() }}>
                            <div className='flex flex-1 justify-end'>
                                <AdminIcon width={"20px"} height={"20px"} fill={colorPrimary} className='mr-[10px]' />
                            </div>
                            <p style={{ marginLeft: "5px", textAlign: "left", marginTop: "0px", marginBottom: "0px", flex: "2" }}>{t('admin')}</p>
                        </MenuItem>
                        <MenuItem style={{
                            height: "50px",
                            display: "flex", justifyContent: "center", alignItems: "center",
                            width: cardName.current.offsetWidth,
                            maxWidth: cardName.current.offsetWidth,
                            margin: "0px", padding: "0px"
                        }} onClick={() => { handleClose(); logOut() }}>
                            <div className='flex flex-1 justify-end'>
                                <LogoutIcon className='mr-[10px]' />
                            </div>
                            <p style={{ marginLeft: "5px", textAlign: "left", marginTop: "0px", marginBottom: "0px", flex: "2" }}>{t('log_out')}</p>
                        </MenuItem>
                    </Menu>
                    : <></>
            }
        </div>
    )
}

type ProfileActionProps = {
    style: CSSProperties,
    logOut: () => void,
    goToProfile: () => void,
    goToAdminPage: () => void,
    isAdmin: AdminInfo
}

export default ProfileActions